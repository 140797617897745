<script>
import _filter from 'lodash/filter'
import VJstree from 'vue-jstree'

export default {
  components: {
    VJstree,
  },

  data () {
    return {
      fields: [
        {
          name: 'name',
          title: 'Name',
          titleClass: 'ps-3',
          dataClass: 'ps-3',
          width: '90%',
        },
        {
          name: '__slot:actions',
          title: '',
          titleClass: 'text-center',
          dataClass: 'text-center',
          width: '10%',
        },
      ],

      form: new window.Form({
        id: null,
        name: '',
        // professional_body: '',
        // certification_date: '',
        // specialization: '',
        // user_id: '',
        // certificate_no: '',
      }),

      moreParams: {
        filter: 'name',
        sort: '-created_at',
      },

      permissionForm: new window.Form({
        id: '',
        selectedPermission: [],
      }),

      showPermissionForm: false,

      permissions: [],
      rolePermissions: [],

      selectedRole: null,
    }
  },

  computed: {
    vuetable () {
      return this.$refs.table.$refs.vuetable
    },
  },

  methods: {
    showCreateForm () {
      this.$bvModal.show('create-group-modal')
    },

    createGroup () {
      this.form.post('/roles')
      // UserService.createProfessional(this.form.data())
        .then((response) => {
          // this.$bvModal.hide('create-modal')

          this.vuetable.refresh()

          this.$toast.success('The group has been created')
        })
        .catch(() => {
          // this.form.processing = false
          // this.form.errors.record(error)
          // this.$toast.error('Oops! Something wrong')
        })
    },

    showEditForm (rowData) {
      this.form.populate(rowData)
      this.$bvModal.show('edit-group-modal')
    },

    updateGroup () {
      this.form.processing = true
      this.form.patch('/roles/' + this.form.id)
      // UserService.updateProfessional(this.form.data())
        .then(() => {
          this.form.processing = false
          this.$bvModal.hide('edit-group-modal')
          this.vuetable.reload()
          this.$toast.success('The Changes have been saved')
        })
        .catch((error) => {
        })
    },

    applyFilter () {
      this.vuetable.reload()
    },

    accessControlManagement (role) {
      this.selectedRole = role
      this.permissionForm.id = role.id

      this.rolePermissions = role.permissions

      this.permissionForm.selectedPermission = this.rolePermissions.map(({ id }) => id)

      this.retrieveSystemPermissions()

      setTimeout(() => {
        this.showPermissionForm = true
      }, 1000)
    },

    retrieveSystemPermissions () {
      const vm = this

      this.$httpClient.get('/permissions', {
        params: {
          page: {
            size: 1000,
          },
        },
      })
        .then(({ data }) => {
          const availablePermissions = data.data

          const permissionGroups = new Set()

          availablePermissions.forEach(({ code }) => {
            permissionGroups.add(code)
          })

          const parent = [...permissionGroups].map(function (code, index) {
            return {
              id: 'muhi',
              text: code,
              children: [],
              opened: false,
              selected: false,
              isLeaf: true,
            }
          })

          parent.forEach(function (parent) {
            _filter(availablePermissions, { code: parent.text }).forEach(function (permission) {
              const child = {
                id: permission.id,
                text: permission.display_name,
                selected: false,
              }

              if (vm.rolePermissions.length > 0) {
                const permissionToSelect = vm.rolePermissions.find(({ id }) => id === permission.id)

                child.selected = !!permissionToSelect
                parent.opened = !!permissionToSelect
              }

              parent.children.push(child)
            })
          })

          this.permissions = parent
        }).catch()
    },

    onPermissionSelected (node, item, event) {
      this.permissionForm.selectedPermission = []
      this.$refs.tree.handleRecursionNodeChilds(this.$refs.tree, node => {
        if (node.model && node.model.selected && node.model.id && node.model.id !== 'muhi') {
          // this.form.selectedPermission.push({id: node.model.id})
          this.permissionForm.selectedPermission.push(node.model.id)
        }
      })
    },

    updateRolePermission () {
      this.permissionForm.post(`/roles/${this.selectedRole.id}/permissions`)
        .then(() => {
          this.$toast.success('The change has been saved')

          this.rolePermissions = []
          this.permissionForm.reset()

          this.showPermissionForm = false
        }).catch()
    },

    closePermissionForm () {
      this.showPermissionForm = false

      this.rolePermissions = []
      this.permissionForm.reset()
    },
  },
}
</script>

<template>
  <div>
    <div v-if="showPermissionForm" class="row">
      <div class="col-xl-6">
        <div class="card shadow-sm">
          <div class="card-header">
              {{  selectedRole.name }} Permission Management
          </div>

          <div class="card-body">
            <v-jstree
              ref="tree"
              :data="permissions"
              show-checkbox
              multiple
              allow-batch
              whole-row
              @item-click="onPermissionSelected"
            />
          </div>

          <div class="card-footer">
            <button class="btn btn-success" :disabled="permissionForm.processing" @click="updateRolePermission">
              Submit
            </button>
            <button class="btn btn-light" :disabled="permissionForm.processing" @click="closePermissionForm">
              Close
            </button>
          </div>
          <div v-if="permissionForm.processing" class="progress card-progress">
            <div class="progress-bar progress-bar-indeterminate bg-green" />
          </div>
        </div>
      </div>
    </div>

    <div v-else class="card rounded-3 mt-3">
      <div class="card-header">
        <div class="input-icon me-2">
          <span class="input-icon-addon">
            <i class="uil uil-search" />
          </span>
          <input
            v-model="moreParams.filter.name" type="text"
            class="form-control bg-muted-lt rounded-2"
            placeholder="Search"
            @input="applyFilter"
          >
        </div>

        <div class="card-actions">
          <button class="btn btn-primary" @click="showCreateForm">
            <i class="uil uil-plus me-1" />Add Role
          </button>
        </div>
      </div>

      <VueTable
        ref="table"
        api-url="/datatable/roles"
        :fields="fields"
        :append-params="moreParams"
      >
        <template #actions="props">
          <div class="dropdown">
            <button class="btn align-text-top py-1" data-bs-toggle="dropdown">
              <i class="uil uil-ellipsis-h" />
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <a class="dropdown-item" href="#" @click="showEditForm(props.rowData)">
                <i class="uil uil-pen fs-3 me-2" /> Edit
              </a>

              <div class="dropdown-divider" />

              <a class="dropdown-item" href="#" @click="accessControlManagement(props.rowData)">
                <i class="uil uil-shield fs-3 me-2" /> Permission
              </a>
            </div>
          </div>
        </template>
      </VueTable>
    </div>

    <b-modal
      id="create-group-modal"
      title="Create Group"
      footer-class="d-flex justify-content-between"
      modal-class="modal-blur"
      body-class="px-4"
      hide-header-close
      no-close-on-esc
      lazy
      @hidden="form.reset()"
    >
      <form id="createForm" @submit.prevent="createGroup">
        <div class="form-group mb-4">
          <label for="" class="form-label">Name</label>
          <input
            id="name"
            v-model="form.name"
            type="text"
            class="form-control"
          >

          <span
            v-if="form.hasError('name')"
            class="text-danger"
            v-text="form.getError('name')"
          />
        </div>
      </form>

      <template #modal-footer="{cancel}">
        <button class="btn" :disabled="form.processing" @click.prevent="cancel">
          Close
        </button>

        <button class="btn btn-primary" form="createForm" :disabled="form.processing" @click.prevent="createGroup">
          Submit
        </button>
      </template>
    </b-modal>

    <b-modal
      id="edit-group-modal"
      title="Edit Group"
      footer-class="d-flex justify-content-between"
      modal-class="modal-blur"
      body-class="px-4"
      hide-header-close
      no-close-on-esc
      lazy
      @hidden="form.reset()"
    >
      <form id="editForm" @submit.prevent="updateGroup">
        <div class="form-group mb-4">
          <label for="" class="form-label">Name</label>
          <input
            id="name"
            v-model="form.name"
            type="text"
            class="form-control"
          >

          <span
            v-if="form.hasError('name')"
            class="text-danger"
            v-text="form.getError('name')"
          />
        </div>
      </form>

      <template #modal-footer="{cancel}">
        <button class="btn" :disabled="form.processing" @click.prevent="cancel">
          Close
        </button>

        <button class="btn btn-primary" form="editForm" :disabled="form.processing">
          Submit
        </button>
      </template>
    </b-modal>
  </div>
</template>
