// import './errorTracker'
import './bootstrap-vue'
import './axios'
import './form'
import './notification'
import './vue-select'
import './date'
import './websockets'
import './permission'
import './charts'
import './money'
