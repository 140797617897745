<script>

export default {
    props: ['branches', 'stores', 'accounts'],
    data() {
        return {
            fields: [
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: 'font-weight-bold',
                    dataClass: 'ps-3',
                },
                {
                    name: 'days',
                    title: 'Days',
                    titleClass: 'font-weight-bold',
                    dataClass: 'ps-3',
                },
                {
                    name: 'situational',
                    title: 'STATUS',
                    titleClass: 'font-weight-bold',
                    dataClass: 'ps-3',
                    callback: function (value) {
                        if (value) {
                            return 'SITUATIONAL'
                        }

                        return ''
                    }
                },
                {
                    name: '__slot:actions',
                    width: '15%',
                    titleClass: 'text-center',
                    dataClass: 'text-right',
                },
            ],

            moreParams: {
                filter: {
                    name: ''
                }
            },

            form: new window.Form({
                id: null,
                name: '',
                days:'',
                situational: false
            }),
        }
    },

    computed: {
        vuetable() {
            return this.$refs.table.$refs.vuetable
        },
    },

    methods: {
        showCreateForm() {
            this.$bvModal.show('create-modal')
        },

        showUpdateForm(rowData) {
            this.selectedDepartment = rowData
            this.viewEditForm = true
        },

      createLeaveType() {
            this.form.post('/leave_types')
                .then(() => {
                    this.$bvModal.hide('create-modal')
                    this.$toast.success('The Leave type  has been created')
                    this.vuetable.reload()
                })
                .catch()
        },

        showEditForm(rowData) {
            this.form.populate(rowData)
            this.form.id = rowData.id

            this.$bvModal.show('update-modal')
        },

        updateLeaveType() {
            this.form.patch('/leave_types/' + this.form.id)
                .then(() => {
                    this.vuetable.reload()
                    this.$bvModal.hide('update-modal')
                    this.$toast.success('The changes have been saved')
                })
                .catch()
        },

        applyFilter() {
            this.vuetable.reload()
        },

        cleanUp() {
            this.form.reset()
        }
    },
}
</script>

<template>
    <div>
        <div class="card card-stacked">
            <div class="card-header">
                <div class="input-icon me-2">
                     <span class="input-icon-addon">
                        <i class="uil uil-search"></i>
                    </span>
                    <input type="text" class="form-control bg-muted-lt rounded-2"
                           placeholder="Search Leave types"
                           @input="applyFilter"
                           v-model="moreParams.filter.name">
                </div>

                <div class="card-actions">
                    <button class="btn btn-primary" @click="showCreateForm">
                        <i class="uil uil-plus me-1"></i>Add  Leave type
                    </button>
                </div>
            </div>
            <VueTable
                ref="table"
                api-url="/datatable/leave_types"
                :fields="fields"
                :append-params="moreParams">
                <template v-slot:name="props">
                    <div class="media">
                        <div class="media-body align-self-center">
                            <span class="text-sm">{{ props.rowData.name }} </span>
                            <div class="font-size-12 text-purple-300">
                                {{ props.rowData.stores ? props.rowData.stores.name : '' }}
                                <span
                                    v-if="props.rowData.auto_post_request"
                                    class="badge badge-soft-success shadow-sm">Auto Post</span>
                                <span
                                    v-else
                                    class="badge badge-soft-secondary rounded px-1 p-1 shadow-sm border">Manual Post</span
                                >
                            </div>
                        </div>
                    </div>
                </template>

                <template v-slot:actions="props">
                    <div class="dropdown">
                        <button class="btn align-text-top py-1" data-bs-toggle="dropdown">
                            <i class="uil uil-ellipsis-h"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end">
                            <a class="dropdown-item" href="#" @click="showEditForm(props.rowData)">
                                <i class="uil uil-pen fs-3 me-2"></i> Edit
                            </a>
                        </div>
                    </div>
                </template>
            </VueTable>
        </div>

        <b-modal
            id="create-modal"
            title="Create Leave Type"
            hide-header-close
            footer-class="d-flex justify-content-start"
            modal-class="modal-blur"
            no-close-on-esc
        >
            <form @submit.prevent="createLeaveType" id="createForm">
                <div class="mb-3">
                    <label for="name" class="form-label">Leave Type</label>
                    <input type="text" class="form-control" id="name" v-model="form.name">

                    <span
                        v-if="form.hasError('name')"
                        class="text-danger"
                        v-text="form.getError('name')">
                    </span>
                </div>
              <div class="mb-3">
                    <label for="name" class="form-label">Days</label>
                    <input type="text" class="form-control" id="name" v-model="form.days">

                    <span
                        v-if="form.hasError('days')"
                        class="text-danger"
                        v-text="form.getError('days')">
                    </span>
                </div>
              <div class="mb-3">
                <div class="mb-2">
                  <label class="form-check form-switch">
                    <input
                        v-model="form.situational" class="form-check-input"
                        type="checkbox"
                    >
                    <span class="form-check-label">Situational</span>
                  </label>
                </div>
                </div>

                <div class="progress mb-2" v-if="form.processing">
                    <div class="progress">
                        <div class="progress-bar progress-bar-indeterminate bg-green"></div>
                    </div>
                </div>

            </form>

            <template #modal-footer="{cancel}">
                <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">Close
                </button>
                <button type="button" class="btn btn-primary" :disabled="form.processing"
                        @click.prevent="createLeaveType">
                    Submit
                </button>
            </template>

        </b-modal>

        <b-modal
            id="update-modal"
            title="Edit Leave Type"
            hide-header-close
            footer-class="d-flex justify-content-start"
            modal-class="modal-blur"
            no-close-on-esc
        >
            <form @submit.prevent="updateLeaveType" id="updateForm">
                <div class="mb-3">
                    <label for="name" class="form-label">Leave type</label>
                    <input type="text" class="form-control" id="name" v-model="form.name">

                    <span
                        v-if="form.hasError('name')"
                        class="text-danger"
                        v-text="form.getError('name')">
                    </span>
                </div>
              <div class="mb-3">
                <label for="name" class="form-label">Days</label>
                <input type="text" class="form-control" id="name" v-model="form.days">

                <span
                    v-if="form.hasError('days')"
                    class="text-danger"
                    v-text="form.getError('days')">
                    </span>
              </div>
              <div class="mb-2">
                <label class="form-check form-switch">
                  <input
                      v-model="form.situational" class="form-check-input"
                      type="checkbox"
                  >
                  <span class="form-check-label">Situational</span>
                </label>
              </div>

                <div class="progress mb-2" v-if="form.processing">
                    <div class="progress">
                        <div class="progress-bar progress-bar-indeterminate bg-green"></div>
                    </div>
                </div>

            </form>
            <template #modal-footer="{cancel}">
                <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">Close
                </button>
                <button type="button" class="btn btn-primary" :disabled="form.processing"
                        @click.prevent="updateLeaveType">
                    Submit
                </button>

            </template>
        </b-modal>

    </div>
</template>
