var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("VueDocPreview", {
    attrs: { value: "http://hrm.ecobiz.co.ke/chart.docx", type: "office" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }