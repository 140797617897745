var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "card card-stacked" },
        [
          _c("div", { staticClass: "card-header" }, [
            _c("div", { staticClass: "input-icon me-2" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.moreParams.filter.name,
                    expression: "moreParams.filter.name",
                  },
                ],
                staticClass: "form-control bg-muted-lt rounded-2",
                attrs: { type: "text", placeholder: "Search" },
                domProps: { value: _vm.moreParams.filter.name },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.moreParams.filter,
                        "name",
                        $event.target.value
                      )
                    },
                    _vm.applyFilter,
                  ],
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("VueTable", {
            ref: "table",
            attrs: {
              "api-url": "/datatable/disciplinary-cases",
              fields: _vm.fields,
              "append-params": _vm.moreParams,
            },
            scopedSlots: _vm._u([
              {
                key: "name",
                fn: function (props) {
                  return [
                    _c("div", { staticClass: "media" }, [
                      _c(
                        "div",
                        { staticClass: "media-body align-self-center" },
                        [
                          _c("span", { staticClass: "text-sm" }, [
                            _vm._v(_vm._s(props.rowData.employee.name) + " "),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "actions",
                fn: function (props) {
                  return [
                    _c("div", { staticClass: "dropdown" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn align-text-top py-1",
                          attrs: { "data-bs-toggle": "dropdown" },
                        },
                        [_c("i", { staticClass: "uil uil-ellipsis-h" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "dropdown-menu dropdown-menu-end" },
                        [
                          _c("div", { staticClass: "dropdown-item" }, [
                            _c(
                              "a",
                              {
                                staticClass: "text-secondary",
                                attrs: {
                                  href: "#",
                                  title: "Mail the payslip to employee",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.viewDetails(props.rowData)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "uil uil-envelope mr-2",
                                }),
                                _vm._v(" View Details"),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "detail-view-modal",
            title: "Disciplinary Case View",
            "hide-header-close": "",
            "footer-class": "d-flex justify-content-start",
            "modal-class": "modal-blur",
            "no-close-on-esc": "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn me-auto",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return cancel.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Close\n      ")]
                  ),
                ]
              },
            },
          ]),
        },
        [_c("div", [_vm._v("\n      inve\n    ")])]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "input-icon-addon" }, [
      _c("i", { staticClass: "uil uil-search" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }