var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c(
            "b-tabs",
            {
              attrs: {
                "active-nav-item-class": "bg-white text-indigo",
                lazy: "",
                "nav-class": "mb-2",
              },
            },
            [
              _c(
                "b-tab",
                {
                  attrs: {
                    "title-item-class": "fs-3",
                    "title-link-class": "px-4 py-2",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c(
                            "svg",
                            {
                              staticClass: "bi bi-people",
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                width: "16",
                                height: "16",
                                fill: "currentColor",
                                viewBox: "0 0 16 16",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4",
                                },
                              }),
                            ]
                          ),
                          _vm._v(" Candidates\n            "),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _c("candidates", {
                    attrs: {
                      jobgroups: _vm.jobgroups,
                      vacancies: _vm.vacancies,
                      employeetypes: _vm.employeetypes,
                      departments: _vm.departments,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-tab",
                {
                  attrs: {
                    "title-item-class": "fs-3",
                    "title-link-class": "px-4 py-2",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("i", { staticClass: "uil uil-check-circle me-2" }),
                          _vm._v("Vacancies\n            "),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _c("vacancies", {
                    attrs: {
                      jobgroups: _vm.jobgroups,
                      departments: _vm.departments,
                      managers: _vm.managers,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }