<template>
  <div>
    <div v-if="isCalendarInitialized">
      <FullCalendar :options="calendarOptions" />
    </div>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

export default {
  components: {
    FullCalendar
  },
  mounted() {
    this.initializeCalendar();
  },
  data() {
    return {
      isCalendarInitialized: false,
      calendarOptions: {
        plugins: [ dayGridPlugin, interactionPlugin ],
        initialView: 'dayGridMonth',
        events: []
      }
    }
  },
  methods: {
    initializeCalendar() {
      setTimeout(()=>{
        if (!this.isCalendarInitialized) {
          this.isCalendarInitialized = true;
          this.loadEvents();
        }
      },200);
    },
    loadEvents() {
      this.calendarOptions.events = [

      ];
    },
    handleDateClick(arg) {
      console.log(arg);
    }
  }
};
</script>

<style scoped>
#calendar {
  max-width: 900px;
  margin: 40px auto;
}
</style>
