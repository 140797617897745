import {saveState} from "@state/helpers";

export const state = {
    inAdmission: getSavedState('admission.patients'),
    inAdmissionQueue: getSavedState('admission.queue')
};

export const mutations = {
    ADD_TO_QUEUE(state, patient) {
        saveState('admission.queue', patient)
    },
}

export const actions = {
    enQueuePatient({commit}, patient) {
        commit('ADD_TO_QUEUE', patient)
    }
}

// ===
// Private helpers
// ===
function getSavedState(key) {
    if (key) {
        return JSON.parse(window.localStorage.getItem(key))
    }
}

function deleteState(key) {
    window.localStorage.removeItem(key)
}

