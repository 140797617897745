<script>
// import VendorCategories from './categories'

export default {

    data() {
        return {
            fields: [
                {
                    name: 'name',
                    title: 'NAME',
                    dataClass: 'ps-3'
                },
                {
                    name: 'created_at',
                    title: 'Registered',
                    titleClass: 'text-uppercase',
                    dataClass: 'ps-3',
                    callback: this.$options.filters.date_DATE_MONTH_YEAR,
                },
                {
                    name: '__slot:actions',
                    width: '5%',
                    titleClass: 'text-center',
                    dataClass: 'text-center',
                },
            ],

            moreParams: {
                filter: {
                    name: ''
                },
            },

            form: new window.Form({
                id: null,
                name: '',
                telephone: '',
                email: '',
                address: '',
                activated: true
            }),
        }
    },

    computed: {
        vuetable() {
            return this.$refs.table.$refs.vuetable
        },
    },

    methods: {
        showCreateForm() {
            this.$bvModal.show('vendor-modal')
        },

        registerVendor() {
            this.form.post('/vendors')
                .then(() => {
                    this.vuetable.reload()

                    this.$toast.success('Changes Saved')
                })
                .catch((e) => {
                })
        },

        updateVendor() {
            this.form.patch('/vendors/' + this.form.id)
                .then(() => {

                    this.$bvModal.hide('edit-modal')

                    this.vuetable.refresh()

                    this.$toast.success('The changes have been saved')
                })
                .catch((e) => {
                })
        },

        showEditForm(rowData) {
            this.form.populate(rowData)

            this.form.id = rowData.id

            this.$bvModal.show('edit-modal')
        },

        applyFilter() {
            this.vuetable.reload()
        },
    },
}
</script>

<template>
    <div>

        <div class="card">
            <div class="card-header">

                <div class="input-icon me-2">
                     <span class="input-icon-addon">
                        <i class="uil uil-search"></i>
                    </span>
                    <input type="text" class="form-control bg-muted-lt rounded-2"
                           placeholder="Search "
                           @input="applyFilter"
                           v-model="moreParams.filter.name">
                </div>

                <div class="card-actions">
                    <button class="btn btn-primary" @click="showCreateForm">
                        <i class="uil uil-plus me-1"></i>Add Supplier
                    </button>
                </div>
            </div>

            <VueTable
                ref="table"
                api-url="/datatable/vendors"
                :fields="fields"
                :append-params="moreParams"
            >

                <template v-slot:actions="props">
                    <div class="dropdown">
                        <button class="btn align-text-top py-1" data-bs-toggle="dropdown">
                            <i class="uil uil-ellipsis-h"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end">
                            <a class="dropdown-item" href="#" @click="showEditForm(props.rowData)">
                                <i class="uil uil-pen fs-3 me-2"></i> Edit
                            </a>
                        </div>
                    </div>
                </template>
            </VueTable>

        </div>

        <b-modal
            id="vendor-modal"
            title="Create Supplier Account"
            hide-header-close
            footer-class="d-flex justify-content-start"
            modal-class="modal-blur"
            no-close-on-esc
        >
            <form @submit.prevent="registerVendor" id="vendorForm">
                <div class="mb-3">
                    <label for="name" class="form-label">Supplier Name</label>
                    <input type="text" class="form-control" v-model="form.name" id="name">

                    <span
                        v-if="form.hasError('name')"
                        class="text-danger"
                        v-text="form.getError('name')">
                    </span>
                </div>

                <div class="progress mb-2" v-if="form.processing">
                    <div class="progress">
                        <div class="progress-bar progress-bar-indeterminate bg-green"></div>
                    </div>
                </div>

            </form>

            <template #modal-footer="{cancel}">
                <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">Close
                </button>
                <button type="button" class="btn btn-primary" :disabled="form.processing"
                        @click.prevent="registerVendor">
                    Submit
                </button>
            </template>
        </b-modal>

        <b-modal
            id="edit-modal"
            title="Edit Supplier"
            hide-header-close
            footer-class="d-flex justify-content-start"
            modal-class="modal-blur"
            no-close-on-esc
        >
            <form @submit.prevent="updateVendor" id="vendorForm">
                <div class="mb-3">
                    <label for="name" class="form-label">Supplier Name</label>
                    <input type="text" class="form-control" v-model="form.name" id="name">

                    <span
                        v-if="form.hasError('name')"
                        class="text-danger"
                        v-text="form.getError('name')">
                    </span>
                </div>

                <div class="mb-3">
                    <label class="row">
                        <span class="col">Activated</span>
                        <span class="col-auto">
                            <label class="form-check form-check-single form-switch">
                                <input class="form-check-input" type="checkbox" v-model="form.activated">
                            </label>
                        </span>
                    </label>
                </div>

                <div class="progress mb-2" v-if="form.processing">
                    <div class="progress">
                        <div class="progress-bar progress-bar-indeterminate bg-green"></div>
                    </div>
                </div>

            </form>

            <template #modal-footer="{cancel}">
                <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">Close
                </button>
                <button type="button" class="btn btn-primary" :disabled="form.processing"
                        @click.prevent="updateVendor">
                    Submit
                </button>
            </template>
        </b-modal>

    </div>
</template>
