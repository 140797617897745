import httpClient from '@plugins/axios'
// import Cookies from 'js-cookie'
// import jsonApi from '@plugins/devour-client'
import UserService from '@services/user'
import permission from "@plugins/permission";

export const state = {
    currentUser: getSavedState('auth.currentUser'),
    permissions: getSavedState('auth.permissions'),
    roles: []
    // roles: getSavedState('auth.roles'),
    // token: Cookies.get('oauth_token'),
    // refresh_token: Cookies.get('refresh_token'),
}

export const getters = {
    loggedIn: (state) => !!state.currentUser,
    user: (state) => state.currentUser,
    roles: (state) => state.roles,
    permissions: (state) => state.permissions,
    token: (state) => state.token,
    refresh_token: (state) => state.refresh_token,
}

export const mutations = {
    SET_CURRENT_USER(state, user) {
        saveState('auth.currentUser', user)

        state.currentUser = user
    },

    SAVE_TOKEN(state, payload) {
        // Cookies.set('oauth_token', payload.access_token, {
        //     expires: payload.expires_in,
        //     sameSite: 'strict'
        // })

        // Cookies.set('refresh_token', payload.refresh_token, {sameSite: 'strict'}) // 7 days

        // state.token = Cookies.get('oauth_token',)
        // state.refresh_token = Cookies.get('refresh_token')
    },

    SAVE_PERMISSIONS(state, permissions) {
        saveState('auth.permissions', permissions)

        // state.permissions = getSavedState('auth.permissions')
    },

    LOGOUT(state) {
        // Cookies.remove('oauth_token')
        // Cookies.remove('refresh_token')
        deleteState('accounts.CHARTS_OF_ACCOUNT')
        deleteState('auth.currentUser')
        deleteState('auth.permissions')
        deleteState('auth.roles')
        state.token = null
        state.refresh_token = null
        state.currentUser = null
        state.permissions = null
    },

    FETCH_PERMISSIONS_FAILURE(state) {
        state.permissions = []
    },

    FETCH_ROLES_SUCCESS(state, {roles}) {
        state.roles = roles
        saveState('auth.roles', roles)
    },

    FETCH_ROLES_FAILURE(state) {
        state.roles = []
    },
}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    init({state, dispatch}) {
        // dispatch('fetchAuthenticatedUser')
    },

    logIn({commit, dispatch, getters}, payload = {}) {
        return new Promise((resolve, reject) => {
            httpClient.post(import.meta.env.VITE_APP_URL + '/login', payload)
                .then(({data}) => {

                    dispatch('saveToken', data)

                    resolve()
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },

    saveUser({commit}, payload) {
        commit('SET_CURRENT_USER', payload)

        if (payload.permissions.length) {
            commit('SAVE_PERMISSIONS', payload.permissions.map(permission => permission.name))
        }
    },

    fetchAuthenticatedUser({commit}) {
        return new Promise((resolve, reject) => {
            httpClient.get(import.meta.env.VITE_APP_URL + '/api/v1/session')
                .then(({data}) => {
                    commit('SET_CURRENT_USER', data.data)

                    if (data.data.permissions.length > 0) {
                        let permissionGroup = data.data.permissions.map((perm) => perm.name)

                        commit('SAVE_PERMISSIONS', permissionGroup)
                    } else {
                        commit('SAVE_PERMISSIONS', [])
                    }

                }).catch((err) => {
                reject(err)
            })
        })
    },

    logOut({commit}) {
        commit('SET_CURRENT_USER', null)
        commit('LOGOUT', state)
    },

    // register the user
    resetPassword({commit, dispatch, getters}, {email} = {}) {
        if (getters.loggedIn) return dispatch('validate')

        return httpClient.post('/api/reset', {email}).then((response) => {
            return response.data
        })
    },

    // Validates the current user's token and refreshes it
    // with new data from the API.
    // async validate({ commit, dispatch, getters }) {
    //   if (getters.loggedIn && getters.token) return Promise.resolve(getters.user)
    //
    //   try {
    //     let { data: user } = await jsonApi.request(
    //       process.env.VUE_APP_BACKEND_URL + '/session',
    //       'GET',
    //       {
    //         include: 'employees,permission,branches',
    //       }
    //     )
    //
    //     commit('SET_CURRENT_USER', user)
    //
    //     if (user.permissions.length > 0) {
    //       commit('SAVE_PERMISSIONS', user.permissions)
    //     }
    //
    //     return user
    //   } catch (error) {
    //     commit('LOGOUT')
    //     return Promise.reject(error)
    //   }
    // },

    async getUserPermissions({commit}) {
        UserService.getUserPermissions(state.currentUser.id)
            .then((response) => {
                if (response.data.length > 0) {
                    commit('FETCH_PERMISSIONS_SUCCESS', {
                        permissions: response.data.map((permission) => permission),
                    })
                }

                // commit('FETCH_PERMISSIONS_SUCCESS', {
                //   permissions: [],
                // })
            })
            .catch(() => {
                console.log('Failed fetching permissions')

                commit('FETCH_PERMISSIONS_FAILURE')
            })

        //   const { data } = await axios.get('/v1/users/permissions')
    },

    getUserRoles({commit}) {
        UserService.findUsersRoles(state.currentUser.id)
            .then((response) => {
                commit('FETCH_ROLES_SUCCESS', {roles: response.data})
            })
            .catch(() => {
                commit('FETCH_ROLES_FAILURE')
            })
    },
}

// ===
// Private helpers
// ===
function getSavedState(key) {
    if (key) {
        return JSON.parse(window.localStorage.getItem(key))
    }
}

function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state))
}

function deleteState(key) {
    window.localStorage.removeItem(key)
}
