var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "card card-stacked card-md mb-4" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xl-8 mx-auto" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary mx-auto",
                attrs: { disabled: _vm.form.processing },
                on: { click: _vm.generateReport },
              },
              [_vm._v("\n              Generate\n            ")]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-4" }, [
      _c("div", { staticClass: "text-center" }, [
        _c("h1", { staticClass: "fw-bolder" }, [
          _vm._v(
            "\n                  Employee Listing Report\n                "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }