<script>
import UnresolvedCases from "./UnresolvedCases.vue";
import ResolvedCases from "./ResolvedCases.vue";

export default {
    props: ['actionstypes'],
   components:{
     UnresolvedCases,
     ResolvedCases
  },
    data() {
        return {

        }
    },
}
</script>

<template>
    <div>

          <b-tabs active-nav-item-class="bg-white text-indigo" lazy nav-class="mb-2">
            <b-tab title-item-class="fs-3" title-link-class="px-4 py-2">
              <template #title>
                <i class="uil uil-spinner-alt me-2" />Unresolved Cases
              </template>

              <unresolved-cases  :actionstypes="actionstypes"/>

            </b-tab>
            <b-tab title-item-class="fs-3" title-link-class="px-4 py-2">
              <template #title>
                <i class="uil uil-check-circle me-2" />Resolved
              </template>
              <resolved-cases />
            </b-tab>
          </b-tabs>
    </div>
</template>
