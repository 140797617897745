import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=0330eb64&"
import script from "./Index.vue?vue&type=script&lang=js&"
export * from "./Index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/bismarck/Documents/software/ponacare-medical/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0330eb64')) {
      api.createRecord('0330eb64', component.options)
    } else {
      api.reload('0330eb64', component.options)
    }
    module.hot.accept("./Index.vue?vue&type=template&id=0330eb64&", function () {
      api.rerender('0330eb64', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/pages/configuration/benefits/Index.vue"
export default component.exports