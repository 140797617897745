<script>
import { defineComponent } from 'vue'
import { toPrice } from '@plugins/money'

export default defineComponent({
  name: 'Pensions',
  props: {
    employee: {
      type: Object,
      default: () => {},
    },
    permissions: {
      type: Array,
      default: () => [],
    },
    pensions: {
      type: Array,
      default: () => [],
    },
  },

  data () {
    return {

      fields: [
        {
          name: 'pension.name',
          title: 'Pension',
          titleClass: ' text-end pe-3',
          dataClass: ' text-end pe-3',
          width: '8%',
        },
        {
          name: 'employer_max',
          title: 'Employer Amount',
          titleClass: 'ps-3',
          dataClass: 'ps-3',
          width: '45%',
        },
        {
          name: 'employee_max',
          title: 'Employee Amount',
          dataClass: 'ps-3',
          titleClass: 'ps-3',
          width: '15%',
        },
      
        // {
        //   name: '__slot:status',
        //   title: 'STATUS',
        //   dataClass: 'ps-1',
        //   titleClass: 'ps-1',
        //   width: '10%',
        // },
        {
          name: '__slot:actions',
          dataClass: 'txt-center',
          width: '5%',
        },
      ],

      employeePensions: [],

      billToReverse: '',

      form: new window.Form({
        id:'',
        employee_id: this.employee.id,
        pension_id: null,
        employer_rate: 0,
        employer_max: 0,
        employee_rate: 0,
        employee_max: 0,
      }),
    }
  },
  mounted () {
    this.retrieveEmployeePensions()
  },

  methods: {
    retrieveEmployeePensions () {
      this.$httpClient.get('/employee/pensions/' + this.employee.id)
          .then(({ data }) => {
            this.employeePensions = data;
          }).catch(() => {
        this.$toast.error('There was a problem retrieving employee pensions')
      })
    },
    deletePension (rowData){
      this.form.id = rowData.id
      this.$toast.question('Are you sure?', 'You want to delete').then(() => {
        this.$httpClient.delete('/employee/pensions/' + this.form.id )
        .then((error) => {
          this.$toast.success('deleted')
          this.cleanUp()
          this.vuetable.reload()
        }).catch((error) => {
          this.$toast.error(error.response.data)
        })

        
      })
    },
    openPensionModal () {
      this.$bvModal.show('pension-modal')
    },


    addPension () {

      this.form.post('/employee/pensions')
          .then(() => {
            this.$toast.success('Pension has been added')
            this.cleanUp()
            this.$bvModal.hide('pension-modal')
          })
          .catch((error) => {
            this.form.processing = false
            this.form.errors.record(error.response.data.errors)
          })
    },
    editPension(data){
      this.form.populate(data);
      this.$bvModal.show('edit-pension-modal')
    },
    updatePension(){
      this.form.patch('/employee/pensions/' + this.form.id)
          .then(() => {
            this.$toast.success('The changes have been saved')

            this.cleanUp()
            this.$bvModal.hide('edit-pension-modal')
            this.vuetable.reload()
          })
          .catch()
    },
    cleanUp () {
      this.form.reset();
      this.retrieveEmployeePensions()
    },

  },

})
</script>

<template>

  <div>

    <b-modal
        id="pension-modal"
        body-class="px-4"
        content-class="bg-white rounded-3"
        footer-class="d-flex justify-content-between bg-muted-lt border-top"
        hide-header-close
        modal-class="modal-blur"
        no-close-on-backdrop
        no-close-on-esc
        no-enforce-focus
        title="Pension Form"
        @hidden="cleanUp"
    >
      <div class="form-group mb-4">
        <label class="form-label" for="item_id">Pension</label>
        <v-select
            id="item_id"
            v-model="form.pension_id"
            :clearable="false"
            :options="pensions"
            :reduce="(option) => option.id"
            label="name"
        >

        </v-select>
        <span
            v-if="form.errors.has('item_id')"
            class="text-danger"
            v-text="form.errors.first('item_id')"
        />
        <p
            v-if="form.hasError('ledger_id')"
            class="text-danger"
            v-text="form.getError('ledger_id')"
        />
      </div>
      
      <div class="form-group mb-4">
        <label for="physical_address" class="form-label">Employer Amount</label>
        <input
            id="physical_address"
            v-model="form.employer_max"
            type="text"
            class="form-control"
        >

        <span
            v-if="form.hasError('employer_max')"
            class="text-danger"
            v-text="form.getError('employer_max')"
        />
      </div>
     
      <div class="form-group mb-4">
        <label for="physical_address" class="form-label">Employee Amount</label>
        <input
            id="physical_address"
            v-model="form.employee_max"
            type="text"
            class="form-control"
        >

        <span
            v-if="form.hasError('employee_max')"
            class="text-danger"
            v-text="form.getError('employee_max')"
        />
      </div>
      <div v-if="form.processing" class="progress mb-2">
        <div class="progress">
          <div class="progress-bar progress-bar-indeterminate bg-green"/>
        </div>
      </div>

      <template #modal-footer="{cancel}">
        <button :disabled="form.processing" class="btn" type="button" @click.prevent="cancel">
          Close
        </button>

        <button
            :disabled="form.processing" class="btn btn-primary"
            type="submit"
            @click="addPension"
        >
          Submit
        </button>
      </template>
    </b-modal>
    <b-modal
        id="edit-pension-modal"
        body-class="px-4"
        content-class="bg-white rounded-3"
        footer-class="d-flex justify-content-between bg-muted-lt border-top"
        hide-header-close
        modal-class="modal-blur"
        no-close-on-backdrop
        no-close-on-esc
        no-enforce-focus
        title="Edit Pension Form"
        @hidden="cleanUp"
    >
      <div class="form-group mb-4">
        <label class="form-label" for="item_id">Pension</label>
        <v-select
            id="item_id"
            v-model="form.pension_id"
            :clearable="false"
            :options="pensions"
            :reduce="(option) => option.id"
            label="name"
        >

        </v-select>
        <span
            v-if="form.errors.has('item_id')"
            class="text-danger"
            v-text="form.errors.first('item_id')"
        />
        <p
            v-if="form.hasError('ledger_id')"
            class="text-danger"
            v-text="form.getError('ledger_id')"
        />
      </div>
      <div class="form-group mb-4">
        <label for="physical_address" class="form-label">Employer Rate</label>
        <input
            id="physical_address"
            v-model="form.employer_rate"
            type="text"
            class="form-control"
        >

        <span
            v-if="form.hasError('employer_rate')"
            class="text-danger"
            v-text="form.getError('employer_rate')"
        />
      </div>
      <div class="form-group mb-4">
        <label for="physical_address" class="form-label">Employer Max</label>
        <input
            id="physical_address"
            v-model="form.employer_max"
            type="text"
            class="form-control"
        >

        <span
            v-if="form.hasError('employer_max')"
            class="text-danger"
            v-text="form.getError('employer_max')"
        />
      </div>
      <div class="form-group mb-4">
        <label for="physical_address" class="form-label">Employee Rate </label>
        <input
            id="physical_address"
            v-model="form.employee_rate"
            type="text"
            class="form-control"
        >

        <span
            v-if="form.hasError('employee_rate')"
            class="text-danger"
            v-text="form.getError('employee_rate')"
        />
      </div>
      <div class="form-group mb-4">
        <label for="physical_address" class="form-label">Employee Max</label>
        <input
            id="physical_address"
            v-model="form.employee_max"
            type="text"
            class="form-control"
        >

        <span
            v-if="form.hasError('employee_max')"
            class="text-danger"
            v-text="form.getError('employee_max')"
        />
      </div>
      <div v-if="form.processing" class="progress mb-2">
        <div class="progress">
          <div class="progress-bar progress-bar-indeterminate bg-green"/>
        </div>
      </div>

      <template #modal-footer="{cancel}">
        <button :disabled="form.processing" class="btn" type="button" @click.prevent="cancel">
          Close
        </button>

        <button
            :disabled="form.processing" class="btn btn-primary"
            type="submit"
            @click="updatePension"
        >
          Submit
        </button>
      </template>
    </b-modal>

    <div class="card rounded-3 mb-5 ">
      <div class="card-header">
        <div class="">
          <h2 class="card-title mb-0 fw-bold">
            Pensions
          </h2>
        </div>

        <div class="card-actions">
          <div class="btn-list">
            <button class="btn btn-primary me-4" @click.prevent="openPensionModal">
              <i class="uil uil-plus me-1"/> Add Pension
            </button>
          </div>
        </div>
      </div>

      <VueTable
          ref="table"
          :api-mode="false"
          :data="employeePensions"
          :fields="fields"
          :load-on-start="false"
          :show-pagination="false"
          :show-per-page="false"
      >




        <template #actions="props">
          <div class="dropdown">
            <button
                class="btn align-text-center py-1"
                data-bs-toggle="dropdown"
            >
              <i class="uil uil-ellipsis-h"/>
            </button>
            <div class="dropdown-menu dropdown-menu-end">


              <div class="dropdown-divider"/>

              <a class="dropdown-item" href="#" @click="editPension(props.rowData)">
                <i class="uil uil-pen fs-3 me-2"/> Edit
              </a>
              <a class="dropdown-item text-danger" href="#" @click="deletePension(props.rowData)">
                <i class="uil  uil-trash-alt me-2"/> Delete
              </a>
            </div>
          </div>
        </template>
      </VueTable>
    </div>

  </div>
</template>

<style scoped>

</style>
