import axios from 'axios'
import Vue from 'vue'
import toast from '@plugins/notification'
import Qs from 'qs'

/**
 * Create a new Axios client instance
 * @see https://github.com/mzabriskie/axios#creating-an-instance
 */
const config = {
    // baseURL: process.env.MIX_APP_URL,
    retries: 2,
    // timeout: 35000, // 35sec
    headers: {
        Accept: 'application/json',
        common: {
            'X-Requested-With': 'XMLHttpRequest'
        }
    },
    paramsSerializer: function (params) {
        return Qs.stringify(params, {arrayFormat: 'brackets'})
    },
}

const httpClient = axios.create(config)

httpClient.interceptors.response.use((response) => response, (error) => {

        if (error.response) {
            const {status} = error.response

            // authentication error
            if (status === 401) {
                toast.error('You need to sign in to perform this action', 'Unauthenticated')
            }

            if (status === 403) {
                toast.warning('You are not authorized to perform this action', 'Unauthorized')
            }

            return Promise.reject(error)

        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        } else {
            // Something happened in setting up the request that triggered an Error
        }

        return Promise.reject(error)
    }
)

Object.defineProperty(Vue.prototype, '$httpClient', {value: httpClient})

export default httpClient
