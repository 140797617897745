<script>

export default {
  props: {
    employeetypes: {
      type: Array,
      default: () => [],
    },
    jobgroups: {
      type: Array,
      default: () => [],
    },
    departments: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    accounts: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      PAYEOptions: [
        { name: 'Not Applicable', value: 0 },
        { name: 'Graduated Tax', value: 1 },
        { name: '30% of Gross Salary', value: 2 },
        { name: '5% with-holding', value: 3 },
      ],
      employees: [],
      form: new window.Form({
        id: null,
        name: '',
        dob: '',
        gender: '',
        identification_number: '',
        physical_address: '',
        job_title: '',
        is_disabled: '',
        disability:'',
        telephone: '',
        staff_number: '',
        employee_type_id: null,
        next_of_kin: '',
        supervisor:'',
        email: '',
        kra_pin: '',
        nhif_no: '',
        nssf_no: '',
        payee_type: 0,
        job_group_id: null,
        pays_tax: false,
        pays_nssf: false,
        pays_nhif: false,
        department_id: null,


        activated: true
      }),
    }
  },

  watch: {

  },
  created() {
    this.retrieveEmployees()
  },
  methods: {
    retrieveEmployees () {
      this.$httpClient.get('/datatable/employees/')
          .then(({ data }) => {
            this.employees = data.data.filter((employee)=>employee.employment_status === 0 && employee);
          }).catch(() => {
        this.$toast.error('There was a problem retrieving the Employees')
      })
    },
    registerEmployee() {
      this.form.post('/payroll/create')
          .then(() => {
            this.$toast.success('Employee Registered successfully')

            setTimeout(() => {
              window.location.assign('/payroll/master')
            }, 2000)
          })
          .catch()
    },

    goBack() {
      window.location.assign('/payroll/master')
    },
  },
}
</script>

<template>
  <div>
    <div class="row">
      <div class="col-xl-8 mx-auto p-4">
        <div class="text-center mb-4">
          <h1 class="fs-1">
            Employee Payroll Registration
          </h1>
          <span class="text-muted">Complete the form below to register a new employee to payroll</span>
        </div>
        <div class="card card-stacked">
          <form class="card-body py-4" @submit.prevent="registerEmployee">
            <div class="row g-4">
              <div class="col-lg-6 form-group mb-4">
                <label for="" class="form-label">Payroll Number</label>
                <input id="name" v-model="form.staff_number" type="text" class="form-control">
                <div v-if="form.hasError('staff_number')" class="text-danger" v-text="form.getError('staff_number')" />
              </div>
              <div class="col-lg-6 form-group mb-4">
                <label for="" class="form-label">Full name</label>
                <input id="name" v-model="form.name" type="text" class="form-control">
                <div v-if="form.hasError('name')" class="text-danger" v-text="form.getError('name')" />
              </div>
              <div class="col-lg-6 form-group mb-4">
                <label for="" class="form-label">Gender</label>
                <input id="name" v-model="form.gender" type="text" class="form-control">

                <span v-if="form.hasError('gender')" class="text-danger" v-text="form.getError('gender')" />
              </div>
              <div class="col-lg-6 form-group mb-4">
                <label class="form-label">Date of Birth</label>
                <div>
                  <date-picker name="date_of_birth" formclass="form-control bg-white" :value="form.dob"
                               :max-date="new Date()" @on-change="(dateOb, dateStr) => {
            form.dob = dateStr
          }" />

                  <span v-if="form.hasError('dob')" class="text-danger" v-text="form.getError('dob')" />
                </div>
              </div>
              <div class="col-lg-6 form-group mb-4">
                <label class="form-label" for="plan_type">Identification number</label>
                <input id="name" v-model="form.identification_number" type="text" class="form-control">
                <span v-if="form.hasError('identification_number')" class="text-danger"
                      v-text="form.getError('identification_number')" />
              </div>
              <div class="col-lg-6 form-group mb-4">
                <label class="form-label" for="plan_type">Mobile number</label>
                <input id="name" v-model="form.telephone" type="text" class="form-control">
                <span v-if="form.hasError('identification_number')" class="text-danger"
                      v-text="form.getError('identification_number')" />
              </div>
              <div class="col-lg-6 form-group mb-4">
                <label class="form-label" for="plan_type">Email address</label>
                <input id="name" v-model="form.email" type="text" class="form-control">
                <span v-if="form.hasError('email')" class="text-danger" v-text="form.getError('email')" />
              </div>
              <div class="col-lg-6 form-group mb-4">
                <label class="form-label" for="plan_type">Physical address</label>
                <input id="name" v-model="form.physical_address" type="text" class="form-control">
                <span v-if="form.hasError('physical_address')" class="text-danger"
                      v-text="form.getError('physical_address')" />
              </div>
<!--              <div class="col-lg-6 form-group mb-4">-->
<!--                <label class="form-label" for="plan_type">Marital Status</label>-->
<!--                <v-select id="scheme_id" v-model="form.marital_status" label="name" :options="marital_status"-->
<!--                          :reduce="(option) => option.value" :clearable="false" />-->
<!--                <span v-if="form.hasError('marital_status')" class="text-danger"-->
<!--                      v-text="form.getError('marital_status')" />-->
<!--              </div>-->
              <div class="col-lg-6 form-group mb-4">
                <label class="form-label" for="plan_type">Job Title</label>
                <input id="name" v-model="form.job_title" type="text" class="form-control">
                <span v-if="form.hasError('job_title')" class="text-danger" v-text="form.getError('job_title')" />
              </div>
              <div class="col-lg-6 form-group mb-4">
                <label class="form-label" for="plan_type">Department</label>
                <v-select id="scheme_id" v-model="form.department_id" label="name" :options="departments"
                          :reduce="(option) => option.id" :clearable="false" />
                <span v-if="form.hasError('department_id')" class="text-danger"
                      v-text="form.getError('department_id')" />
              </div>
              <div class="col-lg-6 form-group mb-4">
                <label class="form-label" for="plan_type">Report to</label>
                <v-select id="scheme_id" v-model="form.supervisor" label="name" :options="employees"
                          :reduce="(option) => option.id" :clearable="false" >
                  <template v-slot:option="option">
                    <li class="media mb-1">
                      <div class="media-body">
                        <div class="d-flex justify-content-between">
                          <span>{{ option.name }}</span> <br>
                          <small class="text-warning">{{option.department.name}}</small>
                        </div>
                      </div>
                    </li>
                  </template>
                  <template v-slot:selected-option="option">
                    <li class="media mb-1">
                      <div class="media-body">
                        <div class="d-flex justify-content-between">
                          <span>{{ option.name }}</span>
                        </div>
                      </div>
                    </li>
                  </template>
                </v-select>
                <span v-if="form.hasError('supervisor')" class="text-danger"
                      v-text="form.getError('supervisor')" />
              </div>
              <div class="col-lg-6 form-group mb-4">
                <label class="form-label" for="plan_type">Employee Type</label>
                <v-select id="scheme_id" v-model="form.employee_type_id" label="name" :options="employeetypes"
                          :reduce="(option) => option.id" :clearable="false" />
                <span v-if="form.hasError('employee_type_id')" class="text-danger"
                      v-text="form.getError('employee_type_id')" />
              </div>
              <div class="col-lg-6 form-group mb-4">
                <label class="form-label" for="plan_type">Job Group</label>
                <v-select id="scheme_id" v-model="form.job_group_id" label="name" :options="jobgroups"
                          :reduce="(option) => option.id" :clearable="false" />
                <span v-if="form.hasError('job_group_id')" class="text-danger"
                      v-text="form.getError('job_group_id')" />
              </div>
              <div class="col-lg-8 mx-auto mt-4">
                <div class="form-group mb-3">
                  <div class="mb-2">
                    <label class="form-check form-switch">
                      <input v-model="form.pays_tax" class="form-check-input" type="checkbox">
                      <span class="form-check-label">Pays tax</span>
                    </label>
                  </div>
                </div>

                <div v-if="form.pays_tax" class="form-group mb-3">
                  <label class="form-label" for="paye_type">Tax Setup</label>
                  <v-select id="paye_type" v-model="form.payee_type" :options="PAYEOptions" label="name"
                            :clearable="false" :reduce="(option) => option.value">
                  </v-select>

                  <span v-if="form.errors.has('payee_type')" class="text-danger" v-text="form.errors.get('payee_type')">
                  </span>
                </div>

                <div v-if="form.pays_tax" class=" form-group mb-3">
                  <label for="kra_pin" class="form-label">Kra Pin</label>
                  <input id="name" v-model="form.kra_pin" type="text" class="form-control">

                  <span v-if="form.hasError('kra_pin')" class="text-danger" v-text="form.getError('kra_pin')" />
                </div>
                <div class="form-group mb-3">
                  <div class="mb-2">
                    <label class="form-check form-switch">
                      <input v-model="form.pays_nhif" class="form-check-input" type="checkbox">
                      <span class="form-check-label">Pays NHIF</span>
                    </label>
                  </div>
                </div>
                <div v-if="form.pays_nhif" class="form-group mb-4">
                  <label for="kra_pin" class="form-label">NHIF NUMBER</label>
                  <input id="name" v-model="form.nhif_no" type="text" class="form-control">

                  <span v-if="form.hasError('nhif_no')" class="text-danger" v-text="form.getError('nhif_no')" />
                </div>
                <div class="form-group mb-3">
                  <div class="mb-2">
                    <label class="form-check form-switch">
                      <input v-model="form.pays_nssf" class="form-check-input" type="checkbox">
                      <span class="form-check-label">Pays NSSF</span>
                    </label>
                  </div>
                </div>
                <div v-if="form.pays_nssf" class=" form-group mb-4">
                  <label for="kra_pin" class="form-label">NSSF NUMBER</label>
                  <input id="name" v-model="form.nssf_no" type="text" class="form-control">

                  <span v-if="form.hasError('nssf_no')" class="text-danger" v-text="form.getError('nssf_no')" />
                </div>
              </div>
              <div class="mt-4">
                <button class="btn btn-primary me-3" type="submit">
                  Submit
                </button>

                <button class="btn" :disabled="form.processing || form.successful" @click.prevent="goBack">
                  Cancel
                </button>
              </div>

            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
