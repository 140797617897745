<script>

export default {
  data () {
    return {
      hasLoginErr:false,
      form: new window.Form({
        username: '',
        staff_number: '',
        name: '',
        gender: '',
        BoD: '',
        identification_number: '',
        residence: '',
        email: '',
        password: '',
        cpassword:'',
        remember_me: '',
      }),
    }
  },
watch:{
  'form.name':function(value){
      const fullname = this.form.name
      this.form.name  = fullname

      const [firstName, secondName] = [...fullname.split(' ')];
      this.form.username = (`${firstName.substr(0,1)}.${secondName ?? ''}`).toLocaleLowerCase();

    }
},
  methods: {
    register () {
      
      if (this.form.password !== this.form.cpassword || this.username === '') {
        this.$toast.error('Password do not match, try again')
        return
      }
      
      this.form.post('/register')
          .then((response) => {
            this.$toast.success('Registered successfully, but you wont be able to login unit HR approves')
            setTimeout(() => {
              window.location.assign('/login')
            }, 2000)

          }).catch((error)=>{
        this.hasLoginErr=true;
      })
    },
  },
}
</script>

<template>
  <div>
    <div class="text-center"><h2>Create new account</h2></div>
    <form class="row" autocomplete="off" @submit.prevent="register">
      <div class="col-lg-6 mt-4">
        <label class="form-label" for="staff_number">Payroll number</label>
        <input
          id="staff_number" v-model="form.staff_number" type="text"
          class="form-control"
          autocomplete="off"
        >

        <span
          v-if="form.hasError('staff_number')"
          class="text-danger"
          v-text="form.getError('staff_number')"
        />
      </div>
      <div class="col-lg-6  mt-4">
        <label class="form-label" for="name">Fullname</label>
        <input
          id="name" v-model="form.name" type="text"
          class="form-control"
          autocomplete="off"
        >

        <span
          v-if="form.hasError('name')"
          class="text-danger"
          v-text="form.getError('name')"
        />
      </div>
      <div class="col-lg-6  mt-4">
        <label class="form-label" for="gender">Gender</label>
        <input
          id="gender" v-model="form.gender" type="text"
          class="form-control"
          autocomplete="off"
        >

        <span
          v-if="form.hasError('gender')"
          class="text-danger"
          v-text="form.getError('gender')"
        />
      </div>
      <div class="col-lg-6  mt-4">
        <label class="form-label" for="username">Username</label>
        <input
          id="username" v-model="form.username" type="text"
          class="form-control"
          autocomplete="off"
        >

        <span
          v-if="form.hasError('username')"
          class="text-danger"
          v-text="form.getError('username')"
        />
      </div>
      <div class="col-lg-6  mt-4">
        <label class="form-label" for="BoD">Date of Birth</label>
        <date-picker name="date_of_birth" formclass="form-control bg-white" :value="form.BoD"
                    :max-date="new Date()" @on-change="(dateOb, dateStr) => {
            form.BoD = dateStr
          }" />

        <span
          v-if="form.hasError('BoD')"
          class="text-danger"
          v-text="form.getError('BoD')"
        />
      </div>
      <div class="col-lg-6  mt-4">
        <label class="form-label" for="identification_number">ID no</label>
        <input
          id="identification_number" v-model="form.identification_number" type="text"
          class="form-control"
          autocomplete="off"
        >

        <span
          v-if="form.hasError('identification_number')"
          class="text-danger"
          v-text="form.getError('identification_number')"
        />
      </div>
      <div class="col-lg-6  mt-4">
        <label class="form-label" for="email">Email address</label>
        <input
          id="email" v-model="form.email" type="email"
          class="form-control"
          autocomplete="off"
        >

        <span
          v-if="form.hasError('email')"
          class="text-danger"
          v-text="form.getError('email')"
        />
      </div>
      <div class="col-lg-6  mt-4">
        <label class="form-label" for="residence">Residence</label>
        <input
          id="residence" v-model="form.residence" type="text"
          class="form-control"
          autocomplete="off"
        >

        <span
          v-if="form.hasError('residence')"
          class="text-danger"
          v-text="form.getError('residence')"
        />
      </div>
      <div class="col-lg-6 mt-4">
        <label class="form-label" for="password">Password</label>
        <input
          id="password" v-model="form.password" type="password"
          class="form-control"
          autocomplete="off"
        >

        <span
          v-if="form.hasError('password')"
          class="text-danger"
          v-text="form.getError('password')"
        />
      </div>
      <div class="col-lg-6 mt-4">
        <label class="form-label" for="cpassword">Confirm Password</label>
        <input
          id="cpassword" v-model="form.cpassword" type="password"
          class="form-control"
          autocomplete="off"
        >

        <span
          v-if="form.hasError('cpassword')"
          class="text-danger"
          v-text="form.getError('cpassword')"
        />
      </div>

      <div class="form-footer">
        <div>
          <button
          type="submit"
          class="btn btn-primary py-2 rounded-2 mb-3"
          :disabled="form.processing"
        >
          {{ form.processing ? 'Processing...' : 'Sign in' }}
        </button>
        </div>
       
      </div>
    </form>
    <div class="text-center mt-2 text-muted">Don't have account yet? <a href="/register"> Sign up</a></div>
  </div>
</template>
