<template>
  <VueDocPreview value="http://hrm.ecobiz.co.ke/chart.docx" type="office" />
</template>

<script>
import VueDocPreview from 'vue-doc-preview'
export default {
  components: {
    VueDocPreview
  }
}
</script>