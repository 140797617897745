var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dataTables_length" }, [
    _c("label", { staticClass: "d-inline-flex align-items-center" }, [
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.perPage,
              expression: "perPage",
            },
          ],
          staticClass: "form-select form-select-sm",
          on: {
            change: [
              function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.perPage = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
              _vm.changeLimit,
            ],
          },
        },
        _vm._l(_vm.limits, function (l) {
          return _c("option", { domProps: { value: l } }, [
            _vm._v(" " + _vm._s(l) + " "),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }