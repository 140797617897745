var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "row",
        attrs: { autocomplete: "off" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.register.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "col-lg-6 mt-4" }, [
          _c(
            "label",
            { staticClass: "form-label", attrs: { for: "staff_number" } },
            [_vm._v("Payroll number")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.staff_number,
                expression: "form.staff_number",
              },
            ],
            staticClass: "form-control",
            attrs: { id: "staff_number", type: "text", autocomplete: "off" },
            domProps: { value: _vm.form.staff_number },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "staff_number", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _vm.form.hasError("staff_number")
            ? _c("span", {
                staticClass: "text-danger",
                domProps: {
                  textContent: _vm._s(_vm.form.getError("staff_number")),
                },
              })
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-lg-6 mt-4" }, [
          _c("label", { staticClass: "form-label", attrs: { for: "name" } }, [
            _vm._v("Fullname"),
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.name,
                expression: "form.name",
              },
            ],
            staticClass: "form-control",
            attrs: { id: "name", type: "text", autocomplete: "off" },
            domProps: { value: _vm.form.name },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "name", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _vm.form.hasError("name")
            ? _c("span", {
                staticClass: "text-danger",
                domProps: { textContent: _vm._s(_vm.form.getError("name")) },
              })
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-lg-6 mt-4" }, [
          _c("label", { staticClass: "form-label", attrs: { for: "gender" } }, [
            _vm._v("Gender"),
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.gender,
                expression: "form.gender",
              },
            ],
            staticClass: "form-control",
            attrs: { id: "gender", type: "text", autocomplete: "off" },
            domProps: { value: _vm.form.gender },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "gender", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _vm.form.hasError("gender")
            ? _c("span", {
                staticClass: "text-danger",
                domProps: { textContent: _vm._s(_vm.form.getError("gender")) },
              })
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-lg-6 mt-4" }, [
          _c(
            "label",
            { staticClass: "form-label", attrs: { for: "username" } },
            [_vm._v("Username")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.username,
                expression: "form.username",
              },
            ],
            staticClass: "form-control",
            attrs: { id: "username", type: "text", autocomplete: "off" },
            domProps: { value: _vm.form.username },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "username", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _vm.form.hasError("username")
            ? _c("span", {
                staticClass: "text-danger",
                domProps: {
                  textContent: _vm._s(_vm.form.getError("username")),
                },
              })
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-lg-6 mt-4" },
          [
            _c("label", { staticClass: "form-label", attrs: { for: "BoD" } }, [
              _vm._v("Date of Birth"),
            ]),
            _vm._v(" "),
            _c("date-picker", {
              attrs: {
                name: "date_of_birth",
                formclass: "form-control bg-white",
                value: _vm.form.BoD,
                "max-date": new Date(),
              },
              on: {
                "on-change": (dateOb, dateStr) => {
                  _vm.form.BoD = dateStr
                },
              },
            }),
            _vm._v(" "),
            _vm.form.hasError("BoD")
              ? _c("span", {
                  staticClass: "text-danger",
                  domProps: { textContent: _vm._s(_vm.form.getError("BoD")) },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-lg-6 mt-4" }, [
          _c(
            "label",
            {
              staticClass: "form-label",
              attrs: { for: "identification_number" },
            },
            [_vm._v("ID no")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.identification_number,
                expression: "form.identification_number",
              },
            ],
            staticClass: "form-control",
            attrs: {
              id: "identification_number",
              type: "text",
              autocomplete: "off",
            },
            domProps: { value: _vm.form.identification_number },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "identification_number", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _vm.form.hasError("identification_number")
            ? _c("span", {
                staticClass: "text-danger",
                domProps: {
                  textContent: _vm._s(
                    _vm.form.getError("identification_number")
                  ),
                },
              })
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-lg-6 mt-4" }, [
          _c("label", { staticClass: "form-label", attrs: { for: "email" } }, [
            _vm._v("Email address"),
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.email,
                expression: "form.email",
              },
            ],
            staticClass: "form-control",
            attrs: { id: "email", type: "email", autocomplete: "off" },
            domProps: { value: _vm.form.email },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "email", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _vm.form.hasError("email")
            ? _c("span", {
                staticClass: "text-danger",
                domProps: { textContent: _vm._s(_vm.form.getError("email")) },
              })
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-lg-6 mt-4" }, [
          _c(
            "label",
            { staticClass: "form-label", attrs: { for: "residence" } },
            [_vm._v("Residence")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.residence,
                expression: "form.residence",
              },
            ],
            staticClass: "form-control",
            attrs: { id: "residence", type: "text", autocomplete: "off" },
            domProps: { value: _vm.form.residence },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "residence", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _vm.form.hasError("residence")
            ? _c("span", {
                staticClass: "text-danger",
                domProps: {
                  textContent: _vm._s(_vm.form.getError("residence")),
                },
              })
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-lg-6 mt-4" }, [
          _c(
            "label",
            { staticClass: "form-label", attrs: { for: "password" } },
            [_vm._v("Password")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.password,
                expression: "form.password",
              },
            ],
            staticClass: "form-control",
            attrs: { id: "password", type: "password", autocomplete: "off" },
            domProps: { value: _vm.form.password },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "password", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _vm.form.hasError("password")
            ? _c("span", {
                staticClass: "text-danger",
                domProps: {
                  textContent: _vm._s(_vm.form.getError("password")),
                },
              })
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-lg-6 mt-4" }, [
          _c(
            "label",
            { staticClass: "form-label", attrs: { for: "cpassword" } },
            [_vm._v("Confirm Password")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.cpassword,
                expression: "form.cpassword",
              },
            ],
            staticClass: "form-control",
            attrs: { id: "cpassword", type: "password", autocomplete: "off" },
            domProps: { value: _vm.form.cpassword },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "cpassword", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _vm.form.hasError("cpassword")
            ? _c("span", {
                staticClass: "text-danger",
                domProps: {
                  textContent: _vm._s(_vm.form.getError("cpassword")),
                },
              })
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-footer" }, [
          _c("div", [
            _c(
              "button",
              {
                staticClass: "btn btn-primary py-2 rounded-2 mb-3",
                attrs: { type: "submit", disabled: _vm.form.processing },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.form.processing ? "Processing..." : "Sign in") +
                    "\n      "
                ),
              ]
            ),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center" }, [
      _c("h2", [_vm._v("Create new account")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center mt-2 text-muted" }, [
      _vm._v("Don't have account yet? "),
      _c("a", { attrs: { href: "/register" } }, [_vm._v(" Sign up")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }