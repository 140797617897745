var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isCalendarInitialized
      ? _c(
          "div",
          [_c("FullCalendar", { attrs: { options: _vm.calendarOptions } })],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }