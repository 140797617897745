<script>
import _debounce from 'lodash/debounce'

export default {
  props: {
    genders: {
      type: Array,
      required: true,
      default: function () {
        return []
      },
    },
    departments: {
      type: Array,
      required: true,
      default: function () {
        return []
      },
    },
  },

  data () {
    return {
      fields: [
        {
          name: 'staff_number',
          title: 'StaffNo',
          titleClass: 'text-muted text-center',
          dataClass: 'text-muted text-center',
          width: '5%',
        },
        {
          name: '__slot:name',
          title: 'NAME',
          titleClass: 'ps-2',
          dataClass: 'ps-2 text-truncate',
          width: '30%',
        },
        {
          name: 'gender',
          title: 'Sex',
          titleClass: 'ps-2',
          dataClass: 'ps-2',
          width: '10%',
        },
        {
          name: 'dob',
          title: 'DOB',
          titleClass: 'text-end pe-3',
          dataClass: 'text-end pe-3',
          width: '10%',
        },
        {
          name: 'address',
          title: 'ADDRESS',
          titleClass: 'ps-0',
          dataClass: 'ps-0',
          width: '10%',
        },
        {
          name: 'telephone',
          title: 'MOBILE',
          titleClass: 'ps-0',
          dataClass: 'ps-0',
          width: '10%',
        },
        {
          name: 'created_at',
          title: 'REG. DATE',
          titleClass: 'ps-3',
          dataClass: 'ps-3',
          callback: this.$options.filters.date_DATE_MONTH_YEAR,
          width: '10%',
        },
        {
          name: '__slot:actions',
          title: '',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
      ],

      moreParams: {
        filter: {
          search: '',
        },
      },

      form: new window.Form({
        id: null,
        name: '',
        date_of_birth: '',
        gender_id: '',
        identification_value: '',
        physical_address: '',
        telephone: '',
        email: '',
        kra_pin: '',
        nhif_no:'',
        pays_tax:true,
        pays_nssf:true,
        pays_nhif:true,
        department_id:null,
        nationality: '',
      }),

      selectedPatient: null,

      idNumberTaken: false,
    }
  },

  computed: {
    vuetable () {
      return this.$refs.table.$refs.vuetable
    },
  },

  methods: {

    checkIdentificationStatus (value) {
      if (value) {
        this.$httpClient.get('/check-id-number-status', {
          params: {
            identification_value: value,
          },
        }).then(({ data }) => {
          console.log(data)
          this.idNumberTaken = data.data
        }).catch((error) => {
          // console.error(error.message)
        })
      }
    },

    registerEmployee () {
      this.form.post('/employees')
          .then(() => {
            this.$bvModal.hide('registration-modal')

            this.$toast.success('Employee Registered successfully')

            this.vuetable.reload()
          })
          .catch()
    },



    showEditForm (patient) {
      this.selectedPatient = patient

      setTimeout(() => {
        this.$bvModal.show('edit-modal')
      }, 300)
    },
    applyFilter () {
      // Object.assign(this.moreParams.filter, filters)

      this.vuetable.refresh()
    },

    searchEmployees: _debounce(function () {
      this.applyFilter()
    }, 700),

  },
}
</script>

<template>
  <div>
    <div class="card card-stacked">
      <div class="card-header">
        <div class="input-icon me-2">
          <span class="input-icon-addon">
            <i class="uil uil-search" />
          </span>
          <input
              v-model="moreParams.filter.search" type="text"
              class="form-control bg-muted-lt rounded-2"
              placeholder="Search Employees"
              @input="searchEmployees"
          >
        </div>

        <div class="card-actions">
          <a href="/employees/create" class="btn btn-primary me-3">New Registration</a>
          
        </div>
      </div>

      <VueTable
          ref="table"
          class="table-hover"
          api-url="/datatable/employees"
          :fields="fields"
          :append-params="moreParams"
      >
        <template #name="props">
          <a :href="'/employees/' + props.rowData.id" class="text-reset">{{ props.rowData.name }}</a>
          <br/><small class="text-warning" v-if="props.rowData.employment_status === 1">Suspended</small>
        <small class="text-danger" v-if="props.rowData.employment_status === 2">Terminated</small>
        </template>
        <template #dob="props">
          <div class="row">
            <div v-if="props.rowData.date_of_birth" class="col">
              <div class="font-weight-medium">
                {{ props.rowData.date_of_birth | date_DATE_MONTH_YEAR }}
              </div>
              <small class="text-indigo">
                {{ props.rowData.age }}
              </small>
            </div>

            <div v-else class="col">
              N/A
            </div>
          </div>
        </template>
        <template #actions="props">
          <a :href="'/employees/' + props.rowData.id" class="btn">View</a>
        </template>
      </VueTable>
    </div>

    <b-modal
        id="registration-modal"
        title="Employee Registration"
        no-close-on-backdrop
        hide-header-close
        no-enforce-focus
        lazy
        scrollable
        body-class="px-4"
        footer-class="d-flex justify-content-start border-top"
        modal-class="modal-blur"
        size="lg"
    >
      <form id="form" @submit.prevent="registerEmployee">
        <div class="form-group mb-4">
          <label class="form-label" for="name">Full Name</label>
          <div>
            <input
                id="name"
                v-model="form.name"
                type="text"
                class="form-control"
            >

            <span
                v-if="form.hasError('name')"
                class="text-danger"
                v-text="form.getError('name')"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-xl-6">
            <div class="form-group mb-4">
              <label class="form-label">Date of Birth</label>
              <div>
                <date-picker
                    name="date_of_birth"
                    formclass="form-control bg-white"
                    :value="form.date_of_birth"
                    :max-date="new Date()"
                    @on-change="(dateOb, dateStr) => {
                    form.date_of_birth = dateStr
                  }"
                />

                <span
                    v-if="form.hasError('date_of_birth')"
                    class="text-danger"
                    v-text="form.getError('date_of_birth')"
                />
              </div>
            </div>
          </div>

          <div class="col-xl-6">
            <div class="form-group mb-4">
              <label class="form-label">Gender </label>
              <v-select
                  id="gender_id"
                  v-model="form.gender_id"
                  name="gender_id"
                  :options="genders"
                  label="name"
                  :reduce="option => option.id"
                  :clearable="false"
              />

              <span
                  v-if="form.hasError('gender_id')"
                  class="text-danger"
                  v-text="form.getError('gender_id')"
              />
            </div>
          </div>

        </div>



        <div class="row">
          <div class="col-xl-6">
            <div class="form-group mb-4">
              <label for="identification_value" class="form-label">Identification Number</label>
              <div>
                <input
                    id="identification_value"
                    v-model="form.identification_value"
                    type="text"
                    class="form-control"
                    @blur="checkIdentificationStatus($event.target.value)"
                >

                <span
                    v-if="form.hasError('identification_value')"
                    class="text-danger"
                    v-text="form.getError('identification_value')"
                />
              </div>
            </div>
          </div>
          <div class="col-xl-6">
            <div class="form-group mb-4">
              <label for="telephone" class="form-label">Mobile </label>
              <input
                  id="telephone"
                  v-model="form.telephone" type="tel"
                  class="form-control"
              >
              <span
                  v-if="form.hasError('telephone')"
                  class="text-danger"
                  v-text="form.getError('telephone')"
              />
            </div>
          </div>
        </div>

        <div class="form-group mb-4">
          <label for="physical_address" class="form-label">Physical Address </label>
          <input
              id="physical_address"
              v-model="form.physical_address"
              type="text"
              class="form-control"
          >

          <span
              v-if="form.hasError('physical_address')"
              class="text-danger"
              v-text="form.getError('physical_address')"
          />
        </div>
        <div class="row">
          <div class="col-xl-6">
            <div class="form-group mb-4">
              <label class="form-label">Department </label>
              <v-select
                  id="gender_id"
                  v-model="form.department_id"
                  name="gender_id"
                  :options="departments"
                  label="name"
                  :reduce="option => option.id"
                  :clearable="false"
              />

              <span
                  v-if="form.hasError('department_id')"
                  class="text-danger"
                  v-text="form.getError('department_id')"
              />
            </div>
          </div>
          <div class="col-xl-6">
            <div class="form-group mb-4">
              <label class="form-label">Nationality</label>
              <v-select
                  id="gender_id"
                  v-model="form.nationality"
                  name="gender_id"
                  :options="genders"
                  label="name"
                  :reduce="option => option.id"
                  :clearable="false"
              />

              <span
                  v-if="form.hasError('nationality')"
                  class="text-danger"
                  v-text="form.getError('nationality')"
              />
            </div>
          </div>
          <div class="mb-3">
            <div class="d-flex gap-2">
              <input id="pays_tax" v-model="form.pays_tax" class="form-check-input" type="checkbox">
              <label class="form-label" for="pays_tax">Tax payer</label>
            </div>

            <span
                v-if="form.hasError('pays_tax')"
                class="text-danger"
                v-text="form.getError('pays_tax')"
            />
          </div>
          <div class="mb-3">
            <div class="d-flex gap-2">
              <input id="pays_nhif" v-model="form.pays_nhif" class="form-check-input" type="checkbox">
              <label class="form-label" for="pays_nhif">NHIF payer</label>
            </div>

            <span
                v-if="form.hasError('pays_nhif')"
                class="text-danger"
                v-text="form.getError('pays_nhif')"
            />
          </div>
          <div class="mb-3">
            <div class="d-flex gap-2">
              <input id="pays_nssf" v-model="form.pays_nssf" class="form-check-input" type="checkbox">
              <label class="form-label" for="pays_nssf">NSSF payer</label>
            </div>

            <span
                v-if="form.hasError('pays_nssf')"
                class="text-danger"
                v-text="form.getError('pays_nssf')"
            />
          </div>
          <div class="col-xl-6">
            <div class="form-group mb-4">
              <label class="form-label">Kra Pin</label>
              <input
                  id="physical_address"
                  v-model="form.kra_pin"
                  type="text"
                  class="form-control"
              >
              <span
                  v-if="form.hasError('kra_pin')"
                  class="text-danger"
                  v-text="form.getError('kra_pin')"
              />
            </div>
          </div>
<!--          <div class="col-xl-6">-->
<!--            <div class="form-group mb-4">-->
<!--              <label class="form-label">Date of Employement</label>-->
<!--              <div>-->
<!--                <date-picker-->
<!--                    name="date_of_birth"-->
<!--                    formclass="form-control bg-white"-->
<!--                    :value="form.date_of_birth"-->
<!--                    :max-date="new Date()"-->
<!--                    @on-change="(dateOb, dateStr) => {-->
<!--                    form.date_of_birth = dateStr-->
<!--                  }"-->
<!--                />-->

<!--                <span-->
<!--                    v-if="form.hasError('date_of_birth')"-->
<!--                    class="text-danger"-->
<!--                    v-text="form.getError('date_of_birth')"-->
<!--                />-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
        </div>

        <div v-if="form.processing" class="progress mb-2">
          <div class="progress">
            <div class="progress-bar progress-bar-indeterminate bg-green" />
          </div>
        </div>
      </form>
      <template #modal-footer="{cancel}">
        <button type="button" class="btn me-auto" :disabled="form.processing" @click.prevent="cancel">
          Close
        </button>

        <button
            type="submit" class="btn btn-primary" :disabled="form.processing"
            form="form"
        >
          Register Employee
        </button>
      </template>
    </b-modal>
  </div>
</template>
