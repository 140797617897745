<script>
import _debounce from "lodash/debounce";

export default {
    data() {
        return {
            fields: [
                {
                    name: 'year',
                    title: 'Year',
                    titleClass: 'text-end pe-3',
                    dataClass: 'text-end pe-3',
                    width: '3%',
                },
                {
                    name: 'min',
                    title: 'Min',
                    titleClass: 'ps-3',
                    dataClass: 'ps-3',
                    width: '20%'
                },

                {
                    name: 'max',
                    title: 'Max',
                    titleClass: 'ps-3',
                    dataClass: 'ps-3',
                    width: '20%'
                },
                {
                    name: 'rate',
                    title: 'Rate',
                    titleClass: 'ps-3',
                    dataClass: 'ps-3',
                    width: '10%'
                },
                {
                    name: '__slot:actions',
                    title: '',
                    width: '15%',
                    titleClass: 'text-center',
                    dataClass: 'text-right',
                },
            ],

            moreParams: {
                filter: {
                    year: ''
                },
            },

            selectedTemplate: {},

            form: new window.Form({
                id: '',
                year: '',
                max: '',
                min: '',
                rate: '',

            }),
        }
    },

    computed: {
        vuetable() {
            return this.$refs.table.$refs.vuetable
        },
    },


    methods: {
        showCreateForm() {
            this.$bvModal.show('create-modal')
        },

        createTax() {
            this.form.post('/tax')
                .then(() => {
                    this.$toast.success('The tax has been created')

                    this.vuetable.refresh()

                    this.findAllProducts()
                    this.findSpecimen()
                    this.findmeasurements()

                })
                .catch()
        },

        deleteTemplate(template) {
            this.$toast.question('Are you sure?')
                .then(() => {
                    this.$httpClient.delete('/tax/' + template.id)
                        .then(() => {

                            this.$toast.info('Tax deleted')

                            this.vuetable.refresh()
                        })
                        .catch(error => {
                            this.$toast.error('Something went wrong')

                        })
                })

        },

        showEditForm(tax) {
            this.form.populate(tax)

            this.form.id = tax.id
            this.$bvModal.show('update-modal')
        },

        updateTax() {
            this.form.patch('/tax/' + this.form.id)
                .then(() => {

                    this.$bvModal.hide('update-modal')

                    this.$toast.success('The changes has been saved')

                    this.vuetable.refresh()
                })
                .catch()
        },

        applyFilter: _debounce(function () {
            this.vuetable.reload()
        }, 500),


    },
}
</script>

<template>
    <div>
        <div class="card card-stacked">
            <div class="card-header">
                <div class="input-icon me-2">
                    <span class="input-icon-addon">
                          <i class="uil uil-search"></i>
                    </span>
                    <input type="text" class="form-control bg-muted-lt rounded-2"
                           placeholder="Search Tax"
                           @input="applyFilter"
                           v-model="moreParams.filter.search">
                </div>

                <div class="card-actions">
                    <button class="btn btn-primary" @click="showCreateForm">
                        Add Tax
                    </button>
                </div>

            </div>

            <VueTable
                ref="table"
                api-url="/datatable/tax"
                :fields="fields"
                :append-params="moreParams">

                <template v-slot:actions="props">
                    <button class="btn align-text-top py-1" data-bs-toggle="dropdown">
                        <i class="uil uil-ellipsis-h"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-end">
                        <a class="dropdown-item" href="#" @click="showEditForm(props.rowData)">
                            <i class="uil uil-edit me-2"></i> Edit
                        </a>
                    </div>
                </template>
            </VueTable>
        </div>

        <b-modal
            id="create-modal"
            hide-header-close
            no-close-on-backdrop
            no-enforce-focus
            content-class="rounded-4"
            title="Create Tax"
            size="md"
            footer-class="d-flex justify-content-end bg-light-alpha border-top"
            scrollable
            @hidden="form.reset()">

            <form @submit.prevent="createTax" id="createForm">

                <div class="row">
                    <div class="col-xl-12">
                        <div class="mb-3">
                            <label for="item_id" class="form-label">Year</label>
                          <input type="text" id="name" class="form-control" v-model="form.year">
                            <span v-if="form.hasError('year')"
                                  class="text-danger"
                                  v-text="form.getError('year')">
                             </span>
                        </div>

                        <div class="mb-3">
                            <label for="name" class="form-label">min</label>
                            <input type="text" id="name" class="form-control" v-model="form.min">

                            <span v-if="form.hasError('min')" class="text-danger"
                                  v-text="form.getError('min')">
                            </span>
                        </div>
                      <div class="mb-3">
                            <label for="name" class="form-label">max</label>
                            <input type="text" id="name" class="form-control" v-model="form.max">

                            <span v-if="form.hasError('max')" class="text-danger"
                                  v-text="form.getError('max')">
                            </span>
                        </div>
                      <div class="mb-3">
                            <label for="name" class="form-label">rate</label>
                            <input type="text" id="name" class="form-control" v-model="form.rate">

                            <span v-if="form.hasError('rate')" class="text-danger"
                                  v-text="form.getError('rate')">
                            </span>
                        </div>

                    </div>
                </div>

            </form>

            <template #modal-footer="{cancel}">
                <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">
                    Dismiss
                </button>
                <button type="submit" class="btn btn-primary" :disabled="form.processing" form="createForm">
                    Submit
                </button>
            </template>
        </b-modal>

        <b-modal
            id="update-modal"
            hide-header-close
            no-close-on-backdrop
            no-enforce-focus
            modal-class="modal-blur"
            title="Edit Tax"
            size="md"
            footer-class="d-flex justify-content-end bg-light-alpha border-top"
            scrollable
            @hidden="form.reset()">

          <form @submit.prevent="updateTax" id="createForm">

            <div class="row">
              <div class="col-xl-12">
                <div class="mb-3">
                  <label for="item_id" class="form-label">Year</label>
                  <input type="text" id="name" class="form-control" v-model="form.year">
                  <span v-if="form.hasError('year')"
                        class="text-danger"
                        v-text="form.getError('year')">
                             </span>
                </div>

                <div class="mb-3">
                  <label for="name" class="form-label">min</label>
                  <input type="text" id="name" class="form-control" v-model="form.min">

                  <span v-if="form.hasError('min')" class="text-danger"
                        v-text="form.getError('min')">
                            </span>
                </div>
                <div class="mb-3">
                  <label for="name" class="form-label">max</label>
                  <input type="text" id="name" class="form-control" v-model="form.max">

                  <span v-if="form.hasError('max')" class="text-danger"
                        v-text="form.getError('max')">
                            </span>
                </div>
                <div class="mb-3">
                  <label for="name" class="form-label">rate</label>
                  <input type="text" id="name" class="form-control" v-model="form.rate">

                  <span v-if="form.hasError('rate')" class="text-danger"
                        v-text="form.getError('rate')">
                            </span>
                </div>

              </div>
            </div>

          </form>

          <template #modal-footer="{cancel}">
            <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">
              Dismiss
            </button>
            <button type="submit" class="btn btn-primary" :disabled="form.processing" form="createForm">
              Submit
            </button>
          </template>
        </b-modal>
    </div>
</template>


