<script>

export default {
  props: ['visits'],
  data () {
    return {
      form: new window.Form({
        start_date: '',
        end_date: '',
      }),

    }
  },

  methods: {
    generateReport () {
      this.form.processing = true

      this.$httpClient.get('/reports/employee-listing/generate', {
        responseType: 'blob',
        params: {
          ...this.form.data(),
        },
      })
          .then(({ data }) => {
            this.form.processing = false

            const url = URL.createObjectURL(
                new Blob([data], { type: 'application/pdf' }),
            )

            const link = document.createElement('a')

            link.href = url
            link.target = '_blank'
            link.click()
          }).catch((error) => {
        this.form.processing = false

        this.form.errors.record(error.response.data.errors)
      })
    },
  },
}
</script>

<template>
  <div>
    <div class="card card-stacked card-md mb-4">
      <div class="card-body">
        <div class="row">
          <div class="col-xl-8 mx-auto">
            <div class="mb-4">
              <div class="text-center">
                <h1 class="fw-bolder">
                  Employee Listing Report
                </h1>
              </div>
            </div>

<!--            <div class="row gx-4">-->
<!--              <div class="col-xl-6">-->
<!--                <div class="form-group mb-4">-->
<!--                  <label class="form-label" for="">Starting Date</label>-->
<!--                  <date-picker-->
<!--                      :max-date="new Date()"-->
<!--                      :value="form.start_date"-->
<!--                      @on-change="(date, dateStr) => form.start_date = dateStr"-->
<!--                  />-->

<!--                  <span-->
<!--                      v-if="form.hasError('start_date')"-->
<!--                      class="text-danger"-->
<!--                      v-text="form.getError('start_date')"-->
<!--                  />-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-xl-6">-->
<!--                <div class="form-group mb-4">-->
<!--                  <label class="form-label" for="">Ending Date</label>-->
<!--                  <date-picker-->
<!--                      :max-date="new Date()"-->
<!--                      :value="form.end_date"-->
<!--                      @on-change="(date, dateStr) => form.end_date = dateStr"-->
<!--                  />-->

<!--                  <span-->
<!--                      v-if="form.hasError('end_date')"-->
<!--                      class="text-danger"-->
<!--                      v-text="form.getError('end_date')"-->
<!--                  />-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

            <button
                :disabled="form.processing"
                class="btn btn-primary mx-auto"
                @click="generateReport"
            >
              Generate
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
