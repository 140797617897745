// Globally register all base components for convenience, because they
// will be used very frequently. Components are registered using the
// PascalCased version of their file name.

import Vue from 'vue'
// import upperFirst from 'lodash/upperFirst'
// import camelCase from 'lodash/camelCase'
// https://webpack.js.org/guides/dependency-management/#require-context
// const requireComponent = require.context(
//   // Look for files in the current directory
//   './global',
//   // Do not look in subdirectories
//   false,
//   // Only include "_base-" prefixed .vue files
//   /_base-[\w-]+\.vue$/
// )
//
// // For each matching file name...
// requireComponent.keys().forEach((fileName) => {
//   // Get the component config
//   const componentConfig = requireComponent(fileName)
//   // Get the PascalCase version of the component name
//   const componentName = upperFirst(camelCase(fileName
//       // Remove the "./_" from the beginning
//       .replace(/^\.\/_/, '')
//       // Remove the file extension from the end
//       .replace(/\.\w+$/, '')
//     )
//   )
//   // Globally register the component
//   Vue.component(componentName, componentConfig.default || componentConfig)
// })
// import BaseButton from '@components/global/forms/elements/BaseButton';
// import BaseInput from '@components/global/forms/elements/BaseInput';
import VueTable from '@components/global/table/_baseTable';
import DatePicker from '@components/global/forms/pickers/_baseDatePicker';
// import FileUpload from '@components/global/FileUpload';
import MedicMoney from '@components/global/MedicMoney';
// import MediaUploadAttachment from '@components/global/forms/FileUpload/MediaUploadAttachment'
// import CommentForm from '@components/global/CommentForm';

[
    // BaseButton,
    // BaseInput,
    VueTable,
    DatePicker,
    // FileUpload,
    MedicMoney,
    // MediaUploadAttachment,
    // CommentForm,
].forEach((Component) => {
    Vue.component(Component.name, Component)
})
