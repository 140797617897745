var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c(
            "b-tabs",
            {
              attrs: {
                "active-nav-item-class": "bg-white text-indigo",
                lazy: "",
                "nav-class": "mb-2",
              },
            },
            [
              _c(
                "b-tab",
                {
                  attrs: {
                    "title-item-class": "fs-3",
                    "title-link-class": "px-4 py-2",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("i", { staticClass: "uil uil-spinner-alt me-2" }),
                          _vm._v("Leave Request\n          "),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _c("leave-request", {
                    attrs: { leave_types: _vm.leave_types },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-tab",
                {
                  attrs: {
                    "title-item-class": "fs-3",
                    "title-link-class": "px-4 py-2",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("i", { staticClass: "uil uil-check-circle me-2" }),
                          _vm._v("Allocated Leaves\n          "),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _c("allocated-leaves", {
                    attrs: { leave_types: _vm.leave_types },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-tab",
                {
                  attrs: {
                    "title-item-class": "fs-3",
                    "title-link-class": "px-4 py-2",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("i", {
                            staticClass: "uil uil-closed-circle me-2",
                          }),
                          _vm._v("Previous Leaves\n          "),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [_vm._v(" "), _c("previous-leaves")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }