var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-tabs",
        {
          attrs: {
            "nav-class": "mb-2",
            "active-nav-item-class": "bg-white text-orange",
            lazy: "",
          },
        },
        [
          _c(
            "b-tab",
            {
              attrs: {
                title: "Users",
                "title-link-class": "px-3 py-2",
                "title-item-class": "fs-3",
              },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("i", { staticClass: "uil uil-users-alt me-1" }),
                      _vm._v(" Users\n      "),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm._v(" "),
              _vm.showPermissionForm
                ? _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-xl-6" }, [
                      _c("div", { staticClass: "card shadow-sm" }, [
                        _c("div", { staticClass: "card-header" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.user.name) +
                              " Permission Management\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "card-body" },
                          [
                            _c("v-jstree", {
                              ref: "tree",
                              attrs: {
                                data: _vm.permissions,
                                "show-checkbox": "",
                                multiple: "",
                                "allow-batch": "",
                                "whole-row": "",
                              },
                              on: { "item-click": _vm.onPermissionSelected },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-footer" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-success",
                              attrs: {
                                disabled: _vm.permissionForm.processing,
                              },
                              on: { click: _vm.updateUserPermission },
                            },
                            [_vm._v("\n                Submit\n              ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-light",
                              attrs: {
                                disabled: _vm.permissionForm.processing,
                              },
                              on: { click: _vm.closePermissionForm },
                            },
                            [_vm._v("\n                Close\n              ")]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.permissionForm.processing
                          ? _c(
                              "div",
                              { staticClass: "progress card-progress" },
                              [
                                _c("div", {
                                  staticClass:
                                    "progress-bar progress-bar-indeterminate bg-green",
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ])
                : _c(
                    "div",
                    { staticClass: "card rounded-3 mt-3" },
                    [
                      _c("div", { staticClass: "card-header" }, [
                        _c("div", { staticClass: "input-icon me-2" }, [
                          _c("span", { staticClass: "input-icon-addon" }, [
                            _c("i", { staticClass: "uil uil-search" }),
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.moreParams.filter.name,
                                expression: "moreParams.filter.name",
                              },
                            ],
                            staticClass: "form-control bg-muted-lt rounded-2",
                            attrs: {
                              type: "text",
                              placeholder: "Search Users",
                            },
                            domProps: { value: _vm.moreParams.filter.name },
                            on: {
                              input: [
                                function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.moreParams.filter,
                                    "name",
                                    $event.target.value
                                  )
                                },
                                _vm.applyFilter,
                              ],
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-actions" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              on: { click: _vm.showCreateForm },
                            },
                            [
                              _c("i", { staticClass: "uil uil-plus me-1" }),
                              _vm._v("Add User\n            "),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("VueTable", {
                        ref: "table",
                        attrs: {
                          "api-url": "/datatable/users",
                          fields: _vm.fields,
                          "append-params": _vm.moreParams,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "actions",
                            fn: function (props) {
                              return [
                                _c("div", { staticClass: "dropdown" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn align-text-top py-1",
                                      attrs: { "data-bs-toggle": "dropdown" },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "uil uil-ellipsis-h",
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "dropdown-menu dropdown-menu-end",
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showEditForm(
                                                props.rowData
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "uil uil-pen fs-3 me-2",
                                          }),
                                          _vm._v(" Edit\n                "),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", {
                                        staticClass: "dropdown-divider",
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.accessControlManagement(
                                                props.rowData
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "uil uil-shield fs-3 me-2",
                                          }),
                                          _vm._v(
                                            " Permission\n                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
              _vm._v(" "),
              _c(
                "b-modal",
                {
                  attrs: {
                    id: "createModal",
                    "no-close-on-backdrop": "",
                    title: "Create Account",
                    "hide-header-close": "",
                    "footer-class": "d-flex justify-content-start",
                    "modal-class": "modal-blur",
                    "body-class": "px-4",
                    "no-close-on-esc": "",
                  },
                  on: {
                    hidden: function ($event) {
                      return _vm.form.reset()
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "modal-footer",
                      fn: function ({ cancel }) {
                        return [
                          _c(
                            "button",
                            {
                              staticClass: "btn me-auto",
                              attrs: {
                                type: "button",
                                disabled: _vm.form.processing,
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return cancel.apply(null, arguments)
                                },
                              },
                            },
                            [_vm._v("\n            Close\n          ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: {
                                type: "button",
                                disabled: _vm.form.processing,
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.createUser.apply(null, arguments)
                                },
                              },
                            },
                            [_vm._v("\n            Submit\n          ")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "form",
                    {
                      attrs: { id: "createForm" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.createUser.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "mb-4" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "form-label",
                              attrs: { for: "department_id" },
                            },
                            [_vm._v("Employee Name")]
                          ),
                          _vm._v(" "),
                          _c("v-select", {
                            attrs: {
                              options: _vm.employees,
                              label: "name",
                              reduce: (option) => option.id,
                            },
                            model: {
                              value: _vm.form.employee_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "employee_id", $$v)
                              },
                              expression: "form.employee_id",
                            },
                          }),
                          _vm._v(" "),
                          _vm.form.hasError("employee_id")
                            ? _c("span", {
                                staticClass: "text-danger",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.form.getError("employee_id")
                                  ),
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("small", { staticClass: "form-hint" }, [
                            _vm._v("Employees without user login accounts"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "mb-3" }, [
                        _c(
                          "label",
                          { staticClass: "form-label", attrs: { for: "name" } },
                          [_vm._v("Full Name")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.name,
                              expression: "form.name",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "name", type: "text" },
                          domProps: { value: _vm.form.name },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.form, "name", $event.target.value)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.form.hasError("name")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(_vm.form.getError("name")),
                              },
                            })
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mb-3" }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "username" },
                          },
                          [_vm._v("Username\n            ")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.username,
                              expression: "form.username",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "username", type: "text" },
                          domProps: { value: _vm.form.username },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.form,
                                "username",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("small", { staticClass: "form-hint" }, [
                          _vm._v("Every user should have a unique username"),
                        ]),
                        _vm._v(" "),
                        _vm.form.hasError("username")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.form.getError("username")
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mb-3" }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "password" },
                          },
                          [_vm._v("Password")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.password,
                              expression: "form.password",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "password", type: "password" },
                          domProps: { value: _vm.form.password },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.form,
                                "password",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.form.hasError("password")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.form.getError("password")
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mb-4" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "form-label",
                              attrs: { for: "role_id" },
                            },
                            [_vm._v("Group")]
                          ),
                          _vm._v(" "),
                          _c("v-select", {
                            attrs: {
                              id: "role_id",
                              options: _vm.roles,
                              label: "name",
                              reduce: (option) => option.id,
                            },
                            model: {
                              value: _vm.form.role_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "role_id", $$v)
                              },
                              expression: "form.role_id",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "text-muted small" }, [
                            _vm._v(
                              "When selected, the user will inherit the group's permissions"
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.form.hasError("role_id")
                            ? _c("span", {
                                staticClass: "text-danger",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.form.getError("role_id")
                                  ),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.form.processing
                        ? _c("div", { staticClass: "progress mb-2" }, [
                            _c("div", { staticClass: "progress" }, [
                              _c("div", {
                                staticClass:
                                  "progress-bar progress-bar-indeterminate bg-green",
                              }),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-modal",
                {
                  attrs: {
                    id: "updateModal",
                    title: "Edit Account",
                    "footer-class": "d-flex justify-content-start",
                    "modal-class": "modal-blur",
                    "body-class": "px-4",
                    "no-close-on-backdrop": "",
                    "hide-header-close": "",
                    "no-close-on-esc": "",
                  },
                  on: {
                    hidden: function ($event) {
                      return _vm.form.reset()
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "modal-footer",
                      fn: function ({ cancel }) {
                        return [
                          _c(
                            "button",
                            {
                              staticClass: "btn me-auto",
                              attrs: {
                                type: "button",
                                disabled: _vm.form.processing,
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return cancel.apply(null, arguments)
                                },
                              },
                            },
                            [_vm._v("\n            Close\n          ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: {
                                type: "button",
                                disabled: _vm.form.processing,
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.updateUserAccount.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [_vm._v("\n            Submit\n          ")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "form",
                    {
                      attrs: { id: "EditForm" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.updateUserAccount.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "mb-3" }, [
                        _c(
                          "label",
                          { staticClass: "form-label", attrs: { for: "name" } },
                          [_vm._v("Name")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.name,
                              expression: "form.name",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "name", type: "text" },
                          domProps: { value: _vm.form.name },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.form, "name", $event.target.value)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.form.hasError("name")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(_vm.form.getError("name")),
                              },
                            })
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mb-3" }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "username" },
                          },
                          [_vm._v("Username")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.username,
                              expression: "form.username",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "username", type: "text" },
                          domProps: { value: _vm.form.username },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.form,
                                "username",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.form.hasError("username")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.form.getError("username")
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mb-3" }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "password" },
                          },
                          [_vm._v("Password")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.password,
                              expression: "form.password",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "password", type: "text" },
                          domProps: { value: _vm.form.password },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.form,
                                "password",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "text-muted small" }, [
                          _vm._v(
                            "Only fill when you want to change it, otherwise leave it blank"
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.form.hasError("password")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.form.getError("password")
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mb-3" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "form-label",
                              attrs: { for: "role_id" },
                            },
                            [_vm._v("Group")]
                          ),
                          _vm._v(" "),
                          _c("v-select", {
                            attrs: {
                              id: "role_id",
                              options: _vm.roles,
                              label: "name",
                              reduce: (option) => option.id,
                            },
                            model: {
                              value: _vm.form.role_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "role_id", $$v)
                              },
                              expression: "form.role_id",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "text-muted small" }, [
                            _vm._v(
                              "When selected, the user will inherit the group's permissions"
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.form.hasError("role_id")
                            ? _c("span", {
                                staticClass: "text-danger",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.form.getError("role_id")
                                  ),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "mb-3" }, [
                        _c("label", { staticClass: "row" }, [
                          _c("span", { staticClass: "col" }, [
                            _vm._v("Activated"),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "col-auto" }, [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "form-check form-check-single form-switch",
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.form.activated,
                                      expression: "form.activated",
                                    },
                                  ],
                                  staticClass: "form-check-input",
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(_vm.form.activated)
                                      ? _vm._i(_vm.form.activated, null) > -1
                                      : _vm.form.activated,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.form.activated,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.form,
                                              "activated",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.form,
                                              "activated",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(_vm.form, "activated", $$c)
                                      }
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.form.processing
                        ? _c("div", { staticClass: "progress mb-2" }, [
                            _c("div", { staticClass: "progress" }, [
                              _c("div", {
                                staticClass:
                                  "progress-bar progress-bar-indeterminate bg-green",
                              }),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-tab",
            {
              attrs: {
                title: "Users",
                "title-link-class": "px-3 py-2",
                "title-item-class": "fs-3",
              },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("i", { staticClass: "uil uil-shield me-1" }),
                      _vm._v(" Role\n      "),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [_vm._v(" "), _c("Role")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }