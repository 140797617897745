<template>
  <div v-if="!rowInput" class="form-group"
       :class="[{'has-error': error}, {'required': required}, {'readonly': readonly}, {'disabled': disabled}, col]">
    <label v-if="title" :for="name" class="form-control-label ">{{ title }}</label>

    <div class="input-group input-group-merge" :class="group_class">
      <div v-if="icon" class="input-group-prepend">
                <span class="input-group-text">
                    <i :class="'uil ' + icon"></i>
                </span>
      </div>

      <money :name="name" @input="input" :placeholder="placeholder" v-bind="money" :value="model" :disabled="disabled"
             :masked="masked" class="form-control" :class="moneyClass"></money>
    </div>

    <div class="invalid-feedback d-block" v-if="error" v-html="error"></div>
  </div>

  <div v-else
       :class="[
         { 'has-error': error},
         {'required': required},
         { 'readonly': readonly},
         {'disabled': disabled},
         col
         ]">
    <label v-if="title" :for="name" class="form-control-label">{{ title }}</label>

    <div v-if="icon" class="input-group input-group-merge" :class="group_class">
      <div v-if="icon" class="input-group-prepend">
                <span class="input-group-text">
                    <i :class="'uil ' + icon"></i>
                </span>
      </div>

      <money :name="name" @input="input" :placeholder="placeholder" v-bind="money" :value="model" :disabled="disabled"
             :masked="masked" class="form-control" :class="moneyClass"></money>
    </div>

    <money v-else :name="name" @input="input" :placeholder="placeholder" v-bind="money" :value="model"
           :disabled="disabled" :masked="masked" class="form-control" :class="moneyClass"></money>

    <div class="invalid-feedback d-block" v-if="error" v-html="error"></div>
  </div>
</template>

<script>
import {Money} from 'v-money';

export default {
  name: "medic-money",

  components: {
    Money
  },

  props: {
    title: {
      type: String,
      default: '',
      description: "Selectbox label text"
    },
    placeholder: {
      type: String,
      default: '',
      description: "Selectbox input placeholder text"
    },
    name: {
      type: String,
      default: null,
      description: "Selectbox attribute name"
    },
    value: 0,
    icon: {
      type: String,
      description: "Prepend icon (left)"
    },
    moneyClass: {
      type: String,
      default: null,
      description: "Selectbox disabled status"
    },
    group_class: {
      type: String,
      default: null,
      description: "Selectbox disabled status"
    },
    col: {
      type: String,
      default: null,
      description: "Selectbox disabled status"
    },
    error: {
      type: String,
      default: null,
      description: "Selectbox disabled status"
    },
    required: {
      type: Boolean,
      default: false,
      description: "Selectbox disabled status"
    },
    readonly: {
      type: Boolean,
      default: false,
      description: "Selectbox disabled status"
    },
    disabled: {
      type: [Boolean, Number],
      default: false,
      description: "Selectbox disabled status"
    },
    dynamicCurrency: {
      type: Object,
      default: function () {
        return {
          decimal_mark: '.',
          thousands_separator: ',',
          symbol_first: 1,
          symbol: '$',
          precision: 2,
        };
      },
      description: "Dynamic currency"
    },
    currency: {
      type: Object,
      default: function () {
        return {
          decimal_mark: '.',
          thousands_separator: ',',
          symbol_first: 1,
          symbol: 'KES ',
          precision: 2,
        };
      },
      description: "Default currency"
    },
    masked: {
      type: Boolean,
      default: false,
      description: "Money result value"
    },
    rowInput: {
      type: Boolean,
      default: false,
      description: "Money result value"
    },
  },

  data() {
    return {
      model: this.value,
      money: {
        decimal: this.currency.decimal_mark,
        thousands: this.currency.thousands_separator,
        prefix: (this.currency.symbol_first) ? this.currency.symbol : '',
        suffix: (!this.currency.symbol_first) ? this.currency.symbol : '',
        precision: parseInt(this.currency.precision),
        masked: this.masked
      }
    }
  },

  mounted() {
    //this.model = this.value;

    if (this.dynamicCurrency.code != this.currency.code) {
      if (!this.dynamicCurrency.decimal) {
        this.money = {
          decimal: this.dynamicCurrency.decimal_mark,
          thousands: this.dynamicCurrency.thousands_separator,
          prefix: (this.dynamicCurrency.symbol_first) ? this.dynamicCurrency.symbol : '',
          suffix: (!this.dynamicCurrency.symbol_first) ? this.dynamicCurrency.symbol : '',
          precision: parseInt(this.dynamicCurrency.precision),
          masked: this.masked
        };
      } else {
        this.money = this.dynamicCurrency;
      }
    }

    this.$emit('interface', this.model);
  },

  methods: {
    change() {
      //this.$emit('change', this.model);
      //this.$emit('interface', this.model);

      this.$emit('input', this.model);
    },

    input(event) {
      this.model = event;

      this.$emit('input', event);

      //this.$emit('change', this.model);
      //this.$emit('interface', this.model);
    }
  },

  watch: {
    dynamicCurrency: function (currency) {
      if (!currency) {
        return;
      }

      this.money = {
        decimal: currency.decimal_mark,
        thousands: currency.thousands_separator,
        prefix: (currency.symbol_first) ? currency.symbol : '',
        suffix: (!currency.symbol_first) ? currency.symbol : '',
        precision: parseInt(currency.precision),
        masked: this.masked
      };
    },

    value: function (value) {
      this.model = value;
    },

    model: function (model) {
      this.$emit('change', this.model);
      this.$emit('interface', this.model);
    }
  },
}
</script>

<style scoped>
.text-right.input-price .v-money {
  text-align: right;
}
</style>
