var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "card card-stacked card-md mb-4" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xl-8 mx-auto" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "row gx-4" }, [
              _c("div", { staticClass: "col-xl-6" }, [
                _c(
                  "div",
                  { staticClass: "form-group mb-4" },
                  [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "item_id" } },
                      [_vm._v("Department")]
                    ),
                    _vm._v(" "),
                    _c("v-select", {
                      attrs: {
                        id: "item_id",
                        clearable: false,
                        reduce: (option) => option.id,
                        options: _vm.departments,
                        label: "name",
                      },
                      model: {
                        value: _vm.form.department_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "department_id", $$v)
                        },
                        expression: "form.department_id",
                      },
                    }),
                    _vm._v(" "),
                    _vm.form.errors.has("department_id")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.form.errors.first("department_id")
                            ),
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-xl-6" }, [
                _c("div", { staticClass: "form-group mb-4" }, [
                  _c(
                    "label",
                    { staticClass: "form-label", attrs: { for: "" } },
                    [_vm._v("Year")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.year,
                        expression: "form.year",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "year" },
                    domProps: { value: _vm.form.year },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "year", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.form.errors.has("year")
                    ? _c("span", {
                        staticClass: "text-danger",
                        domProps: {
                          textContent: _vm._s(_vm.form.errors.first("year")),
                        },
                      })
                    : _vm._e(),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary mx-auto",
                attrs: { disabled: _vm.form.processing },
                on: { click: _vm.generateReport },
              },
              [_vm._v("\n            Generate\n          ")]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-4" }, [
      _c("div", { staticClass: "text-center" }, [
        _c("h1", { staticClass: "fw-bolder" }, [
          _vm._v(
            "\n                  Employee Leave Balance Report\n              "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }