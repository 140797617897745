<script>

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  data () {
    return {
      form: new window.Form({
        password: '',
        password_confirmation: '',
      }),

      detailForm: new window.Form({
        name: '',
        username: '',
      }),
    }
  },

  created () {
    this.detailForm.populate(this.user)
  },

  methods: {
    showPasswordForm () {
      this.$bvModal.show('password-modal')
    },

    changePassword () {
      this.form.post('/profile/change-password/' + this.user.hashid)
        .then(() => {
          this.$bvModal.hide('password-modal')

          this.$toast.success('Password has been updated')
        })
        .catch((error) => {

        })
    },

    changeUsername () {
      this.detailForm.post('/profile/change-username/' + this.user.hashid)
        .then(() => {
          this.$bvModal.hide('detail-modal')

          this.$toast.success('The changes have been saved')

          setTimeout(() => {
            window.location.reload()
          }, 3000)
        })
        .catch((error) => {

        })
    },
  },
}
</script>

<template>
  <div>
    <div class="row">
      <div class="col-lg-9">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">
              Account Info
            </h3>
            <div class="card-actions">
              <a href="#" @click="showPasswordForm">
                Change Password
                <i class="uil uil-edit" />
              </a>
            </div>
          </div>
          <div class="card-body">
            <dl class="row">
              <dt class="col-5">
                Name:
              </dt>
              <dd class="col-7">
                {{ user.name }}
              </dd>
              <dt class="col-5">
                Username:
              </dt>
              <dd class="col-7">
                {{ user.username }} <span>
                  <a class="ms-3" href="#" v-b-modal:detail-modal><i class="uil uil-edit"></i> change</a></span>
              </dd>
              <dt class="col-5">
                Department:
              </dt>
              <dd class="col-7">
                {{ user.department ? user.department.name : '' }}
              </dd>
              <dt class="col-5">
                Registered:
              </dt>
              <dd class="col-7">
                {{ user.created_at | date_DAY_MONTH_YEAR }}
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="password-modal"
      title="Change Password"
      no-close-on-backdrop
      body-class="rounded-3"
      footer-bg-variant="light"
      hide-header-close
      footer-class="d-flex justify-content-end border-top"
    >
      <form id="form" autocomplete="off" @submit.prevent="changePassword">
        <div class="form-group mb-4">
          <label for="password" class="form-label">New Password</label>
          <input
            id="password"
            v-model="form.password"
            name="password"
            class="form-control"
            type="password"
          >

          <span
            v-if="form.hasError('password')"
            class="text-danger"
            v-text="form.getError('password')"
          />
        </div>

        <div class="mb-3">
          <label for="password_confirmation" class="form-label">Confirm Password</label>
          <input
            id="password_confirmation"
            v-model="form.password_confirmation"
            name="password_confirmation"
            class="form-control"
            type="password"
          >
          <span
            v-if="form.hasError('password_confirmation')"
            class="text-danger"
            v-text="form.getError('password_confirmation')"
          />
        </div>
      </form>
      <template #modal-footer="{ cancel }">
        <button
          class="btn btn-light"
          :disabled="form.processing"
          @click="cancel"
        >
          Close
        </button>

        <button
          form="form"
          type="submit"
          class="btn btn-success me-2"
          :disabled="form.processing"
        >
          {{ form.processing ? 'Processing' : 'Submit' }}
        </button>
      </template>
    </b-modal>

    <b-modal
      id="detail-modal"
      title="Change Details"
      no-close-on-backdrop
      body-class="rounded-3"
      footer-bg-variant="light"
      hide-header-close
      footer-class="d-flex justify-content-end border-top"
    >
      <form id="detail-form" autocomplete="off" @submit.prevent="changeUsername">
        <div class="form-group mb-4">
          <label for="name" class="form-label">Full Name</label>
          <input
            id="name"
            v-model="detailForm.name"
            name="name"
            class="form-control"
            type="text"
          >

          <span
            v-if="form.hasError('text')"
            class="text-danger"
            v-text="form.getError('text')"
          />
        </div>

        <div class="mb-3">
          <label for="username" class="form-label">Username</label>
          <input
            id="username"
            v-model="detailForm.username"
            name="username"
            class="form-control"
            type="text"
          >
          <span
            v-if="form.hasError('username')"
            class="text-danger"
            v-text="form.getError('username')"
          />
        </div>
      </form>
      <template #modal-footer="{ cancel }">
        <button
          class="btn btn-light"
          :disabled="form.processing"
          @click="cancel"
        >
          Close
        </button>

        <button
          form="detail-form"
          type="submit"
          class="btn btn-success me-2"
          :disabled="form.processing"
        >
          {{ form.processing ? 'Processing' : 'Submit' }}
        </button>
      </template>
    </b-modal>
  </div>
</template>
