import {mapActions, mapGetters, mapState} from 'vuex'

export const authComputed = {
  ...mapState('auth', {
    currentUser: (state) => state.currentUser,
  }),
  ...mapGetters('auth', ['loggedIn']),
};

export const layoutComputed = {
  ...mapState('layout', {
    layoutType: (state) => state.layoutType,
    leftSidebarTheme: (state) => state.leftSidebarTheme,
    leftSidebarType: (state) => state.leftSidebarType,
    layoutWidth: (state) => state.layoutWidth,
  }),
};

export const authMethods = mapActions('auth', [
  'logIn',
  'validate',
  'saveToken',
  'getUserPermissions',
  'getUserRoles',
  'logOut',
  'register',
  'resetPassword',
  'fetchAuthenticatedUser',
]);

export const layoutMethods = mapActions('layout', [
  'changeLayoutType',
  'changeLeftSidebarTheme',
  'changeLeftSidebarType',
  'changeLayoutWidth',
]);

export function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state))
}

export function getSavedState(key, state) {
  return JSON.parse(window.localStorage.getItem(key))
}

export function deleteState(key) {
  window.localStorage.removeItem(key)
}
