<script>

export default {
  props: {
    departments: {
      type: Array,
      required: true,
      default: function () {
        return []
      },
    },
  },
  data() {
    return {
      form: new window.Form({
        department_id: '',
        year: new Date().getFullYear()
      }),

    }
  },

  methods: {
    generateReport() {
      this.form.processing = true

      this.$httpClient.get('/reports/employee-leave-balance/generate', {
        responseType: 'blob',
        params: {
          ...this.form.data(),
        },
      })
        .then(({ data }) => {
          this.form.processing = false

          const url = URL.createObjectURL(
            new Blob([data], { type: 'application/pdf' }),
          )

          const link = document.createElement('a')

          link.href = url
          link.target = '_blank'
          link.click()
        }).catch((error) => {
          this.form.processing = false

          this.form.errors.record(error.response.data.errors)
        })
    },
  },
}
</script>

<template>
  <div>
    <div class="card card-stacked card-md mb-4">
      <div class="card-body">
        <div class="row">
          <div class="col-xl-8 mx-auto">
            <div class="mb-4">
              <div class="text-center">
                <h1 class="fw-bolder">
                    Employee Leave Balance Report
                </h1>
              </div>
            </div>

            <div class="row gx-4">
              <div class="col-xl-6">
                <div class="form-group mb-4">
                  <label class="form-label" for="item_id">Department</label>
                  <v-select id="item_id" v-model="form.department_id" :clearable="false" :reduce="(option) => option.id"
                    :options="departments" label="name">

                  </v-select>
                  <span v-if="form.errors.has('department_id')" class="text-danger"
                    v-text="form.errors.first('department_id')" />
                </div>
              </div>
              <div class="col-xl-6">
                <div class="form-group mb-4">
                  <label class="form-label" for="">Year</label>
                 <input class="form-control" type="year" v-model="form.year" />
                 <span v-if="form.errors.has('year')" class="text-danger"
                    v-text="form.errors.first('year')" />
                </div>
              </div>
            </div>

            <button :disabled="form.processing" class="btn btn-primary mx-auto" @click="generateReport">
              Generate
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
