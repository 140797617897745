var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dataTables_filter text-md-right" }, [
    _c(
      "label",
      { staticClass: "d-inline-flex align-items-center" },
      [
        _vm._v("\n    Search "),
        _c("b-form-input", {
          staticClass: "form-control form-control-sm ml-2",
          attrs: { type: "search", placeholder: "Search..." },
          on: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.doFilter.apply(null, arguments)
            },
          },
          model: {
            value: _vm.filterText,
            callback: function ($$v) {
              _vm.filterText = $$v
            },
            expression: "filterText",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }