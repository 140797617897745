<script>
import Pending from "./Pending.vue";
import Approved from "./Approved.vue";
import Rejected from "./Rejected.vue";

export default {
  components: {
    Rejected,
    Approved,
    Pending,
  },

  data () {
    return {}
  },

}
</script>

<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <b-tabs
            active-nav-item-class="bg-white text-indigo"
            lazy nav-class="mb-2"
        >
          <b-tab title-item-class="fs-3" title-link-class="px-4 py-2">
            <template #title>
              <i class="uil uil-spinner-alt me-2"/>Pending
            </template>

            <pending/>

          </b-tab>
          <b-tab title-item-class="fs-3" title-link-class="px-4 py-2">
            <template #title>
              <i class="uil uil-check-circle me-2"/>Approved
            </template>

            <approved/>

          </b-tab>
          <b-tab title-item-class="fs-3" title-link-class="px-4 py-2">
            <template #title>
              <i class="uil uil-closed-circle me-2"/>Rejected
            </template>

            <rejected/>

          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>
