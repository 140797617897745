<script>

import Vacancies from "./Vacancies.vue";
import Candidates from "./Applications.vue";

export default {
  props: {
    departments: {
      type: Array,
      default: function () {
        return []
      },
    },
    employeetypes: {
      type: Array,
      default: function () {
        return []
      },
    },
    jobgroups: {
      type: Array,
      default: function () {
        return []
      },
    },
    managers: {
      type: Array,
      default: function () {
        return []
      },
    },
    vacancies: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  components: {
    Candidates,
    Vacancies,
  },

  data () {
    return {}
  },

}
</script>

<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <b-tabs
            active-nav-item-class="bg-white text-indigo"
            lazy nav-class="mb-2"
        >
          <b-tab title-item-class="fs-3" title-link-class="px-4 py-2">
            <template #title>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
  <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
</svg> Candidates
            </template>

            <candidates :jobgroups="jobgroups" :vacancies="vacancies" :employeetypes="employeetypes" :departments="departments"/>

          </b-tab>
          <b-tab title-item-class="fs-3" title-link-class="px-4 py-2">
            <template #title>
              <i class="uil uil-check-circle me-2"/>Vacancies
            </template>
            <vacancies :jobgroups="jobgroups" :departments="departments" :managers="managers"/>

          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>
