var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isFixedHeader
    ? _c("div", [
        _c("div", { staticClass: "vuetable-head-wrapper" }, [
          _c("table", { class: ["vuetable", _vm.css.tableClass] }, [
            _c("thead", { class: ["thead-light", _vm.css.tableHeaderClass] }, [
              _c(
                "tr",
                [
                  _vm._l(_vm.tableFields, function (field, fieldIndex) {
                    return [
                      field.visible
                        ? [
                            _vm.isSpecialField(field.name)
                              ? [
                                  _vm.extractName(field.name) == "__checkbox"
                                    ? _c(
                                        "th",
                                        {
                                          key: fieldIndex,
                                          class: [
                                            "vuetable-th-checkbox-" +
                                              _vm.trackBy,
                                            field.titleClass,
                                          ],
                                          style: { width: field.width },
                                        },
                                        [
                                          _c("input", {
                                            attrs: { type: "checkbox" },
                                            domProps: {
                                              checked: _vm.checkCheckboxesState(
                                                field.name
                                              ),
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.toggleAllCheckboxes(
                                                  field.name,
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.extractName(field.name) == "__component"
                                    ? _c("th", {
                                        key: fieldIndex,
                                        class: [
                                          "vuetable-th-component-" +
                                            _vm.trackBy,
                                          field.titleClass,
                                          _vm.sortClass(field),
                                          { sortable: _vm.isSortable(field) },
                                        ],
                                        style: { width: field.width },
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.renderTitle(field)
                                          ),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.orderBy(field, $event)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.extractName(field.name) == "__slot"
                                    ? _c("th", {
                                        key: fieldIndex,
                                        class: [
                                          "vuetable-th-slot-" +
                                            _vm.extractArgs(field.name),
                                          field.titleClass,
                                          _vm.sortClass(field),
                                          { sortable: _vm.isSortable(field) },
                                        ],
                                        style: { width: field.width },
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.renderTitle(field)
                                          ),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.orderBy(field, $event)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.extractName(field.name) == "__sequence"
                                    ? _c("th", {
                                        key: fieldIndex,
                                        class: [
                                          "vuetable-th-sequence",
                                          field.titleClass || "",
                                        ],
                                        style: { width: field.width },
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.renderTitle(field)
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.notIn(_vm.extractName(field.name), [
                                    "__sequence",
                                    "__checkbox",
                                    "__component",
                                    "__slot",
                                  ])
                                    ? _c("th", {
                                        key: fieldIndex,
                                        class: [
                                          "vuetable-th-" + field.name,
                                          field.titleClass || "",
                                        ],
                                        style: { width: field.width },
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.renderTitle(field)
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              : [
                                  _c("th", {
                                    key: fieldIndex,
                                    class: [
                                      "vuetable-th-" + field.name,
                                      field.titleClass,
                                      _vm.sortClass(field),
                                      { sortable: _vm.isSortable(field) },
                                    ],
                                    style: { width: field.width },
                                    attrs: { id: "_" + field.name },
                                    domProps: {
                                      innerHTML: _vm._s(_vm.renderTitle(field)),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.orderBy(field, $event)
                                      },
                                    },
                                  }),
                                ],
                          ]
                        : _vm._e(),
                    ]
                  }),
                  _vm._v(" "),
                  _vm.scrollVisible
                    ? _c("th", {
                        staticClass: "vuetable-gutter-col",
                        style: { width: _vm.scrollBarWidth },
                      })
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "vuetable-body-wrapper",
            style: { height: _vm.tableHeight },
          },
          [
            _c(
              "table",
              {
                class: ["vuetable", _vm.css.tableClass, _vm.css.tableBodyClass],
              },
              [
                _c(
                  "colgroup",
                  [
                    _vm._l(_vm.tableFields, function (field, fieldIndex) {
                      return [
                        field.visible
                          ? [
                              _c("col", {
                                key: fieldIndex,
                                class: [
                                  "vuetable-th-" + field.name,
                                  field.titleClass,
                                ],
                                style: { width: field.width },
                                attrs: { id: "_col_" + field.name },
                              }),
                            ]
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "tbody",
                  { staticClass: "vuetable-body" },
                  [
                    _vm._l(_vm.tableData, function (item, itemIndex) {
                      return [
                        _c(
                          "tr",
                          {
                            key: itemIndex,
                            class: _vm.onRowClass(item, itemIndex),
                            attrs: {
                              "item-index": itemIndex,
                              render: _vm.onRowChanged(item),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onRowClicked(item, $event)
                              },
                              dblclick: function ($event) {
                                return _vm.onRowDoubleClicked(item, $event)
                              },
                            },
                          },
                          [
                            _vm._l(
                              _vm.tableFields,
                              function (field, fieldIndex) {
                                return [
                                  field.visible
                                    ? [
                                        _vm.isSpecialField(field.name)
                                          ? [
                                              _vm.extractName(field.name) ==
                                              "__sequence"
                                                ? _c("td", {
                                                    key: fieldIndex,
                                                    class: [
                                                      "vuetable-sequence",
                                                      field.dataClass,
                                                    ],
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.renderSequence(
                                                          itemIndex
                                                        )
                                                      ),
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.extractName(field.name) ==
                                              "__handle"
                                                ? _c("td", {
                                                    key: fieldIndex,
                                                    class: [
                                                      "vuetable-handle",
                                                      field.dataClass,
                                                    ],
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.renderIconTag([
                                                          "handle-icon",
                                                          _vm.css.handleIcon,
                                                        ])
                                                      ),
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.extractName(field.name) ==
                                              "__checkbox"
                                                ? _c(
                                                    "td",
                                                    {
                                                      key: fieldIndex,
                                                      class: [
                                                        "vuetable-checkboxes",
                                                        field.dataClass,
                                                      ],
                                                    },
                                                    [
                                                      _c("input", {
                                                        attrs: {
                                                          type: "checkbox",
                                                        },
                                                        domProps: {
                                                          checked:
                                                            _vm.rowSelected(
                                                              item,
                                                              field.name
                                                            ),
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.toggleCheckbox(
                                                              item,
                                                              field.name,
                                                              $event
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.extractName(field.name) ===
                                              "__component"
                                                ? _c(
                                                    "td",
                                                    {
                                                      key: fieldIndex,
                                                      class: [
                                                        "vuetable-component",
                                                        field.dataClass,
                                                      ],
                                                    },
                                                    [
                                                      _c(
                                                        _vm.extractArgs(
                                                          field.name
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            "row-data": item,
                                                            "row-index":
                                                              itemIndex,
                                                            "row-field":
                                                              field.sortField,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.extractName(field.name) ===
                                              "__slot"
                                                ? _c(
                                                    "td",
                                                    {
                                                      key: fieldIndex,
                                                      class: [
                                                        "vuetable-slot",
                                                        field.dataClass,
                                                      ],
                                                    },
                                                    [
                                                      _vm._t(
                                                        _vm.extractArgs(
                                                          field.name
                                                        ),
                                                        null,
                                                        {
                                                          rowData: item,
                                                          rowIndex: itemIndex,
                                                          rowField:
                                                            field.sortField,
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  )
                                                : _vm._e(),
                                            ]
                                          : [
                                              _c("td", {
                                                key: fieldIndex,
                                                class: field.dataClass,
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.renderNormalField(
                                                      field,
                                                      item
                                                    )
                                                  ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onCellClicked(
                                                      item,
                                                      field,
                                                      $event
                                                    )
                                                  },
                                                  dblclick: function ($event) {
                                                    return _vm.onCellDoubleClicked(
                                                      item,
                                                      field,
                                                      $event
                                                    )
                                                  },
                                                  contextmenu: function (
                                                    $event
                                                  ) {
                                                    return _vm.onCellRightClicked(
                                                      item,
                                                      field,
                                                      $event
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                      ]
                                    : _vm._e(),
                                ]
                              }
                            ),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _vm.useDetailRow
                          ? [
                              _c(
                                "transition",
                                {
                                  key: itemIndex,
                                  attrs: { name: _vm.detailRowTransition },
                                },
                                [
                                  _vm.isVisibleDetailRow(item[_vm.trackBy])
                                    ? _c(
                                        "tr",
                                        {
                                          class: [_vm.css.detailRowClass],
                                          on: {
                                            click: function ($event) {
                                              return _vm.onDetailRowClick(
                                                item,
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "td",
                                            {
                                              attrs: {
                                                colspan: _vm.countVisibleFields,
                                              },
                                            },
                                            [
                                              _c(_vm.detailRowComponent, {
                                                tag: "component",
                                                attrs: {
                                                  "row-data": item,
                                                  "row-index": itemIndex,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          : _vm._e(),
                      ]
                    }),
                    _vm._v(" "),
                    _vm.displayEmptyDataRow
                      ? [
                          _c("tr", [
                            _c("td", {
                              staticClass: "vuetable-empty-result",
                              attrs: { colspan: _vm.countVisibleFields },
                              domProps: {
                                innerHTML: _vm._s(_vm.noDataTemplate),
                              },
                            }),
                          ]),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.lessThanMinRows
                      ? _vm._l(_vm.blankRows, function (i) {
                          return _c(
                            "tr",
                            { key: i, staticClass: "blank-row" },
                            [
                              _vm._l(
                                _vm.tableFields,
                                function (field, fieldIndex) {
                                  return [
                                    field.visible
                                      ? _c("td", { key: fieldIndex }, [
                                          _vm._v(
                                            "\n                               \n              "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                }
                              ),
                            ],
                            2
                          )
                        })
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            ),
          ]
        ),
      ])
    : _c("table", { class: ["vuetable", _vm.css.tableClass] }, [
        _c("thead", [
          _c(
            "tr",
            { staticClass: "fixed-top" },
            [
              _vm._l(_vm.tableFields, function (field, fieldIndex) {
                return [
                  field.visible
                    ? [
                        _vm.isSpecialField(field.name)
                          ? [
                              _vm.extractName(field.name) == "__checkbox"
                                ? _c(
                                    "th",
                                    {
                                      key: fieldIndex,
                                      class: [
                                        "vuetable-th-checkbox-" + _vm.trackBy,
                                        field.titleClass,
                                      ],
                                      style: { width: field.width },
                                    },
                                    [
                                      _c("input", {
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          checked: _vm.checkCheckboxesState(
                                            field.name
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.toggleAllCheckboxes(
                                              field.name,
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.extractName(field.name) == "__component"
                                ? _c("th", {
                                    key: fieldIndex,
                                    class: [
                                      "vuetable-th-component-" + _vm.trackBy,
                                      field.titleClass,
                                      _vm.sortClass(field),
                                      { sortable: _vm.isSortable(field) },
                                    ],
                                    style: { width: field.width },
                                    domProps: {
                                      innerHTML: _vm._s(_vm.renderTitle(field)),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.orderBy(field, $event)
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.extractName(field.name) == "__slot"
                                ? _c("th", {
                                    key: fieldIndex,
                                    class: [
                                      "vuetable-th-slot-" +
                                        _vm.extractArgs(field.name),
                                      field.titleClass,
                                      _vm.sortClass(field),
                                      { sortable: _vm.isSortable(field) },
                                    ],
                                    style: { width: field.width },
                                    domProps: {
                                      innerHTML: _vm._s(_vm.renderTitle(field)),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.orderBy(field, $event)
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.extractName(field.name) == "__sequence"
                                ? _c("th", {
                                    key: fieldIndex,
                                    class: [
                                      "vuetable-th-sequence",
                                      field.titleClass || "",
                                      _vm.sortClass(field),
                                    ],
                                    style: { width: field.width },
                                    domProps: {
                                      innerHTML: _vm._s(_vm.renderTitle(field)),
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.notIn(_vm.extractName(field.name), [
                                "__sequence",
                                "__checkbox",
                                "__component",
                                "__slot",
                              ])
                                ? _c("th", {
                                    key: fieldIndex,
                                    class: [
                                      "vuetable-th-" + field.name,
                                      field.titleClass || "",
                                      _vm.sortClass(field),
                                    ],
                                    style: { width: field.width },
                                    domProps: {
                                      innerHTML: _vm._s(_vm.renderTitle(field)),
                                    },
                                  })
                                : _vm._e(),
                            ]
                          : [
                              _c("th", {
                                key: fieldIndex,
                                class: [
                                  "vuetable-th-" + field.name,
                                  field.titleClass,
                                  _vm.sortClass(field),
                                  { sortable: _vm.isSortable(field) },
                                ],
                                style: { width: field.width },
                                attrs: { id: "_" + field.name },
                                domProps: {
                                  innerHTML: _vm._s(_vm.renderTitle(field)),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.orderBy(field, $event)
                                  },
                                },
                              }),
                            ],
                      ]
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          { staticClass: "vuetable-body" },
          [
            _vm._l(_vm.tableData, function (item, itemIndex) {
              return [
                _c(
                  "tr",
                  {
                    key: itemIndex,
                    class: _vm.onRowClass(item, itemIndex),
                    attrs: {
                      "item-index": itemIndex,
                      render: _vm.onRowChanged(item),
                    },
                    on: {
                      dblclick: function ($event) {
                        return _vm.onRowDoubleClicked(item, $event)
                      },
                      click: function ($event) {
                        return _vm.onRowClicked(item, $event)
                      },
                    },
                  },
                  [
                    _vm._l(_vm.tableFields, function (field, fieldIndex) {
                      return [
                        field.visible
                          ? [
                              _vm.isSpecialField(field.name)
                                ? [
                                    _vm.extractName(field.name) == "__sequence"
                                      ? _c("td", {
                                          key: fieldIndex,
                                          class: [
                                            "vuetable-sequence",
                                            field.dataClass,
                                          ],
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.renderSequence(itemIndex)
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.extractName(field.name) == "__handle"
                                      ? _c("td", {
                                          key: fieldIndex,
                                          class: [
                                            "vuetable-handle",
                                            field.dataClass,
                                          ],
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.renderIconTag([
                                                "handle-icon",
                                                _vm.css.handleIcon,
                                              ])
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.extractName(field.name) == "__checkbox"
                                      ? _c(
                                          "td",
                                          {
                                            key: fieldIndex,
                                            class: [
                                              "vuetable-checkboxes",
                                              field.dataClass,
                                            ],
                                          },
                                          [
                                            _c("input", {
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: _vm.rowSelected(
                                                  item,
                                                  field.name
                                                ),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.toggleCheckbox(
                                                    item,
                                                    field.name,
                                                    $event
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.extractName(field.name) ===
                                    "__component"
                                      ? _c(
                                          "td",
                                          {
                                            key: fieldIndex,
                                            class: [
                                              "vuetable-component",
                                              field.dataClass,
                                            ],
                                          },
                                          [
                                            _c(_vm.extractArgs(field.name), {
                                              tag: "component",
                                              attrs: {
                                                "row-data": item,
                                                "row-index": itemIndex,
                                                "row-field": field.sortField,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.extractName(field.name) === "__slot"
                                      ? _c(
                                          "td",
                                          {
                                            key: fieldIndex,
                                            class: [
                                              "vuetable-slot",
                                              field.dataClass,
                                            ],
                                          },
                                          [
                                            _vm._t(
                                              _vm.extractArgs(field.name),
                                              null,
                                              {
                                                rowData: item,
                                                rowIndex: itemIndex,
                                                rowField: field.sortField,
                                              }
                                            ),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                  ]
                                : [
                                    _vm.hasCallback(field)
                                      ? _c("td", {
                                          key: fieldIndex,
                                          class: field.dataClass,
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.callCallback(field, item)
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onCellClicked(
                                                item,
                                                field,
                                                $event
                                              )
                                            },
                                            dblclick: function ($event) {
                                              return _vm.onCellDoubleClicked(
                                                item,
                                                field,
                                                $event
                                              )
                                            },
                                            contextmenu: function ($event) {
                                              return _vm.onCellRightClicked(
                                                item,
                                                field,
                                                $event
                                              )
                                            },
                                          },
                                        })
                                      : _c("td", {
                                          key: fieldIndex,
                                          class: field.dataClass,
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.getObjectValue(
                                                item,
                                                field.name,
                                                ""
                                              )
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onCellClicked(
                                                item,
                                                field,
                                                $event
                                              )
                                            },
                                            dblclick: function ($event) {
                                              return _vm.onCellDoubleClicked(
                                                item,
                                                field,
                                                $event
                                              )
                                            },
                                            contextmenu: function ($event) {
                                              return _vm.onCellRightClicked(
                                                item,
                                                field,
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                  ],
                            ]
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.useDetailRow
                  ? [
                      _c(
                        "transition",
                        {
                          key: itemIndex,
                          attrs: { name: _vm.detailRowTransition },
                        },
                        [
                          _vm.isVisibleDetailRow(item[_vm.trackBy])
                            ? _c(
                                "tr",
                                {
                                  class: [_vm.css.detailRowClass],
                                  on: {
                                    click: function ($event) {
                                      return _vm.onDetailRowClick(item, $event)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "td",
                                    {
                                      attrs: {
                                        colspan: _vm.countVisibleFields,
                                      },
                                    },
                                    [
                                      _c(_vm.detailRowComponent, {
                                        tag: "component",
                                        attrs: {
                                          "row-data": item,
                                          "row-index": itemIndex,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  : _vm._e(),
              ]
            }),
            _vm._v(" "),
            _vm.displayEmptyDataRow
              ? [
                  _c("tr", [
                    _c("td", {
                      staticClass: "vuetable-empty-result",
                      attrs: { colspan: _vm.countVisibleFields },
                      domProps: { innerHTML: _vm._s(_vm.noDataTemplate) },
                    }),
                  ]),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.lessThanMinRows
              ? _vm._l(_vm.blankRows, function (i) {
                  return _c(
                    "tr",
                    { key: i, staticClass: "blank-row" },
                    [
                      _vm._l(_vm.tableFields, function (field, fieldIndex) {
                        return [
                          field.visible
                            ? _c("td", { key: fieldIndex }, [
                                _vm._v("\n                       \n          "),
                              ])
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  )
                })
              : _vm._e(),
          ],
          2
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }