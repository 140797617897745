<script>

export default {
  data () {
    return {
      form: new window.Form({
        year: new Date().getFullYear(),
        month:'',
      }),
      months: [
        'January', 'February', 'March', 'April', 'May', 'June', 'July',
        'August', 'September', 'October', 'November', 'December'
      ],


    }
  },

  methods: {
    generateReport () {
      this.form.processing = true
      this.$httpClient.get('/reports/paye/generate', {
        responseType: 'blob',
        params: {
          ...this.form.data(),
        },
      })
          .then(({ data }) => {
            this.form.processing = false

            const url = URL.createObjectURL(
                new Blob([data], { type: 'application/pdf' }),
            )

            const link = document.createElement('a')

            link.href = url
            link.target = '_blank'
            link.click()
          }).catch((error) => {
        if (error.response && error.response.data && error.response.data.error) {

          const errorMessage = error.response.data.error;
          console.log(error.response.data);
          this.p9Form.errors.record(errorMessage);
        } else {
          console.error('An unexpected error occurred.');
          console.log(error.response.status);
          if(error.response.status === 400){
            this.$toast.error('No payment for the period selected')
          }
          this.form.errors.record('An unexpected error occurred. Please try again later.');
        }
        this.form.processing = false;
      })
    },
  },
}
</script>

<template>
  <div>
    <div class="card card-stacked card-md mb-4">
      <div class="card-body">
        <div class="row">
          <div class="col-xl-8 mx-auto">
            <div class="mb-4">
              <div class="text-start">
                <h1 class="fw-bolder">
                  Generate PAYE
                </h1>
              </div>
            </div>

            <div class="row gx-4">
              <div class="col-xl-6">
                <div class="form-group mb-4">
                  <label class="form-label" for="">Month</label>
                  <v-select id="scheme_id" v-model="form.month" label="name" :options="months"
                            :reduce="(option) => option" :clearable="false" />
                  <span v-if="form.hasError('month')" class="text-danger"
                        v-text="form.getError('month')" />
                </div>
              </div>
              <div class="col-xl-6">
                <div class="form-group mb-4">
                  <label class="form-label" for="">Year</label>
                  <input class="form-control" type="number" v-model="form.year" name="year" id="year">
                  <span
                      v-if="form.hasError('year')"
                      class="text-danger"
                      v-text="form.getError('year')"
                  />
                </div>
              </div>

            </div>

            <button
                :disabled="form.processing"
                class="btn btn-primary mx-auto"
                @click="generateReport"
            >
              Generate
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
