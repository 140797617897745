<script>

export default {
  props: {
    leave_types: {
      type: Array,
      required: true,
      default: function () {
        return []
      },
    },
  },
  data () {
    return {
      employees: [],
      fields: [
        {
          name: 'leave_type.name',
          title: 'Leave Type',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },

        {
          name: 'days',
          title: 'Days',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },
        {
          name: 'year',
          title: 'Year',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },
        {
          name: '__slot:actions',
          width: '15%',
          titleClass: 'text-center',
          dataClass: 'text-right',
        },
      ],

      moreParams: {
        filter: {
          search: ''
        },
      },

      form: new window.Form({
        id: null,
        leave_type_id: '',
        employee_leave_type_id: '',
        start_date: '',
        end_date: '',
        return_date: '',
      }),
    }
  },

  computed: {
    vuetable () {
      return this.$refs.table.$refs.vuetable
    },
  },
  mounted () {
    this.retrieveEmployees()
  },
  watch: {
    'form.leave_type_id': function (id) {
      let leaveType = this.leave_types.find(type => type.id === id)
      this.form.days = leaveType.days
    },
    'form.end_date': function (date) {
      console.log(date);
      this.form.return_date = date;
    },
  },
  methods: {
    retrieveEmployees () {
      this.$httpClient.get('/datatable/employees/')
        .then(({ data }) => {
          this.employees = data.data.filter((employee)=>employee.employment_status === 0 && employee);
        }).catch(() => {
          this.$toast.error('There was a problem retrieving the Employees')
        })
    },
    showCreateForm () {
      this.$bvModal.show('create-modal')
    },
    showUpdateForm (rowData) {
      this.selectedDepartment = rowData
      this.viewEditForm = true
    },

    createAssignLeave () {
      this.form.post('/user/leave-requests')
        .then(() => {
          this.$bvModal.hide('create-modal');
          this.$toast.success('The leave request has been submitted successfully.');
          this.vuetable.reload();
        })
        .catch()
      !this.form.successful && this.$toast.error('Failed to submit leave request. Please try again.');
    },

    showEditForm (rowData) {
      this.form.populate(rowData)
      this.form.id = rowData.id

      this.$bvModal.show('update-modal')
    },

    editLeave () {
      this.form.patch('/user/leave-requests/' + this.form.id)
        .then(() => {
          this.vuetable.reload()
          this.$bvModal.hide('update-modal')
          this.$toast.success('The changes have been saved')
        })
        .catch()
    },

    applyFilter () {
      this.vuetable.reload()
    },

    cleanUp () {
      this.form.reset()
    },
  },
}
</script>

<template>
  <div>
    <div class="card card-stacked">
      <div class="card-header">
        <div class="input-icon me-2">
          <span class="input-icon-addon">
            <i class="uil uil-search"></i>
          </span>
          <input type="text" class="form-control bg-muted-lt rounded-2" placeholder="Search leave" @input="applyFilter"
            v-model="moreParams.filter.search">
        </div>

        <div class="card-actions">
        </div>
      </div>
      <VueTable ref="table" :api-mode="false" :data="leave_types" :fields="fields" :append-params="moreParams">
        <template v-slot:actions="props">
          <div class="dropdown">
            <button class="btn align-text-top py-1" data-bs-toggle="dropdown">
              <i class="uil uil-ellipsis-h"></i>
            </button>

          </div>
        </template>
      </VueTable>
    </div>

    <b-modal id="create-modal" title="Request Leave" hide-header-close footer-class="d-flex justify-content-start"
      modal-class="modal-blur" no-close-on-esc>
      <form @submit.prevent="createAssignLeave" id="createForm">
        <div class="row">
          <div class="col-lg-12">
            <div class="mb-3">
              <label for="name" class="form-label">Leave Type</label>
              <v-select id="gender_id" v-model="form.leave_type_id" name="leave_type_id" :options="leave_types"
                label="leave_type_id" :reduce="option => option.id" :clearable="false">
                <template v-slot:option="option">
                  <li class="media mb-1">
                    <div class="media-body">
                      <div class="d-flex justify-content-between">
                        <span>{{ option.leave_type.name }}</span>
                      </div>
                    </div>
                  </li>
                </template>
                <template v-slot:selected-option="option">
                  <li class="media mb-1">
                    <div class="media-body">
                      <div class="d-flex justify-content-between">
                        <span>{{ option.leave_type.name }}</span>
                      </div>
                    </div>
                  </li>
                </template>
              </v-select>
              <span v-if="form.hasError('leave_type_id')" class="text-danger" v-text="form.getError('leave_type_id')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Start Date</label>
              <date-picker name="date_of_birth" :value="form.start_date" :max-date="false" @on-change="(dateOb, dateStr) => {
              form.start_date = dateStr
            }" />

              <span v-if="form.hasError('start_date')" class="text-danger" v-text="form.getError('start_date')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">End Date</label>
              <date-picker name="date_of_birth" :value="form.end_date" :max-date="false" @on-change="(dateOb, dateStr) => {
              form.end_date = dateStr
            }" />

              <span v-if="form.hasError('end_date')" class="text-danger" v-text="form.getError('end_date ')">
              </span>
            </div>
          </div>
          <!--          <div class="col-lg-6">-->
          <!--            <div class="mb-3">-->
          <!--              <label for="name" class="form-label">Days</label>-->
          <!--              <input type="text" class="form-control" id="name" v-model="form.days">-->

          <!--              <span-->
          <!--                  v-if="form.hasError('days')"-->
          <!--                  class="text-danger"-->
          <!--                  v-text="form.getError('days')">-->
          <!--                    </span>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Return Date</label>
              <date-picker name="date_of_birth" :value="form.return_date" :max-date="false" @on-change="(dateOb, dateStr) => {
              form.return_date = dateStr
            }" />

              <span v-if="form.hasError('return_date')" class="text-danger" v-text="form.getError('return_date ')">
              </span>
            </div>
          </div>
        </div>


        <div class="progress mb-2" v-if="form.processing">
          <div class="progress">
            <div class="progress-bar progress-bar-indeterminate bg-green"></div>
          </div>
        </div>

      </form>

      <template #modal-footer="{ cancel }">
        <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">Close
        </button>
        <button type="button" class="btn btn-primary" :disabled="form.processing" @click.prevent="createAssignLeave">
          Submit
        </button>
      </template>

    </b-modal>

    <b-modal id="update-modal" title="Edit Leave" hide-header-close size="lg"
      footer-class="d-flex justify-content-start" modal-class="modal-blur" no-close-on-esc>
      <form @submit.prevent="editLeave" id="createForm">
        <div class="row">
          <div class="col-lg-12">
            <div class="mb-3">
              <label for="name" class="form-label">Leave Type</label>
              <v-select id="gender_id" v-model="form.employee_leave_type_id" name="leave_type_id" :options="leave_types"
                label="leave_type_id" :reduce="option => option.id" :clearable="false">
                <template v-slot:option="option">
                  <li class="media mb-1">
                    <div class="media-body">
                      <div class="d-flex justify-content-between">
                        <span>{{ option.leave_type.name }}</span>
                      </div>
                    </div>
                  </li>
                </template>
                <template v-slot:selected-option="option">
                  <li class="media mb-1">
                    <div class="media-body">
                      <div class="d-flex justify-content-between">
                        <span>{{ option.leave_type.name }}</span>
                      </div>
                    </div>
                  </li>
                </template>
              </v-select>
              <span v-if="form.hasError('leave_type_id')" class="text-danger" v-text="form.getError('leave_type_id')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Start Date</label>
              <date-picker name="date_of_birth" :value="form.start_date" :max-date="false" @on-change="(dateOb, dateStr) => {
              form.start_date = dateStr
            }" />

              <span v-if="form.hasError('start_date')" class="text-danger" v-text="form.getError('start_date')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">End Date</label>
              <date-picker name="date_of_birth" :value="form.end_date" :max-date="false" @on-change="(dateOb, dateStr) => {
              form.end_date = dateStr
            }" />

              <span v-if="form.hasError('end_date')" class="text-danger" v-text="form.getError('end_date ')">
              </span>
            </div>
          </div>
          <!--          <div class="col-lg-6">-->
          <!--            <div class="mb-3">-->
          <!--              <label for="name" class="form-label">Days</label>-->
          <!--              <input type="text" class="form-control" id="name" v-model="form.days">-->

          <!--              <span-->
          <!--                  v-if="form.hasError('days')"-->
          <!--                  class="text-danger"-->
          <!--                  v-text="form.getError('days')">-->
          <!--                    </span>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Return Date</label>
              <date-picker name="date_of_birth" :value="form.return_date" :max-date="futureDate" @on-change="(dateOb, dateStr) => {
              form.return_date = dateStr
            }" />

              <span v-if="form.hasError('return_date')" class="text-danger" v-text="form.getError('return_date ')">
              </span>
            </div>
          </div>
        </div>


        <div class="progress mb-2" v-if="form.processing">
          <div class="progress">
            <div class="progress-bar progress-bar-indeterminate bg-green"></div>
          </div>
        </div>

      </form>
      <template #modal-footer="{ cancel }">
        <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">Close
        </button>
        <button type="button" class="btn btn-primary" :disabled="form.processing" @click.prevent="editLeave">
          Submit
        </button>

      </template>
    </b-modal>

  </div>
</template>
