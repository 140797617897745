<script>

export default {
  props: {
    orginfo: {
      type: Object,
      required:false,
      default: () => {},
    },
    canadd:{
      type: Number,
      required:false,
      default: () => false
    }
  },

  data() {
    return {
      editForm:false,
      newRecord: false,
      uploadChart:false,
      form: new window.Form({
        id: null,
        name: '',
        kra_pin: '',
        phone: '',
        city: '',
        address: '',
        fax: '',
        email: '',
        postal_code: '',
      }),
      chart: new window.Form({
        chart: '',
      }),
    }
  },
  mounted() {
    this.checkOrgInfo()
  },
  watch:{
    'chart.chart': function (){
      this.chart.post('/org-info/chart')
          .then(() => {
            this.$toast.success('Organization details have been saved successfully')

            setTimeout(() => {
              window.location.assign('/org-info/chart')
            }, 2000)
          })
          .catch((error)=>{
            if (error.response && error.response.data && error.response.data.errors) {
              this.form.errors.record(error.response.data.errors);
            } else {
              this.$toast.error(error.response.data)
            }
          })
    },
    'editForm':function(value){

    },
  },
  methods: {
    checkOrgInfo(){
      if(Object.keys(this.orginfo).length === 0){
        this.editForm = true;
        this.newRecord = true;
      }
      else{
        this.form.populate(this.orginfo)
        this.form.address = this.orginfo.address_1;
        this.newRecord = false;
      }
    },
    saveOrganizationInfo() {
      if(this.newRecord){
        this.form.post('/org-info')
            .then(() => {
              this.$toast.success('Organization details have been saved successfully')

              setTimeout(() => {
                window.location.assign('/org-info')
              }, 2000)
            })
            .catch()
      }else{
        this.form.patch('/org-info/'+this.form.id)
            .then(() => {
              this.$toast.success('changes  saved successfully')
              setTimeout(() => {
                window.location.assign('/org-info')
              }, 2000)
            })
            .catch()
      }

    },
    goBack() {
      window.location.assign('/home')
    },
  },
}
</script>

<template>
  <div>
    <div class="row">
      <div class="col-xl-8 mx-auto p-4">
        <div class="text-center mb-4">
          <h1 class="fs-1">
            Organization
          </h1>
          <span class="text-muted">Complete the form below to save Organization information</span>
        </div>
        <div class="card card-stacked">
          <span v-if="canadd" style="margin-left: auto" class="d-flex p-2 gap-2">
            <input v-if="canadd" type="checkbox"  v-model="editForm" class="form-check"  name="" id="edit">
               <label for="edit" class="form-label">Edit</label>

          </span>
          <span v-if="canadd" style="margin-left: auto" class="d-flex p-2 gap-2">
            <input v-if="canadd" type="checkbox"  v-model="uploadChart" class="form-check"  name="" id="chart">
               <label for="chart" class="form-label">Upload chart</label>
          </span>
          <form class="card-body py-4" @submit.prevent="saveOrganizationInfo">
            <div class="row">
              <div class="col-lg-6 form-group mb-4">
                <label for="" class="form-label">Organization Name</label>
                <input required id="name" :disabled="!this.editForm" v-model="form.name" type="text" class="form-control">
                <div v-if="form.hasError('name')" class="text-danger" v-text="form.getError('name')" />
              </div>
              <div class="col-lg-6 form-group mb-4">
                <label for="" class="form-label">KRA PIN</label>
                <input required :disabled="!this.editForm" id="name" v-model="form.kra_pin" type="text" class="form-control">
                <div v-if="form.hasError('kra_pin')" class="text-danger" v-text="form.getError('kra_pin')" />
              </div>
              <div class="col-lg-6 form-group mb-4">
                <label for="" class="form-label">Phone</label>
                <input required :disabled="!this.editForm" id="name" v-model="form.phone" type="text" class="form-control">

                <span v-if="form.hasError('phone')" class="text-danger" v-text="form.getError('phone')" />
              </div>
              <div class="col-lg-6 form-group mb-4">
                <label class="form-label" for="plan_type">Fax</label>
                <input :disabled="!this.editForm" id="name" v-model="form.fax" type="text" class="form-control">
                <span v-if="form.hasError('fax')" class="text-danger"
                      v-text="form.getError('fax')" />
              </div>

              <div class="col-lg-6 form-group mb-4">
                <label class="form-label" for="plan_type">Email address</label>
                <input required :disabled="!this.editForm" id="name" v-model="form.email" type="text" class="form-control">
                <span v-if="form.hasError('email')" class="text-danger" v-text="form.getError('email')" />
              </div>
              <div class="col-lg-6 form-group mb-4">
                <label class="form-label" for="plan_type">Address</label>
                <input required :disabled="!this.editForm" id="name" v-model="form.address" type="text" class="form-control">
                <span v-if="form.hasError('address')" class="text-danger"
                      v-text="form.getError('address')" />
              </div>
              <div class="col-lg-6 form-group mb-4">
                <label class="form-label" for="plan_type">Postal codes</label>
                <input required :disabled="!this.editForm" id="name" v-model="form.postal_code" type="text" class="form-control">
                <span v-if="form.hasError('postal_code')" class="text-danger"
                      v-text="form.getError('postal_code')" />
              </div>
              <div class="col-lg-6 form-group mb-4">
                <label class="form-label" for="plan_type">City</label>
                <input required :disabled="!this.editForm" id="name" v-model="form.city" type="text" class="form-control">
                <span v-if="form.hasError('city')" class="text-danger"
                      v-text="form.getError('city')" />
              </div>
              <div v-if="uploadChart" class="col-lg-6 form-group mb-4">
                <label class="form-label" for="plan_type">Chart</label>
                <input :disabled="!this.uploadChart" type="file"  class="form-control" @change="uploadChart" @input="chart.chart = $event.target.files[0]">
                <span v-if="form.hasError('chart')" class="text-danger"
                      v-text="form.getError('chart')" />
              </div>


              <div v-if="editForm" class="mt-4">
                <button class="btn btn-primary me-3" type="submit">
                  Submit
                </button>

                <button class="btn" :disabled="form.processing || form.successful" @click.prevent="goBack">
                  Cancel
                </button>
              </div>

            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
