<script>
import _debounce from "lodash/debounce";
import Calender from  "./Calender.vue";
import ApexCharts from 'apexcharts'

export default {
  props: {
    noofemployees: {
      type: Number,
      required: true,
      default: function () {
        return 0
      },
    },
    turnover: {
      type: Object,
      required: true,
      default: function () {
        return 0
      },
    },
    payroll: {
      type: Object,
      required: true,
      default: function () {
        return 0
      },
    },
    user:{
      type:Object,
      required: true,
      default: function (){
        return {}
      }
    },
    noofdepartments: {
      type: Number,
      required: true,
      default: function () {
        return 0
      },
    },
  },
  components: {
    Calender,
    ApexCharts
  },
  data() {
    return {

      series: [
        {
          name: "Approved Requests",
          data: [28, 29, 33, 36, 32, 32, 33,23, 23 ,23]
        },
        {
          name: "Rejected Requests",
          data: [12, 11, 14, 18, 17, 13, 13, 23, 23 ,23]
        }
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        colors: ['#77B6EA', '#545454'],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: 'Leave requests',
          align: 'left'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          },
        },
        markers: {
          size: 1
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug','Sep','Oct', 'Nov', 'Dec'],
          title: {
            text: 'Month'
          }
        },
        yaxis: {
          title: {
            text: 'No of leave requests'
          },
          min: 5,
          max: 70
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        }
      },
      scheduleFilter:{
        date: new Date().toISOString().slice(0, 10)
      },
      employees: [],
      employeeFields: [
      {
        name: 'staff_number',
        title: 'StaffNo',
        width: '15%',
      },
      {
        name: '__slot:name',
        title: 'NAME',
        titleClass: 'ps-2',
        dataClass: 'ps-2 text-truncate',
        width: '40%',
      },
      {
        name: 'department.name',
        title: 'Department',
        titleClass: 'ps-2 ',
        dataClass: 'ps-2 text-start',

      },
      {
        name: 'address',
        title: 'ADDRESS',
        titleClass: 'ps-0',
        dataClass: 'ps-0',

      },
      {
        name: 'created_at',
        title: 'REG. DATE',
        titleClass: 'ps-3',
        dataClass: 'ps-3',
        callback: this.$options.filters.date_DATE_MONTH_YEAR,
      },
      {
        name: '__slot:actions',
        title: '',
        titleClass: 'text-center',
        dataClass: 'text-center',
      },
    ],
      employeeParams: {
        filter: {
          search: '',
        },
      },
      workPerformanceFields: [
        {
          name: '__slot:employee.name',
          title: 'Performance Review',
          titleClass: 'text-start',
          dataClass: 'pe-3 text-start',

        },
      ],
      workPerformanceParams:{
        filter: {
          dtfilter: '',
          dueDataIsTom: false
        }
      },
      interviewFields :[
        {
          name: '__slot:interview_title',
          title: 'Interview',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        }
      ],
      interviewParams :{
        filter: {
          dtfilter: '',
          hasPassed: false
        }
      },
      jobApplicationFields :[
        {
          name: 'applicant.name',
          title: 'Candidate',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },
        {
          name: 'vacancy.name',
          title: 'Vacancy',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },
        {
          name: 'application_date',
          title: 'Date of Application',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },
      ],
      jobApplicationParams:{
        filter: {
          status: 'Applied',
        }
      },
      unsolvedCasesFields: [

        {
          name: '__slot:employee.name',
          title: 'Employee',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },
      ],
      unsolvedCasesParams: {
        filter: {
          search: '',
          caseresolved:false,
          dtfilter: '',

        }
      },
      onleavefield: [
        {
          name: '__slot:employee_leave_type.employee.name',
          title: 'Employee',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },
        {
          name: 'employee_leave_type.leave_type.name',
          title: 'Leave Type',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },
        {
          name: '__slot:return_date',
          title: 'Return Date',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },
      ],
      leaveFields: [
        {
          name: '__slot:employee_leave_type.employee.name',
          title: 'Employee',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },
        {
          name: '__slot:start_date',
          title: 'Leave',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3 align-items-start',
        },
      ],

      leaveParams: {
        filter: {
          search: '',
          approved: false,
          rejected: false,
        }
      },
      filterParams: {
        filter: {
          search: '',
          start_date: new Date().toISOString().slice(0, 10),
          // end_date: new Date().toISOString().slice(0, 10),
        }
      },
      calenderShow:false,
      form: new window.Form({
        id: null,
        employee_id: '',
        leave_type_id: '',
        start_date: '',
        end_date: '',

        approved: true
      }),
    }
  },
  computed: {
    vuetable () {
      return this.$refs.table2.$refs.vuetable
    },
  },
  watch: {
    'scheduleFilter.date':function(value){
      this.unsolvedCasesParams.filter.dtfilter = value
      return this.$refs.table2.$refs.vuetable.reload();
      // alert(value)
    }
  },
  methods: {
    showCalender(){
      this.calenderShow = true
      this.$bvModal.show("calender-modal");
    },
    updateGreeting() {
      let userName = this.user.name; // Change this to the desired name
      const now = new Date();
      const hours = now.getHours();
      let greeting;

      if (hours < 12) {
        greeting = "Good Morning";
      } else if (hours < 18) {
        greeting = "Good Afternoon";
      } else {
        greeting = "Good Evening";
      }

      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      const dateString = now.toLocaleDateString('en-GB', options);

      const greetingMessage = `<h2>${greeting}, ${userName}</h2><br> <div>It\'s ${dateString}</div>`;
      return greetingMessage;
    },
    backgroundImageUrl(employee) {
      if (employee.profile_image != null) {
        return `/storage/${employee.profile_image.file_path}`;
      } else {
        return '/storage/profile/default.jpg'
      }
    },
    searchEmployees: _debounce(function () {
      return this.$refs.tableEmployee.$refs.vuetable.reload()
    }, 700),
    formatDate(dateStr){

      const date = new Date(dateStr);

      const options = { day: '2-digit', month: 'short', year: 'numeric' };

      const formatter = new Intl.DateTimeFormat('en-GB', options);


      const formattedDate = formatter.format(date);

      const finalFormattedDate = formattedDate.replace(/\//g, '-');

      console.log(finalFormattedDate);
      return finalFormattedDate;

    },
    checkLeaveEnd (dateStr) {

      const leaveEndDate = new Date(dateStr);
      leaveEndDate.setHours(0)

      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      const tomorrowDate = new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate());
      if (leaveEndDate.getTime() === tomorrowDate.getTime()) {
        return true;
      } else {
        return false;
      }
    },
    hasLeaveEnd (dateStr) {
    const leaveEndDate = new Date(dateStr);
    leaveEndDate.setHours(0)
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate());
    const tomorrowDate = new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate());
    console.log(tomorrowDate)
    if (leaveEndDate.getTime() === tomorrowDate.getTime()) {
      return true;
    } else {
      return false;
    }
    },

  },
}

</script>

<template>
  <div>
    <div class="page-bdy">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-md-8">
            <div v-html="updateGreeting()" class="">
            </div>
          </div>
          <div class="col-md-4 d-flex justify-content-end align-items-end">
            <div>
              <button @click="showCalender" class="btn btn-outline-secondary rounded"><span class="me-2"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-event" viewBox="0 0 16 16">
  <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z"/>
  <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
</svg></span> Calender</button>
            </div>

          </div>
        </div>
        <div class="row row-cards mb-4">
          <!-- Total employees -->
          <div class="col-xxl-3 col-md-6">
            <div class="card info-card sales-card">
              <div class="card-body">
                <h5 class="card-title">Total Employee</h5>

                <div class="d-flex align-items-center">
                  <div  class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <svg style="size: 32px" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
                      <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
                    </svg>
                  </div>
                  <div class="ps-3">
                    <h6>{{ noofemployees }}</h6>
<!--                    <span class="text-success small pt-1 fw-bold">12%</span> <span class="text-muted small pt-2 ps-1">Than last month</span>-->

                  </div>
                </div>
              </div>

            </div>
          </div>
          <!-- End total employee -->
          <div class="col-xxl-3 col-md-6">
            <div class="card info-card sales-card">
              <div class="card-body">
                <h5 class="card-title">Turnover Rate</h5>

                <div class="d-flex align-items-center">
                  <div  class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <svg style="size: 32px" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
                      <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
                    </svg>
                  </div>
                  <div class="ps-3">
                    <h6>{{ turnover.employeeWhoLeft }}</h6>
                    <span class="text-success small pt-1 fw-bold">{{ turnover.rate }}%</span> <span class="text-muted small pt-2 ps-1">Than last year</span>

                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="col-xxl-3 col-md-6">
            <div class="card info-card sales-card">
              <div class="card-body">
                <h5 class="card-title">Payrolls</h5>

                <div class="d-flex align-items-center">
                  <div  class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <svg style="size: 32px" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
                      <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
                    </svg>
                  </div>
                  <div class="ps-3">
                    <h6>ksh {{ payroll.netDeff }}</h6>
                    <span class="text-success small pt-1 fw-bold">{{payroll.netIncPe}}%</span> <span class="text-muted small pt-2 ps-1">Than last month</span>

                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="col-xxl-3 col-md-6">
            <div class="card info-card sales-card">
              <div class="card-body">
                <h5 class="card-title">On leave</h5>

                <div class="d-flex align-items-center">
                  <div  class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <svg style="size: 32px" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
                      <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
                    </svg>
                  </div>
                  <div class="ps-3">
                    <h6>20</h6>
                    <span class="text-success small pt-1 fw-bold">12%</span> <span class="text-muted small pt-2 ps-1">Than last month</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>


        <div class="row row-deck row-cards">



          <div class="col-md-6 col-lg-4 mb-3">
            <div class="card section">
              <div class="card-header d-flex justify-content-between align-items-center">
                <div class="section-header">
                  <h4 class="card-title">Schedule</h4>
                </div>
                <span class="d-flex gap-2 align-items-center">
                  <label for="">Date: </label>
                    <date-picker name="date_of_birth" formclass="form-control bg-white" :value="scheduleFilter.date"
                                 @on-change="(dateOb, dateStr) => {
            scheduleFilter.date = dateStr
          }" />
                  </span>
              </div>
              <b-tabs active-nav-item-class="bg-white text-indigo" lazy nav-class="mb-2 mx-1">
                <b-tab title-item-class="fs-3" title-link-class="px-4 py-2">
                  <template #title>
                    <i class="uil uil-spinner-alt me-2" />Meetings
                  </template>
                  <VueTable  :per-page="5" ref="table2" api-url="/datatable/interview" :fields="interviewFields"
                            :append-params="interviewParams">
                    <template v-slot:interview_title="props">
                      <div class="d-flex py-1 align-items-center">
                        <span class="avatar me-2" :style="{ backgroundImage: `url('/storage/profile/default.jpg')`}"></span>
                        <div class="flex-fill">
                          <div class="font-weight-medium d-flex justify-content-between"><span>{{ props.rowData.application.applicant.name}}</span>  <span class="small">{{props.rowData.date_and_time}}</span>
<!--                            <small v-if="checkLeaveEnd(props.rowData.resolution_date)" class="text-warning">| Tomorrow</small>-->
                          </div>
                          <div class="text-muted small"><a :href="'mailto:'+props.rowData.application.applicant.email" class="text-muted">{{ props.rowData.application.applicant.email }}</a></div>
                                                <div class="text-secondary"><a href="#" class="text-reset">{{ props.rowData.application.vacancy.name }}</a></div>

                        </div>
                      </div>
                      <!--                  <div class="text-reset"></div>-->

                      <!--                  <small v-if="hasLeaveEnd(props.rowData.resolution_date)" class="text-warning">Case to be resolved today</small>-->
                    </template>



                  </VueTable>
                </b-tab>
                <b-tab title-item-class="fs-3" title-link-class="px-4 py-2">
                  <template #title>
                    <i class="uil uil-check-circle me-2" />Cases
                  </template>
                  <VueTable :per-page="5" ref="table2" api-url="/datatable/disciplinary-cases" :fields="unsolvedCasesFields"
                            :append-params="unsolvedCasesParams">
                    <template v-slot:employee.name="props">
                      <div class="d-flex py-1 align-items-center">
                        <span class="avatar me-2" :style="{ backgroundImage: `url(${backgroundImageUrl(props.rowData.employee)})`}"></span>
                        <div class="flex-fill">
                          <div class="font-weight-medium">{{ props.rowData.employee.name }} <small title="Case to be resolved today" v-if="hasLeaveEnd(props.rowData.resolution_date)" class="text-warning"> | Today</small>
                            <small v-if="checkLeaveEnd(props.rowData.resolution_date)" class="text-warning">| Tomorrow</small>
                          </div>
                          <div class="text-muted small"><a :href="'mailto:'+props.rowData.employee.email" class="text-muted">{{ props.rowData.employee.email }}</a></div>
                          <!--                      <div class="text-secondary"><a href="#" class="text-reset">Sevi</a></div>-->

                        </div>
                      </div>
                      <!--                  <div class="text-reset"></div>-->

                      <!--                  <small v-if="hasLeaveEnd(props.rowData.resolution_date)" class="text-warning">Case to be resolved today</small>-->
                    </template>
                    <template v-slot:resolution_date="props">
                      <div class="text-reset">{{ formatDate(props.rowData.resolution_date)  }}</div>
                    </template>


                  </VueTable>

                </b-tab>
                <b-tab title-item-class="fs-3" title-link-class="px-4 py-2">
                  <template #title>
                    <i class="uil uil-closed-circle me-2" />Reviews
                  </template>
                  <VueTable :per-page="5" ref="table2" api-url="/datatable/performance" :fields="workPerformanceFields"
                            :append-params="workPerformanceParams">
                    <template v-slot:employee.name="props">
                      <div class="d-flex py-1 align-items-center">
                        <span class="avatar me-2" :style="{ backgroundImage: `url(${backgroundImageUrl(props.rowData.employee)})`}"></span>
                        <div class="flex-fill">
                          <div class="font-weight-medium d-flex justify-content-between"><span  title="employee name and group">{{ props.rowData.employee.name }} | {{ props.rowData.job_group.name }}</span> <span title="due date" class="small">{{ formatDate(props.rowData.due_date) }}</span>
                          </div>
                          <div class="text-muted small"><a :href="'mailto:'+props.rowData.employee.email" class="text-muted">{{ props.rowData.employee.email }}</a></div>
                          <!--                      <div class="text-secondary"><a href="#" class="text-reset">Sevi</a></div>-->

                        </div>
                      </div>
                      <div v-if="props.rowData.employee.supervisor"  class="text-muted">Supervisor</div>
                      <div v-if="props.rowData.employee.supervisor" class="d-flex py-1 align-items-center">
                        <span class="avatar me-2" :style="{ backgroundImage: `url(${backgroundImageUrl(props.rowData.employee.supervisor)})`}"></span>
                        <div class="flex-fill">
                          <div class="font-weight-medium">{{ props.rowData.employee.supervisor.name }}

                          </div>

                          <div class="text-muted small"><a :href="'mailto:'+props.rowData.employee.supervisor.email" class="text-muted">{{ props.rowData.employee.supervisor.email }}</a></div>
                          <!--                      <div class="text-secondary"><a href="#" class="text-reset">Sevi</a></div>-->

                        </div>
                      </div>

                    </template>
                    <template v-slot:employee_leave_type.leave_type="props">
                      <div class="d-flex">
                        <div>sddfd</div>
                        <div>{{ props.employee_leave_type.leave_type.name }}</div>
                      </div>
<!--                      <div class="text-reset">{{ formatDate(props.rowData.resolution_date)  }}</div>-->
                    </template>
                  </VueTable>

                </b-tab>
              </b-tabs>


            </div>
          </div>
          <div class="col-md-6 col-lg-8">
            <div class="card section">
              <div class="section-header">
                <h4 class="card-title">Employee</h4>
              </div>
              <div class="pb-2">
                <div class="col-lg-4">
                  <input  v-model="employeeParams.filter.search" placeholder="Search" type="text" class="form-control" @input="searchEmployees">
                </div>

              </div>
              <VueTable :per-page="7" ref="tableEmployee" api-url="/datatable/employees" :fields="employeeFields" :append-params="employeeParams">
                <template v-slot:name="props">
                  <div class="d-flex py-1 align-items-center">
                    <span class="avatar me-2" :style="{ backgroundImage: `url(${backgroundImageUrl(props.rowData)})`}"></span>
                    <div class="flex-fill ">
                      <div class="font-weight-medium d-flex justify-content-between">
                        <span>{{ props.rowData.name }}</span>
                        <span title="Job title" class="small">{{ props.rowData.job_title }}</span>
                      </div>
                      <div class="text-muted small"><a :href="'mailto:'+props.rowData.email" class="text-muted">{{ props.rowData.email }}</a></div>
                    </div>
                  </div>

                </template>
              </VueTable>

            </div>
          </div>
          <div class="row mt-4 g-3">
            <div class="col-md-12 col-lg-8">
              <div class="card section">
                <div class="section-header">
                  <h4 class="card-title">Leave</h4>
                </div>
                <b-tabs active-nav-item-class="bg-white text-indigo" lazy nav-class="mb-2">
                  <b-tab title-item-class="fs-3" title-link-class="px-4 py-2">
                    <template #title>
                      <i class="uil uil-spinner-alt me-2" />Leave Request
                    </template>

                    <VueTable :per-page="7" ref="leaveTable" api-url="/datatable/leave-requests" :fields="leaveFields" :append-params="leaveParams">
                      <template v-slot:employee_leave_type.employee.name="props">
                        <div class="d-flex py-1 align-items-center">
                          <span class="avatar me-2" :style="{ backgroundImage: `url(${backgroundImageUrl(props.rowData.employee_leave_type.employee)})`}"></span>
                          <div class="flex-fill ">
                            <div class="font-weight-medium d-flex justify-content-between">
                              <span>{{ props.rowData.employee_leave_type.employee.name }}</span>
                              <span title="Job title" class="small">{{ props.rowData.employee_leave_type.employee.job_title }}</span>
                            </div>
                            <div class="text-muted small"><a :href="'mailto:'+props.rowData.employee_leave_type.employee.email" class="text-muted">{{ props.rowData.employee_leave_type.employee.email }}</a></div>
                          </div>
                        </div>
                        <div v-if="props.rowData.employee_leave_type.employee.supervisor"  class="text-muted">Supervisor</div>
                        <div v-if="props.rowData.employee_leave_type.employee.supervisor" class="d-flex py-1 align-items-center">
                          <span class="avatar me-2" :style="{ backgroundImage: `url(${backgroundImageUrl(props.rowData.employee_leave_type.employee.supervisor)})`}"></span>
                          <div class="flex-fill">
                            <div class="font-weight-medium">{{ props.rowData.employee_leave_type.employee.supervisor.name }}

                              <span v-if="props.rowData.supervisor_approved" class="small text-success">| Has approved</span>
                              <span v-else-if="props.rowData.supervisor_rejected" class="small text-warning">| Has rejected</span>
                              <span v-else class="small text-warning">Has not approved</span>
                            </div>
                            <div class="text-muted small"><a :href="'mailto:'+props.rowData.employee_leave_type.employee.supervisor.email" class="text-muted">{{ props.rowData.employee_leave_type.employee.supervisor.email }}</a></div>
                            <!--                      <div class="text-secondary"><a href="#" class="text-reset">Sevi</a></div>-->

                          </div>
                        </div>
                      </template>
                      <template v-slot:start_date="props">
                        <div class="d-flex justify-content-between">
                          <span title="Leave type">
                             {{props.rowData.employee_leave_type.leave_type.name}}
                          </span>
                          <span v-if="props.rowData.days > 1">
                            {{props.rowData.days}} days
                          </span>
                          <span v-else="props.rowData.days">
                            {{props.rowData.days}} day
                          </span>
                        </div>
                        <div>
                          <span class="small">Starting from {{formatDate(props.rowData.start_date)}}</span>
                          <span class="small">To {{formatDate(props.rowData.end_date)}}</span>
                        </div>
                      </template>
                    </VueTable>
                  </b-tab>
                  <b-tab title-item-class="fs-3" title-link-class="px-4 py-2">
                    <template #title>
                      <i class="uil uil-check-circle me-2" />On leave
                    </template>

                    <VueTable :per-page="7" ref="leaveTable" api-url="/datatable/employee_on_leave" :fields="leaveFields" :append-params="filterParams">
                      <template v-slot:employee_leave_type.employee.name="props">
                        <div class="d-flex py-1 align-items-center">
                          <span class="avatar me-2" :style="{ backgroundImage: `url(${backgroundImageUrl(props.rowData.employee_leave_type.employee)})`}"></span>
                          <div class="flex-fill ">
                            <div class="font-weight-medium d-flex justify-content-between">
                              <span>{{ props.rowData.employee_leave_type.employee.name }}</span>
                              <span title="Job title" class="small">{{ props.rowData.employee_leave_type.employee.job_title }}</span>
                            </div>
                            <div class="text-muted small"><a :href="'mailto:'+props.rowData.employee_leave_type.employee.email" class="text-muted">{{ props.rowData.employee_leave_type.employee.email }}</a></div>
                          </div>
                        </div>
                        <div v-if="props.rowData.employee_leave_type.employee.supervisor"  class="text-muted">Supervisor</div>
                        <div v-if="props.rowData.employee_leave_type.employee.supervisor" class="d-flex py-1 align-items-center">
                          <span class="avatar me-2" :style="{ backgroundImage: `url(${backgroundImageUrl(props.rowData.employee_leave_type.employee.supervisor)})`}"></span>
                          <div class="flex-fill">
                            <div class="font-weight-medium">{{ props.rowData.employee_leave_type.employee.supervisor.name }}

                              <span v-if="props.rowData.supervisor_approved" class="small text-success">| Has approved</span>
                              <span v-else-if="props.rowData.supervisor_rejected" class="small text-warning">| Has rejected</span>
                              <span v-else class="small text-warning">Has not approved</span>
                            </div>
                            <div class="text-muted small"><a :href="'mailto:'+props.rowData.employee_leave_type.employee.supervisor.email" class="text-muted">{{ props.rowData.employee_leave_type.employee.supervisor.email }}</a></div>
                            <!--                      <div class="text-secondary"><a href="#" class="text-reset">Sevi</a></div>-->

                          </div>
                        </div>
                      </template>
                      <template v-slot:start_date="props">
                        <div class="d-flex justify-content-between">
                          <span title="Leave type">
                             {{props.rowData.employee_leave_type.leave_type.name}}
                          </span>
                          <span v-if="props.rowData.days > 1">
                            {{props.rowData.days}} days
                          </span>
                          <span v-else="props.rowData.days">
                            {{props.rowData.days}} day
                          </span>
                        </div>
                        <div>
                          <span class="small">Starting from {{formatDate(props.rowData.start_date)}}</span>
                          <span class="small">To {{formatDate(props.rowData.end_date)}}</span>
                        </div>
                      </template>
                    </VueTable>
                  </b-tab>

                </b-tabs>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card section">
                <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <b-modal size="xl" id="calender-modal" title="Calender" hide-header-close footer-class="d-flex justify-content-start"
             modal-class="modal-blur" no-close-on-esc>
        <Calender :isCalendarInitialized="calenderShow"/>
    </b-modal>
  </div>

</template>
<style scoped>
.section {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  flex: 1;
  min-width: 45%;
}
.card-icon{
  font-size: 32px;
  line-height: 0;
  width: 64px;
  height: 64px;
  background: #f6f6fe;
  flex-shrink: 0;
  flex-grow: 0;
}
.info-card h6 {
  font-size: 28px;
  color: #012970;
  font-weight: 700;
  margin: 0;
  padding: 0;
}
.section-header {
  margin-bottom: 15px;
  border-bottom: 2px solid #eee;
  padding-bottom: 10px;
}

.section-header h3 {
  margin: 0;
  font-size: 1.4em;
  color: #333;
}
element {
}
.card-header:first-child {
  border-radius: var(--tblr-card-border-radius) var(--tblr-card-border-radius) 0 0;

}
.card-header:first-child {
  border-radius: 3px 3px 0 0;

}
.card-header {
  color: inherit;

  display: flex;

  align-items: center;

  background: transparent;

}
.justify-content-between {
  justify-content: space-between !important;

}
.d-flex {
  display: flex !important;

}
.card-header{
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.section-header[data-v-9ff9941e] {
  margin-bottom: 15px;
  border-bottom: none;
  padding-bottom: 0px;
}
</style>
