import httpClient from "@plugins/axios";
import {getSavedState, saveState} from "@state/helpers";

export const state = {
  accounts: getSavedState('accounts.CHARTS_OF_ACCOUNT'),
}

export const getters = {
  accounts: (state) => state.accounts
}

export const mutations = {
  SAVE_ACCOUNTS(state, accounts) {
    saveState('accounts.CHARTS_OF_ACCOUNT', accounts)

    state.accounts = getSavedState('accounts.CHARTS_OF_ACCOUNT')
  },

  UPDATE_ACCOUNT(state, data) {
    let pos = state.accounts.findIndex((account) => account.id === data.account.id)

    state.accounts[pos] = data.account

  },
}

export const actions = {
  fetchAccounts({commit, dispatch, state}, params = {page: {size: 10000}}) {
    return new Promise((resolve, reject) => {
      httpClient.get('account', params)
        .then(({data}) => {
          let accountsData = data.map((account) => {
            return {
              id: account.id,
              name: account.name,
              description: account.description,
              account_category_id: account.account_category_id
            }
          })
          commit('SAVE_ACCOUNTS', accountsData)
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

