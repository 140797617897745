var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.enableForm
    ? _c("div", [
        _c("div", { staticClass: "card rounded-3" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "card-body px-4" }, [
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.uploadDocument.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "form-group mb-4" },
                  [
                    _c("media-library-attachment", {
                      ref: "mediaComponent",
                      attrs: {
                        name: "media",
                        "validation-rules": {
                          accept: ["image/jpeg", "image/gif"],
                          maxSizeInKB: 10240,
                        },
                        "validation-errors": _vm.validationErrors,
                        "initial-value": _vm.value.media,
                      },
                      on: {
                        "is-ready-to-submit-change": function ($event) {
                          _vm.isReadyToSubmit = $event
                        },
                        change: _vm.onChange,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { disabled: _vm.form.processing, type: "submit" },
                  },
                  [_vm._v("\n          Upload Document\n        ")]
                ),
              ]
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "card-header" }, [
      _c("div", [
        _c("h3", { staticClass: "card-title" }, [
          _vm._v("\n          Photo\n        "),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "card-subtitle" }, [
          _vm._v("\n          Upload photo\n        "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }