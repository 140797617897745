<script>
import _filter from 'lodash/filter'
import VJstree from 'vue-jstree'
import Role from './Role.vue'

export default {
  components: {
    VJstree,
    Role,
  },
  props: {
    departments: {
      type: Array,
      required: true,
    },
    roles: {
      type: Array,
      default: () => [],
    },
    employees: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      fields: [
        {
          name: '__sequence',
          title: '#',
          titleClass: 'ps-3',
          dataClass: 'ps-3',
          width: '3%',
        },
        {
          name: 'name',
          title: 'Full Name',
          titleClass: 'ps-3',
          dataClass: 'ps-3',
          width: '30%',
        },
        {
          name: 'username',
          title: 'Username',
          titleClass: 'ps-3',
          dataClass: 'ps-3',
          width: '15%',
        },
        {
          name: 'employee.department.name',
          title: 'Department',
          titleClass: 'ps-3',
          dataClass: 'ps-3 text-truncate',
          width: '20%',
        },
        {
          name: 'roles',
          title: 'Group',
          titleClass: 'ps-3',
          dataClass: 'ps-3 text-truncate',
          width: '20%',
          callback: function (roles) {
            if (roles.length) {
              return roles[0].name
            }
          },
        },
        {
          name: 'activated',
          title: 'Status',
          titleClass: 'ps-3',
          dataClass: 'ps-3',
          width: '5%',
          callback: function (value) {
            if (value) {
              return '<span class="badge bg-teal badge-pill">Active</span>'
            }

            return '<span class="badge bg-danger badge-pill">Deactivated</span>'
          },
        },
        {
          name: '__slot:actions',
          title: '',
          width: '5%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
      ],

      moreParams: {
        filter: {
          name: '',
        },
      },

      viewAccessControlManagement: false,

      form: new window.Form({
        id: null,
        username: '',
        name: '',
        email: '',
        password: '',
        department_id: '',
        role_id: '',
        employee_id: '',
        activated: true,
      }),

      permissionForm: new window.Form({
        id: '',
        selectedPermission: [],
      }),

      user: '',

      showPermissionForm: false,

      permissions: [],
      userPermissions: [],
    }
  },

  computed: {
    vuetable () {
      return this.$refs.table.$refs.vuetable
    },
  },
  watch: {
    'form.employee_id': function (id) {
      const selectEmployee = this.employees.find( employee => employee.id === id );

      this.form.email = selectEmployee.email
      const fullname = selectEmployee.name
      this.form.name  = fullname

      const [firstName, secondName] = [...fullname.split(' ')];
      this.form.username = (`${firstName.substr(0,1)}.${secondName ?? ''}`).toLocaleLowerCase();
     
    }
  },
  methods: {
    showCreateForm () {
      this.$bvModal.show('createModal')
    },

    showEditForm (rowData) {
      this.user = rowData

      this.form.populate(rowData)

      this.form.id = rowData.id

      if (rowData.roles.length) {
        this.form.role_id = rowData.roles[0].id
      }

      this.$bvModal.show('updateModal')
    },

    createUser () {
      this.form.post('/users')
        .then(() => {
          this.$toast.success('The user has been created')

          this.$bvModal.hide('createModal')

          this.vuetable.refresh()
        })
        .catch()
    },

    updateUserAccount () {
      this.form.patch(`/users/${this.form.id}`)
        .then(() => {
          this.$toast.success('The user account has been updated')

          this.$bvModal.hide('updateModal')

          this.vuetable.refresh()
        }).catch()
    },

    accessControlManagement (user) {
      this.user = user
      this.permissionForm.id = user.id

      this.userPermissions = user.permissions

      this.permissionForm.selectedPermission = this.userPermissions.map(permission => permission.id)

      this.showPermissionForm = true

      this.retrieveSystemPermissions()
      // window.location.assign(`/users/${rowData.id}/permissions`)
    },

    applyFilter () {
      this.vuetable.reload()
    },

    retrieveSystemPermissions () {
      const vm = this

      this.$httpClient.get('/permissions', {
        params: {
          page: {
            size: 1000,
          },
        },
      })
        .then(({ data }) => {
          const availablePermissions = data.data

          const permissionGroups = new Set()

          availablePermissions.forEach(({ code }) => {
            permissionGroups.add(code)
          })

          const parent = [...permissionGroups].map(function (code, index) {
            return {
              id: 'muhi',
              text: code,
              children: [],
              opened: false,
              selected: false,
              isLeaf: true,
            }
          })

          parent.forEach(function (parent) {
            _filter(availablePermissions, { code: parent.text }).forEach(function (permission) {
              const child = {
                id: permission.id,
                text: permission.display_name,
                selected: false,
              }

              if (vm.userPermissions.length > 0) {
                const permissionToSelect = vm.userPermissions.find(({ id }) => id === permission.id)

                child.selected = !!permissionToSelect
                parent.opened = !!permissionToSelect
              }

              parent.children.push(child)
            })
          })

          this.permissions = parent
        }).catch()
    },

    onPermissionSelected (node, item, event) {
      this.permissionForm.selectedPermission = []
      this.$refs.tree.handleRecursionNodeChilds(this.$refs.tree, node => {
        if (node.model && node.model.selected && node.model.id && node.model.id !== 'muhi') {
          // this.form.selectedPermission.push({id: node.model.id})
          this.permissionForm.selectedPermission.push(node.model.id)
        }
      })
    },

    updateUserPermission () {
      this.permissionForm.post(`/users/${this.user.id}/permissions`)
        .then(() => {
          this.$toast.success('The change has been saved')

          this.userPermissions = []
          this.permissionForm.reset()

          this.showPermissionForm = false
        }).catch()
    },

    closePermissionForm () {
      this.showPermissionForm = false

      this.userPermissions = []
      this.permissionForm.reset()
    },
  },
}
</script>

<template>
  <div>
    <b-tabs nav-class="mb-2" active-nav-item-class="bg-white text-orange" lazy>
      <b-tab title="Users" title-link-class="px-3 py-2" title-item-class="fs-3">
        <template #title>
          <i class="uil uil-users-alt me-1" /> Users
        </template>

        <div v-if="showPermissionForm" class="row">
          <div class="col-xl-6">
            <div class="card shadow-sm">
              <div class="card-header">
                  {{  user.name }} Permission Management
              </div>

              <div class="card-body">
                <v-jstree
                  ref="tree"
                  :data="permissions"
                  show-checkbox
                  multiple
                  allow-batch
                  whole-row
                  @item-click="onPermissionSelected"
                />
              </div>

              <div class="card-footer">
                <button class="btn btn-success" :disabled="permissionForm.processing" @click="updateUserPermission">
                  Submit
                </button>
                <button class="btn btn-light" :disabled="permissionForm.processing" @click="closePermissionForm">
                  Close
                </button>
              </div>
              <div v-if="permissionForm.processing" class="progress card-progress">
                <div class="progress-bar progress-bar-indeterminate bg-green" />
              </div>
            </div>
          </div>
        </div>

        <div v-else class="card rounded-3 mt-3">
          <div class="card-header">
            <div class="input-icon me-2">
              <span class="input-icon-addon">
                <i class="uil uil-search" />
              </span>
              <input
                v-model="moreParams.filter.name" type="text"
                class="form-control bg-muted-lt rounded-2"
                placeholder="Search Users"
                @input="applyFilter"
              >
            </div>

            <div class="card-actions">
              <button class="btn btn-primary" @click="showCreateForm">
                <i class="uil uil-plus me-1" />Add User
              </button>
            </div>
          </div>

          <VueTable
            ref="table"
            api-url="/datatable/users"
            :fields="fields"
            :append-params="moreParams"
          >
            <template #actions="props">
              <div class="dropdown">
                <button class="btn align-text-top py-1" data-bs-toggle="dropdown">
                  <i class="uil uil-ellipsis-h" />
                </button>
                <div class="dropdown-menu dropdown-menu-end">
                  <a class="dropdown-item" href="#" @click="showEditForm(props.rowData)">
                    <i class="uil uil-pen fs-3 me-2" /> Edit
                  </a>

                  <div class="dropdown-divider" />

                  <a class="dropdown-item" href="#" @click="accessControlManagement(props.rowData)">
                    <i class="uil uil-shield fs-3 me-2" /> Permission
                  </a>
                </div>
              </div>
            </template>
          </VueTable>
        </div>

        <b-modal
          id="createModal"
          no-close-on-backdrop
          title="Create Account"
          hide-header-close
          footer-class="d-flex justify-content-start"
          modal-class="modal-blur"
          body-class="px-4"
          no-close-on-esc
          @hidden="form.reset()"
        >
          <form id="createForm" @submit.prevent="createUser">
            <div class="mb-4">
              <label for="department_id" class="form-label">Employee Name</label>
              <v-select
                v-model="form.employee_id"
                :options="employees"
                label="name"
                :reduce="option => option.id"
              />

              <span
                v-if="form.hasError('employee_id')"
                class="text-danger"
                v-text="form.getError('employee_id')"
              />
              <small class="form-hint">Employees without user login accounts</small>
            </div>
            <div class="mb-3">
              <label for="name" class="form-label">Full Name</label>
              <input id="name" v-model="form.name" type="text" class="form-control">

              <span
                v-if="form.hasError('name')"
                class="text-danger"
                v-text="form.getError('name')"
              />
            </div>

            <div class="mb-3">
              <label for="username" class="form-label">Username
              </label>
              <input id="username" v-model="form.username" type="text" class="form-control">

              <small class="form-hint">Every user should have a unique username</small>
              <span
                v-if="form.hasError('username')"
                class="text-danger"
                v-text="form.getError('username')"
              />
            </div>
           
            <div class="mb-3">
              <label for="password" class="form-label">Password</label>
              <input id="password" v-model="form.password" type="password" class="form-control">

              <span
                v-if="form.hasError('password')"
                class="text-danger"
                v-text="form.getError('password')"
              />
            </div>

            <div class="mb-4">
              <label for="role_id" class="form-label">Group</label>
              <v-select
                id="role_id"
                v-model="form.role_id"
                :options="roles"
                label="name"
                :reduce="option => option.id"
              />

                <span class="text-muted small">When selected, the user will inherit the group's permissions</span>

              <span
                v-if="form.hasError('role_id')"
                class="text-danger"
                v-text="form.getError('role_id')"
              />
            </div>

            <div v-if="form.processing" class="progress mb-2">
              <div class="progress">
                <div class="progress-bar progress-bar-indeterminate bg-green" />
              </div>
            </div>
          </form>

          <template #modal-footer="{cancel}">
            <button type="button" class="btn me-auto" :disabled="form.processing" @click.prevent="cancel">
              Close
            </button>
            <button type="button" class="btn btn-primary" :disabled="form.processing" @click.prevent="createUser">
              Submit
            </button>
          </template>
        </b-modal>

        <b-modal
          id="updateModal"
          title="Edit Account"
          footer-class="d-flex justify-content-start"
          modal-class="modal-blur"
          body-class="px-4"
          no-close-on-backdrop
          hide-header-close
          no-close-on-esc
          @hidden="form.reset()"
        >
          <form id="EditForm" @submit.prevent="updateUserAccount">
            <div class="mb-3">
              <label for="name" class="form-label">Name</label>
              <input id="name" v-model="form.name" type="text" class="form-control">

              <span
                v-if="form.hasError('name')"
                class="text-danger"
                v-text="form.getError('name')"
              />
            </div>

            <div class="mb-3">
              <label for="username" class="form-label">Username</label>
              <input id="username" v-model="form.username" type="text" class="form-control">

              <span
                v-if="form.hasError('username')"
                class="text-danger"
                v-text="form.getError('username')"
              />
            </div>

            <div class="mb-3">
              <label for="password" class="form-label">Password</label>
              <input id="password" v-model="form.password" type="text" class="form-control">

              <span class="text-muted small">Only fill when you want to change it, otherwise leave it blank</span>
              <span
                v-if="form.hasError('password')"
                class="text-danger"
                v-text="form.getError('password')"
              />
            </div>
            <div class="mb-3">
              <label for="role_id" class="form-label">Group</label>
              <v-select
                id="role_id"
                v-model="form.role_id"
                :options="roles"
                label="name"
                :reduce="option => option.id"
              />

                <span class="text-muted small">When selected, the user will inherit the group's permissions</span>


              <span
                v-if="form.hasError('role_id')"
                class="text-danger"
                v-text="form.getError('role_id')"
              />
            </div>
            <div class="mb-3">
              <label class="row">
                <span class="col">Activated</span>
                <span class="col-auto">
                  <label class="form-check form-check-single form-switch">
                    <input v-model="form.activated" class="form-check-input" type="checkbox">
                  </label>
                </span>
              </label>
            </div>

            <div v-if="form.processing" class="progress mb-2">
              <div class="progress">
                <div class="progress-bar progress-bar-indeterminate bg-green" />
              </div>
            </div>
          </form>

          <template #modal-footer="{cancel}">
            <button type="button" class="btn me-auto" :disabled="form.processing" @click.prevent="cancel">
              Close
            </button>
            <button
              type="button" class="btn btn-primary" :disabled="form.processing"
              @click.prevent="updateUserAccount"
            >
              Submit
            </button>
          </template>
        </b-modal>
      </b-tab>
      <b-tab title="Users" title-link-class="px-3 py-2" title-item-class="fs-3">
        <template #title>
          <i class="uil uil-shield me-1" /> Role
        </template>

        <Role />
      </b-tab>
    </b-tabs>
  </div>
</template>
