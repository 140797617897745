<script>
import AllocatedLeaves from "./AllocatedLeaves.vue";
import LeaveRequest from "./LeaveRequest.vue";
import PreviousLeaves from "./PreviousLeaves.vue";

export default {
  components: {
    PreviousLeaves,
    LeaveRequest,
    AllocatedLeaves,
  },
  props: {
    leave_types: {
      type: Array,
      required: true,
      default: function () {
        return []
      }
    },
  },

  data () {
    return {}
  },

}
</script>

<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <b-tabs active-nav-item-class="bg-white text-indigo" lazy nav-class="mb-2">
          <b-tab title-item-class="fs-3" title-link-class="px-4 py-2">
            <template #title>
              <i class="uil uil-spinner-alt me-2" />Leave Request
            </template>

            <leave-request :leave_types="leave_types" />

          </b-tab>
          <b-tab title-item-class="fs-3" title-link-class="px-4 py-2">
            <template #title>
              <i class="uil uil-check-circle me-2" />Allocated Leaves
            </template>
            <allocated-leaves :leave_types="leave_types" />

          </b-tab>
          <b-tab title-item-class="fs-3" title-link-class="px-4 py-2">
            <template #title>
              <i class="uil uil-closed-circle me-2" />Previous Leaves
            </template>

            <previous-leaves />

          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>
