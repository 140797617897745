var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "card card-stacked" },
      [
        _c("div", { staticClass: "card-header" }, [
          _c("div", { staticClass: "input-icon me-2" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.moreParams.filter.search,
                  expression: "moreParams.filter.search",
                },
              ],
              staticClass: "form-control bg-muted-lt rounded-2",
              attrs: { type: "text", placeholder: "Search" },
              domProps: { value: _vm.moreParams.filter.search },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.moreParams.filter,
                      "search",
                      $event.target.value
                    )
                  },
                  _vm.applyFilter,
                ],
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("VueTable", {
          ref: "table",
          attrs: {
            "api-url": "/datatable/promotions",
            fields: _vm.fields,
            "append-params": _vm.moreParams,
          },
          scopedSlots: _vm._u([
            {
              key: "name",
              fn: function (props) {
                return [
                  _c("div", { staticClass: "media" }, [
                    _c("div", { staticClass: "media-body align-self-center" }, [
                      _c("span", { staticClass: "text-sm" }, [
                        _vm._v(_vm._s(props.rowData.name) + " "),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "font-size-12 text-purple-300" },
                        [_vm._v("\n\n\n              >\n            ")]
                      ),
                    ]),
                  ]),
                ]
              },
            },
            {
              key: "job_title",
              fn: function (props) {
                return [
                  _c("div", { staticClass: "media" }, [
                    _c("div", { staticClass: "media-body align-self-center" }, [
                      _c("span", { staticClass: "text-sm" }, [
                        _vm._v(
                          _vm._s(props.rowData.previous_job_title) +
                            " ->  " +
                            _vm._s(props.rowData.new_job_title) +
                            " "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "font-size-12 text-purple-300",
                      }),
                    ]),
                  ]),
                ]
              },
            },
            {
              key: "job_group",
              fn: function (props) {
                return [
                  _c("div", { staticClass: "media" }, [
                    _c("div", { staticClass: "media-body align-self-center" }, [
                      _c("span", { staticClass: "text-sm" }, [
                        _vm._v(
                          _vm._s(props.rowData.previous_job_group.name) +
                            " ->  " +
                            _vm._s(props.rowData.new_job_group.name) +
                            " "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "font-size-12 text-purple-300",
                      }),
                    ]),
                  ]),
                ]
              },
            },
            {
              key: "actions",
              fn: function (props) {
                return [
                  _c("div", { staticClass: "dropdown" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn align-text-top py-1",
                        attrs: { "data-bs-toggle": "dropdown" },
                      },
                      [_c("i", { staticClass: "uil uil-ellipsis-h" })]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "dropdown-menu dropdown-menu-end" },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "dropdown-item text-danger",
                            attrs: { disabled: "true", href: "#" },
                            on: {
                              click: function ($event) {
                                return _vm.showEditForm(props.rowData)
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "uil uil-pen fs-3 me-2" }),
                            _vm._v(" Delete\n            "),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "input-icon-addon" }, [
      _c("i", { staticClass: "uil uil-search" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }