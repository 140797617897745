<script>

export default {
  props: {
    managers: {
      type: Array,
      required: true,
      default: function () {
        return []
      },
    },
    jobgroups: {
      type: Array,
      required: true,
      default: function () {
        return []
      },
    },
    departments: {
      type: Array,
      required: true,
      default: function () {
        return []
      },
    },
  },
  data() {
    return {
      employees: [],
      fields: [
        {
          name: 'name',
          title: 'Vancancy',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },
        {
          name: 'job_title',
          title: 'Job Title',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },
        {
          name: 'department.name',
          title: 'Depertment',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },
        {
          name: 'no_of_positions',
          title: 'No of Positions',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },
        {
          name: 'activated',
          title: 'Status',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
          callback: function (value) {
            if (value) {
              return 'Opened'
            }

            return 'Closed'
          }
        },
        {
          name: '__slot:actions',
          width: '15%',
          titleClass: 'text-center',
          dataClass: 'text-right',
        },
      ],

      moreParams: {
        filter: {
          name: '',
         
        }
      },

      form: new window.Form({
        id: null,
        name: '',
        job_title: '',
        job_group_id: '',
        department_id: '',
        hiring_manager: '',
        panelists: [],
        no_of_positions: '',
        activated: true,
        description: '',
        panelist:''
      }),
    }
  },

  computed: {
    vuetable() {
      return this.$refs.table.$refs.vuetable
    },
  },
  watch: {
    
  },
  methods: {
   
    showCreateForm() {
      this.$bvModal.show('create-modal')
    },
 
    showUpdateForm(rowData) {
      this.selectedDepartment = rowData
      this.viewEditForm = true
    },

    createVacancy() {
      this.convertPanelist()
      this.form.post('/vacancy')
        .then(() => {
          this.$bvModal.hide('create-modal');
          this.$toast.success('Vacancy Created successfully.');
          this.vuetable.reload();
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.errors) {
            this.form.errors.record(error.response.data.errors);
          } else {
            this.$toast.error(error.response.data)
          }
        }

        )

    },
    convertPanelist() {
      this.form.panelist = this.form.panelists.join(',');
    },
    showEditForm(rowData) {
      this.form.populate(rowData)
      this.form.id = rowData.id
      this.form.leave_type_id = rowData.employee_leave_type_id

      this.$bvModal.show('update-modal')
    },
    delVacancy(rowData) {
      this.$httpClient.delete(`/vacancy/${rowData.id}/delete`)

        .then((error) => {
          this.$toast.success('deleted')
          this.vuetable.reload()
        }).catch((error) => {
          this.$toast.error(error.response.data)
        })
    },
    editVacancy() {
      this.form.patch('/vacancy/' + this.form.id)
        .then(() => {
          this.vuetable.reload()
          this.$bvModal.hide('update-modal')
          this.$toast.success('The changes have been saved')
        })
        .catch()
    },

    applyFilter() {
      this.vuetable.reload()
    },

    cleanUp() {
      this.form.reset()
    }
  },
}
</script>

<template>
  <div>
    <div class="card card-stacked">
      <div class="card-header">
        <div class="input-icon me-2">
          <span class="input-icon-addon">
            <i class="uil uil-search"></i>
          </span>
          <input type="text" class="form-control bg-muted-lt rounded-2" placeholder="Search leave" @input="applyFilter"
            v-model="moreParams.filter.name">
        </div>

        <div class="card-actions">
          <!--          {{leave_types}}-->
          <button class="btn btn-primary" @click="showCreateForm">
            <i class="uil uil-plus me-1"></i>Add
          </button>
        </div>
      </div>
      <VueTable ref="table" api-url="/datatable/vacancies" :fields="fields" :append-params="moreParams">
        <template v-slot:actions="props">
          <div class="dropdown">
            <button class="btn align-text-top py-1" data-bs-toggle="dropdown">
              <i class="uil uil-ellipsis-h"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <a v-if="!props.rowData.approved" class="dropdown-item" href="#" @click="showEditForm(props.rowData)">
                <i class="uil uil-pen fs-3 me-2"></i> Edit
              </a>
              <a v-if="!props.rowData.approved" class="dropdown-item" href="#" @click="delVacancy(props.rowData)">
                <i class="uil uil-trush fs-3 me-2"></i> Del
              </a>
            </div>
          </div>
        </template>
      </VueTable>
    </div>

    <b-modal id="create-modal" title="Vacancy" hide-header-close footer-class="d-flex justify-content-start"
      modal-class="modal-blur" size="lg">
      <form @submit.prevent="createVacancy" id="createForm">
        <div class="row">
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Vancancy name</label>
              <input type="text" class="form-control" id="name" v-model="form.name">

              <span v-if="form.hasError('name')" class="text-danger" v-text="form.getError('name')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Job Title</label>
              <input type="text" class="form-control" id="name" v-model="form.job_title">


              <span v-if="form.hasError('job_title')" class="text-danger" v-text="form.getError('job_title')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Department</label>
              <v-select id="gender_id" v-model="form.department_id" name="department_id" :options="departments"
                label="name" :reduce="option => option.id" :clearable="false">

              </v-select>

              <span v-if="form.hasError('department_id')" class="text-danger" v-text="form.getError('department_id')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Job Group</label>
              <v-select id="gender_id" v-model="form.job_group_id" name="department_id" :options="jobgroups"
                label="name" :reduce="option => option.id" :clearable="false">

              </v-select>

              <span v-if="form.hasError('job_group_id')" class="text-danger" v-text="form.getError('job_group_id')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Hiring Manager</label>
              <v-select id="gender_id" v-model="form.hiring_manager" name="hiring_manager" :options="managers"
                label="name" :reduce="option => option.id" :clearable="false">
              </v-select>

              <span v-if="form.hasError('hiring_manager')" class="text-danger" v-text="form.getError('hiring_manager')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">No of Positions</label>
              <input type="text" class="form-control" id="name" v-model="form.no_of_positions">

              <span v-if="form.hasError('no_of_positions')" class="text-danger"
                    v-text="form.getError('no_of_positions')">
              </span>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="mb-3">
              <label for="name" class="form-label">Panelists</label>
              <v-select
                  id="gender_id"
                  v-model="form.panelists"
                  name="department_ids"
                  :options="managers"
                  label="name"
                  :reduce="option => option.id"
                  :clearable="false"
                  multiple
              ></v-select>

              <span v-if="form.hasError('panelists')" class="text-danger" v-text="form.getError('panelists')">
              </span>
            </div>
          </div>
          <div class="d-flex mb-3 gap-2">
            <input  v-model="form.activated" class="form-check-input" type="checkbox">
            <label class="form-label" for="free_bed">Opened</label>
          </div>

          <div class="col-lg-12">
            <div class="mb-3">
              <label for="name" class="form-label">Description</label>
              <textarea v-model="form.description" class="form-control"></textarea>
              <span v-if="form.hasError('description')" class="text-danger" v-text="form.getError('description')">
              </span>
            </div>
          </div>
        </div>


        <div class="progress mb-2" v-if="form.processing">
          <div class="progress">
            <div class="progress-bar progress-bar-indeterminate bg-green"></div>
          </div>
        </div>

      </form>

      <template #modal-footer="{ cancel }">
        <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">Close
        </button>
        <button type="button" class="btn btn-primary" :disabled="form.processing" @click.prevent="createVacancy">
          Submit
        </button>
      </template>

    </b-modal>

    <b-modal id="update-modal" title="Edit Vacancy" hide-header-close size="lg"
      footer-class="d-flex justify-content-start" modal-class="modal-blur">
      <form @submit.prevent="editVacancy" id="createForm">
        <div class="row">
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Vancancy name</label>
              <input type="text" class="form-control" id="name" v-model="form.name">

              <span v-if="form.hasError('name')" class="text-danger" v-text="form.getError('name')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Job Title</label>
              <input type="text" class="form-control" id="name" v-model="form.job_title">


              <span v-if="form.hasError('job_title')" class="text-danger" v-text="form.getError('job_title')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Department</label>
              <v-select id="gender_id" v-model="form.department_id" name="department_id" :options="departments"
                label="name" :reduce="option => option.id" :clearable="false">

              </v-select>

              <span v-if="form.hasError('department_id')" class="text-danger" v-text="form.getError('department_id')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Hiring Manager</label>
              <v-select id="gender_id" v-model="form.hiring_manager" name="hiring_manager" :options="managers"
                label="name" :reduce="option => option.id" :clearable="false">
              </v-select>

              <span v-if="form.hasError('hiring_manager')" class="text-danger" v-text="form.getError('hiring_manager')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">No of Positions</label>
              <input type="text" class="form-control" id="name" v-model="form.no_of_positions">

              <span v-if="form.hasError('no_of_positions')" class="text-danger"
                v-text="form.getError('no_of_positions')">
              </span>
            </div>
          </div>
          <div class="d-flex gap-2">
            <input  v-model="form.activated" class="form-check-input" type="checkbox">
            <label class="form-label" for="free_bed">Opened</label>
          </div>

          <div class="col-lg-12">
            <div class="mb-3">
              <label for="name" class="form-label">Description</label>
              <textarea v-model="form.description" class="form-control"></textarea>
              <span v-if="form.hasError('description')" class="text-danger" v-text="form.getError('description')">
              </span>
            </div>
          </div>
        </div>

        <div class="progress mb-2" v-if="form.processing">
          <div class="progress">
            <div class="progress-bar progress-bar-indeterminate bg-green"></div>
          </div>
        </div>

      </form>
      <template #modal-footer="{ cancel }">
        <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">Close
        </button>
        <button type="button" class="btn btn-primary" :disabled="form.processing" @click.prevent="editVacancy">
          Submit
        </button>

      </template>
    </b-modal>

  </div>
</template>
