var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-tabs",
        {
          attrs: {
            "active-nav-item-class": "bg-white text-indigo",
            lazy: "",
            "nav-class": "mb-2",
          },
        },
        [
          _c(
            "b-tab",
            {
              attrs: {
                "title-item-class": "fs-3",
                "title-link-class": "px-4 py-2",
              },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("i", { staticClass: "uil uil-spinner-alt me-2" }),
                      _vm._v("Unresolved Cases\n          "),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm._v(" "),
              _c("unresolved-cases", {
                attrs: { actionstypes: _vm.actionstypes },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-tab",
            {
              attrs: {
                "title-item-class": "fs-3",
                "title-link-class": "px-4 py-2",
              },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("i", { staticClass: "uil uil-check-circle me-2" }),
                      _vm._v("Resolved\n          "),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [_vm._v(" "), _c("resolved-cases")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }