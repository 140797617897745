<script>
export default {
  props: {
    leave_types: {
      type: Array,
      required: true,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      employees: [],
      assignedLeaves:[],
      fields: [
        {
          name: "employee.name",
          title: "Employee",
          titleClass: "font-weight-bold",
          dataClass: "ps-3",
        },
        {
          name: "resignation_date",
          title: "Date",
          titleClass: "font-weight-bold",
          dataClass: "ps-3",
        },
        {
          name: "status",
          title: "STATUS",
          titleClass: "font-weight-bold",
          dataClass: "ps-3",
        },
        {
          name: "__slot:actions",
          width: "15%",
          titleClass: "text-center",
          dataClass: "text-right",
        },
      ],

      moreParams: {
        filter: {
          search: "",
          status: 'pending',
          // rejected: false,
        },
      },

      reason: null,
      form: new window.Form({
        id: null,
        employee_id: "",
        reason: '',
        responded_feedback: '',
        reject: false,
      }),
    };
  },

  computed: {
    vuetable() {
      return this.$refs.table.$refs.vuetable;
    },
  },
  watch:{

  },
  mounted() {
    this.retrieveEmployees();
  },
  methods: {
    retrieveEmployees() {
      this.$httpClient
        .get("/datatable/employees/",{
          params:{
            filter: {
              activeEmployee:true,
            }
          }
        })
        .then(({ data }) => {
          this.employees = data.data;
        })
        .catch(() => {
          this.$toast.error(
            "There was a problem retrieving the Employees"
          );
        });
    },
    showResignationReason(rowData) {
      this.reason = rowData.reason;
      this.$bvModal.show("resignation-modal");
    },
    showRespond(rowData) {
      this.form.id = rowData.id
      this.form.employee_id = rowData.employee_id;
      this.$bvModal.show("respond-to-resignation");
    },
    approveResignation() {
      this.form
        .patch("/employee/resignation-respond/" + this.form.id)
        .then(() => {
          this.vuetable.reload();
          this.$toast.success("The response has been submitted");
        })
        .catch((error) => {
          this.$toast.error(error.response.data);
        });
    },

    applyFilter() {
      this.vuetable.reload();
    },
    cleanUp() {
      this.form.reset();
    },
  },
};
</script>

<template>
  <div>
    <div class="card card-stacked">
      <div class="card-header">
        <div class="input-icon me-2">
          <span class="input-icon-addon">
            <i class="uil uil-search"></i>
          </span>
          <input type="text" class="form-control bg-muted-lt rounded-2" placeholder="Search employee"
            @input="applyFilter" v-model="moreParams.filter.search" />
        </div>

      </div>
      <VueTable ref="table" api-url="/datatable/resignation-requests" :fields="fields" :append-params="moreParams">
        <template v-slot:approved="props">
          <div class="media">
            <div class="media-body align-self-center">
              <div class="font-size-12 text-purple-300">



              </div>
            </div>
          </div>
        </template>

        <template v-slot:actions="props">
          <div class="dropdown">
            <button class="btn align-text-top py-1" data-bs-toggle="dropdown">
              <i class="uil uil-ellipsis-h"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <a class="dropdown-item" href="#" @click="showResignationReason(props.rowData)">
                View Reason
              </a>
              <a class="dropdown-item" href="#" @click="showRespond(props.rowData)">
               respond
              </a>
            </div>
          </div>
        </template>
      </VueTable>
    </div>

    <b-modal id="resignation-modal" title="Resignation Reason" hide-header-close size="lg"
      footer-class="d-flex justify-content-start" modal-class="modal-blur" no-close-on-esc>
      <form @submit.prevent="createAssignLeave" id="createForm">
        <div class="row">
          <p>{{ reason }}</p>
        </div>

        <div class="progress mb-2" v-if="form.processing">
          <div class="progress">
            <div class="progress-bar progress-bar-indeterminate bg-green"></div>
          </div>
        </div>
      </form>

      <template #modal-footer="{ cancel }">
        <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">
          Close
        </button>
        <button type="button" class="btn btn-primary" :disabled="form.processing" @click.prevent="giveLeave">
          Submit
        </button>
      </template>
    </b-modal>

    <b-modal id="respond-to-resignation" title="Respond" hide-header-close
      footer-class="d-flex justify-content-start" modal-class="modal-blur" no-close-on-esc>
      <form @submit.prevent="approveResignation" id="createForm">
        <div class="row">

          <div class="col-lg-12">
            <div class="mb-3">
              <label for="name" class="form-label">Feed bank</label>
              <textarea v-model="form.responded_feedback"  class="form-control" name="" id=""></textarea>
              <span v-if="form.hasError('responded_feedback')" class="text-danger" v-text="form.getError('responded_feedback')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3 d-flex gap-2">
              <input v-model="form.reject" type="checkbox" class="form-check-input" name="" id="respond">
              <label for="respond" class="form-label">reject ?</label>

              <span v-if="form.hasError('reject')" class="text-danger" v-text="form.getError('reject')">
              </span>
            </div>
          </div>

        </div>

        <div class="progress mb-2" v-if="form.processing">
          <div class="progress">
            <div class="progress-bar progress-bar-indeterminate bg-green"></div>
          </div>
        </div>
      </form>
      <template #modal-footer="{ cancel }">
        <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">
          Close
        </button>
        <button type="button" class="btn btn-primary" :disabled="form.processing" @click.prevent="approveResignation">
          Submit
        </button>
      </template>
    </b-modal>
    <b-modal id="reject-reason" title="Reason for Reject" hide-header-close size="sm"
      footer-class="d-flex justify-content-start" modal-class="modal-blur" no-close-on-esc>
      <form @submit.prevent="editLeave" id="createForm">
        <div class="row">
          <div class="col-lg-12">
            <div class="mb-3">
              <label for="name" class="form-label">Reject Reason</label>
              <textarea class="form-control" v-model="form.reason"></textarea>
              <span v-if="form.hasError('employee_id')" class="text-danger" v-text="form.getError('employee_id')">
              </span>
            </div>
          </div>
        </div>

        <div class="progress mb-2" v-if="form.processing">
          <div class="progress">
            <div class="progress-bar progress-bar-indeterminate bg-green"></div>
          </div>
        </div>
      </form>
      <template #modal-footer="{ cancel }">
        <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">
          Close
        </button>
        <button type="button" class="btn btn-primary" :disabled="form.processing" @click.prevent="rejectLeave">
          Submit
        </button>
      </template>
    </b-modal>
  </div>
</template>
