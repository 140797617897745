<script>
  export default {
    props: {
      css: {
        type: Object,
        default () {
          return {
            wrapperClass: 'ui right floated pagination menu',
            activeClass: 'active large',
            disabledClass: 'disabled',
            pageClass: 'item',
            linkClass: 'icon item',
            paginationClass: 'ui bottom attached segment grid',
            paginationInfoClass: 'left floated left aligned six wide column',
            dropdownClass: 'ui search dropdown',
            icons: {
              first: 'angle double left icon',
              prev: 'left chevron icon',
              next: 'right chevron icon',
              last: 'angle double right icon',
            }
          }
        }
      },
      onEachSide: {
        type: Number,
        default () {
          return 2
        }
      },
    },
    data: function() {
      return {
        eventPrefix: 'vuetable-pagination:',
        tablePagination: null
      }
    },
    computed: {
      totalPage () {
        return this.tablePagination === null
          ? 0
          : this.tablePagination.last_page
      },
      isOnFirstPage () {
        return this.tablePagination === null
          ? false
          : this.tablePagination.current_page === 1
      },
      isOnLastPage () {
        return this.tablePagination === null
          ? false
          : this.tablePagination.current_page === this.tablePagination.last_page
      },
      notEnoughPages () {
        return this.totalPage < (this.onEachSide * 2) + 4
      },
      windowSize () {
        return this.onEachSide * 2 +1;
      },
      windowStart () {
        if (!this.tablePagination || this.tablePagination.current_page <= this.onEachSide) {
          return 1
        } else if (this.tablePagination.current_page >= (this.totalPage - this.onEachSide)) {
          return this.totalPage - this.onEachSide*2
        }

        return this.tablePagination.current_page - this.onEachSide
      },
    },
    methods: {
      loadPage (page) {
        this.$emit(this.eventPrefix+'change-page', page)
      },
      isCurrentPage (page) {
        return page === this.tablePagination.current_page
      },
      setPaginationData (tablePagination) {
        this.tablePagination = tablePagination
      },
      resetData () {
        this.tablePagination = null
      }
    }
  }
</script>
