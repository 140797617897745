<script>

export default {
  data() {
    return {
      fields: [
        {
          name: 'promotion_date',
          title: 'Date',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },
        {
          name: 'employee.name',
          title: 'Name',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },
        {
          name: '__slot:job_title',
          title: 'Job Title',
          titleClass: 'ps-1',
        },
        {
          name: '__slot:job_group',
          title: 'Job Group',
          titleClass: 'ps-1',
        },
        {
          name: 'promoted_by.name',
          title: 'Promoted By',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },
        {
          name: '__slot:actions',
          width: '15%',
          titleClass: 'text-center',
          dataClass: 'text-right',
        },
      ],

      moreParams: {
        filter: {
          search: ''
        }
      },

    }
  },

  computed: {
    vuetable() {
      return this.$refs.table.$refs.vuetable
    },
  },

  methods: {

    applyFilter() {
      this.vuetable.reload()
    },

    cleanUp() {
      this.form.reset()
    }
  },
}
</script>

<template>
  <div>
    <div class="card card-stacked">
      <div class="card-header">
        <div class="input-icon me-2">
                     <span class="input-icon-addon">
                        <i class="uil uil-search"></i>
                    </span>
          <input type="text" class="form-control bg-muted-lt rounded-2"
                 placeholder="Search"
                 @input="applyFilter"
                 v-model="moreParams.filter.search">
        </div>


      </div>
      <VueTable
          ref="table"
          api-url="/datatable/promotions"
          :fields="fields"
          :append-params="moreParams">
        <template v-slot:name="props">
          <div class="media">
            <div class="media-body align-self-center">
              <span class="text-sm">{{ props.rowData.name }} </span>
              <div class="font-size-12 text-purple-300">


                >
              </div>
            </div>
          </div>
        </template>
        <template v-slot:job_title="props">
          <div class="media">
            <div class="media-body align-self-center">
              <span class="text-sm">{{ props.rowData.previous_job_title }} ->  {{ props.rowData.new_job_title }} </span>
              <div class="font-size-12 text-purple-300">

              </div>
            </div>
          </div>
        </template>
        <template v-slot:job_group="props">
          <div class="media">
            <div class="media-body align-self-center">
              <span class="text-sm">{{ props.rowData.previous_job_group.name }} ->  {{ props.rowData.new_job_group.name }} </span>
              <div class="font-size-12 text-purple-300">

              </div>
            </div>
          </div>
        </template>

        <template v-slot:actions="props">
          <div class="dropdown">
            <button class="btn align-text-top py-1" data-bs-toggle="dropdown">
              <i class="uil uil-ellipsis-h"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <a disabled="true" class="dropdown-item text-danger" href="#" @click="showEditForm(props.rowData)">
                <i class="uil uil-pen fs-3 me-2"></i> Delete
              </a>
            </div>
          </div>
        </template>
      </VueTable>
    </div>
  </div>
</template>
