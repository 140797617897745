import Vue from 'vue'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

window.Pusher = Pusher

const laravelEcho = new Echo({
  broadcaster: 'pusher',
  key: process.env.MIX_PUSHER_APP_KEY,
  wsHost: process.env.MIX_APP_DOMAIN_URL,
  wsPort: process.env.MIX_PUSHER_APP_PORT,
  app_id: process.env.MIX_PUSHER_APP_ID,
  secret: process.env.MIX_PUSHER_APP_SECRET,
  forceTLS: false,
  enabledTransports: ['ws'],
  authTransport: 'ajax',
  encrypted: false,
  disableStats: true,
})

// const Client = new Pusher(process.env.MIX_PUSHER_APP_KEY, {
//   wsHost: process.env.MIX_APP_DOMAIN_URL,
//   wsPort: process.env.MIX_PUSHER_APP_PORT,
//   app_id: process.env.MIX_PUSHER_APP_ID,
//   disableStats: true,
//   secret: process.env.MIX_PUSHER_APP_SECRET,
//   encrypted: false,
//   forceTLS: false,
//   authTransport: 'ajax',
//   enabledTransports: ['ws'],
// })

// const laravelEcho = new Echo({
//   broadcaster: 'pusher',
//   client: Client,
//   authEndpoint: '/broadcasting/auth',
// })

// echo.connect()

laravelEcho.connector.pusher.connection.bind('connected', () => {
  console.log('✅ Soketi Connected!')
})

laravelEcho.connector.pusher.connection.bind('disconnected', () => {
  console.log('👋 Soketi Disconnected!')
})

laravelEcho.connector.pusher.connection.bind('failed', () => {
  console.log('❌ Soketi Connection Failed!')
})

laravelEcho.connect()

// laravelEcho.channel('billing')
//   .listen('.bill.added', function (event) {
//     console.log(event)
//   })

// echo.channel('billing')
//   .listen('.bill.added', function (e) {
//     console.log(e)
//   })

Object.defineProperty(Vue.prototype, '$laravelEcho', { value: laravelEcho })
