<script>

export default {
    props: ['contributions', 'employees', 'accounts'],
    data() {
        return {
            fields: [
                {
                    name: 'employee.name',
                    title: 'Employee',
                    titleClass: 'font-weight-bold',
                    dataClass: 'ps-3',
                },
               {
                    name: 'contribution.name',
                    title: 'Contribution',
                    titleClass: 'font-weight-bold',
                    dataClass: 'ps-3',
                },
              {
                    name: 'amount',
                    title: 'Amount',
                    titleClass: 'font-weight-bold',
                    dataClass: 'ps-3',
                },
                {
                    name: 'continuous',
                    title: 'Continuous',
                    titleClass: 'font-weight-bold',
                    dataClass: 'ps-3',
                    callback: function (value) {
                        if (value) {
                            return 'CONTINUOUS'
                        }

                        // return 'DEACTIVATED'
                    }
                },
                {
                    name: '__slot:actions',
                    width: '15%',
                    titleClass: 'text-center',
                    dataClass: 'text-right',
                },
            ],

            moreParams: {
                filter: {
                    search: ''
                }
            },

            form: new window.Form({
                id: null,
                name: '',
                employee_id: '',
                contribution_id: '',
                start_date: '',
                end_date: '',
                amount: null,
                continuous: false
            }),
        }
    },

    computed: {
        vuetable() {
            return this.$refs.table.$refs.vuetable
        },
    },

    methods: {
        showCreateForm() {
            this.$bvModal.show('create-modal')
        },

        showUpdateForm(rowData) {
            this.selectedDepartment = rowData
            this.viewEditForm = true
        },

        addContributions() {
            this.form.post('/employee/contributions')
                .then(() => {
                    this.$bvModal.hide('create-modal')
                    this.$toast.success('The department has been created')
                    this.vuetable.reload()
                })
                .catch()
        },

        showEditForm(rowData) {
            this.form.populate(rowData)
            this.form.id = rowData.id

            this.$bvModal.show('update-modal')
        },

        updateContribution() {
            this.form.patch('/employee/contributions/' + this.form.id)
                .then(() => {
                    this.vuetable.reload()
                    this.$bvModal.hide('update-modal')
                    this.$toast.success('The changes have been saved')
                })
                .catch()
        },

        applyFilter() {
            this.vuetable.reload()
        },

        cleanUp() {
            this.form.reset()
        }
    },
}
</script>

<template>
    <div>
        <div class="card card-stacked">
            <div class="card-header">
                <div class="input-icon me-2">
                     <span class="input-icon-addon">
                        <i class="uil uil-search"></i>
                    </span>
                    <input type="text" class="form-control bg-muted-lt rounded-2"
                           placeholder="Search Employees"
                           @input="applyFilter"
                           v-model="moreParams.filter.search">
                </div>

                <div class="card-actions">
                    <button class="btn btn-primary" @click="showCreateForm">
                        <i class="uil uil-plus me-1"></i>Add Contributions
                    </button>
                </div>
            </div>
            <VueTable
                ref="table"
                api-url="/datatable/employeecontributions"
                :fields="fields"
                :append-params="moreParams">
                <template v-slot:name="props">
                    <div class="media">
                        <div class="media-body align-self-center">
                            <span class="text-sm">{{ props.rowData.name }} </span>
                            <div class="font-size-12 text-purple-300">
                                {{ props.rowData.stores ? props.rowData.stores.name : '' }}
                                <span
                                    v-if="props.rowData.auto_post_request"
                                    class="badge badge-soft-success shadow-sm">Auto Post</span>
                                <span
                                    v-else
                                    class="badge badge-soft-secondary rounded px-1 p-1 shadow-sm border">Manual Post</span
                                >
                            </div>
                        </div>
                    </div>
                </template>

                <template v-slot:actions="props">
                    <div class="dropdown">
                        <button class="btn align-text-top py-1" data-bs-toggle="dropdown">
                            <i class="uil uil-ellipsis-h"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end">
                            <a class="dropdown-item" href="#" @click="showEditForm(props.rowData)">
                                <i class="uil uil-pen fs-3 me-2"></i> Edit
                            </a>
                        </div>
                    </div>
                </template>
            </VueTable>
        </div>

        <b-modal
            id="create-modal"
            title="Create Contribution"
            hide-header-close
            footer-class="d-flex justify-content-start"
            modal-class="modal-blur"
            no-close-on-esc
        >
            <form @submit.prevent="addContributions" id="createForm">
              <div class="form-group mb-4">
                <label class="form-label">Employee</label>
                <v-select
                    id="gender_id"
                    v-model="form.employee_id"
                    name="gender_id"
                    :options="employees"
                    label="name"
                    :reduce="option => option.id"
                    :clearable="false"
                />

                <span
                    v-if="form.hasError('employee_id')"
                    class="text-danger"
                    v-text="form.getError('employee_id')"
                />
              </div>
              <div class="form-group mb-4">
                <label class="form-label">Contribution</label>
                <v-select
                    id="gender_id"
                    v-model="form.contribution_id"
                    name="gender_id"
                    :options="contributions"
                    label="name"
                    :reduce="option => option.id"
                    :clearable="false"
                />

                <span
                    v-if="form.hasError('nationality')"
                    class="text-danger"
                    v-text="form.getError('nationality')"
                />
              </div>
              <div class="form-group mb-4">
                <div class="form-group">
                  <label for="" class="form-label">Amount</label>
                  <medic-money
                      :value="form.amount"
                      @input="(value) => {
                                            form.amount = value
                                        }"></medic-money>

                  <div class="text-danger"
                       v-if="form.hasError('unit_price')"
                       v-text="form.getError('unit_price')">
                  </div>
                </div>
              </div>
              <div class="form-group mb-4">
                <label class="form-label">Start Date</label>
                <div>
                  <date-picker
                      name="date_of_birth"
                      formclass="form-control bg-white"
                      :value="form.start_date"
                      :max-date="new Date()"
                      @on-change="(dateOb, dateStr) => {
                    form.start_date = dateStr
                  }"
                  />

                  <span
                      v-if="form.hasError('start_date')"
                      class="text-danger"
                      v-text="form.getError('start_date')"
                  />
                </div>
              </div>
              <div class="form-group mb-4">
                <label class="form-label">End Date</label>
                <div>
                  <date-picker
                      name="date_of_birth"
                      formclass="form-control bg-white"
                      :value="form.end_date"
                      :max-date="new Date()"
                      @on-change="(dateOb, dateStr) => {
                    form.end_date = dateStr
                  }"
                  />

                  <span
                      v-if="form.hasError('end_date')"
                      class="text-danger"
                      v-text="form.getError('end_date')"
                  />
                </div>
              </div>
              <div class="mb-3">
                <div class="d-flex gap-2">
                  <input id="continuous" v-model="form.continuous" class="form-check-input" type="checkbox">
                  <label class="form-label" for="continuous">Continuous</label>
                </div>

                <span
                    v-if="form.hasError('continuous')"
                    class="text-danger"
                    v-text="form.getError('continuous')"
                />
              </div>
                <div class="progress mb-2" v-if="form.processing">
                    <div class="progress">
                        <div class="progress-bar progress-bar-indeterminate bg-green"></div>
                    </div>
                </div>

            </form>

            <template #modal-footer="{cancel}">
                <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">Close
                </button>
                <button type="button" class="btn btn-primary" :disabled="form.processing"
                        @click.prevent="addContributions">
                    Submit
                </button>
            </template>

        </b-modal>

        <b-modal
            id="update-modal"
            title="Edit Contribution"
            hide-header-close
            footer-class="d-flex justify-content-start"
            modal-class="modal-blur"
            no-close-on-esc
        >
          <form @submit.prevent="updateContribution" id="createForm">
            <div class="form-group mb-4">
              <label class="form-label">Employee</label>
              <v-select
                  id="gender_id"
                  v-model="form.employee_id"
                  name="gender_id"
                  :options="employees"
                  label="name"
                  :reduce="option => option.id"
                  :clearable="false"
              />

              <span
                  v-if="form.hasError('employee_id')"
                  class="text-danger"
                  v-text="form.getError('employee_id')"
              />
            </div>
            <div class="form-group mb-4">
              <label class="form-label">Contribution</label>
              <v-select
                  id="gender_id"
                  v-model="form.contribution_id"
                  name="gender_id"
                  :options="contributions"
                  label="name"
                  :reduce="option => option.id"
                  :clearable="false"
              />

              <span
                  v-if="form.hasError('nationality')"
                  class="text-danger"
                  v-text="form.getError('nationality')"
              />
            </div>
            <div class="form-group mb-4">
              <div class="form-group">
                <label for="" class="form-label">Amount</label>
                <medic-money
                    :value="form.amount"
                    @input="(value) => {
                                            form.amount = value
                                        }"></medic-money>

                <div class="text-danger"
                     v-if="form.hasError('unit_price')"
                     v-text="form.getError('unit_price')">
                </div>
              </div>
            </div>
            <div class="form-group mb-4">
              <label class="form-label">Start Date</label>
              <div>
                <date-picker
                    name="date_of_birth"
                    formclass="form-control bg-white"
                    :value="form.start_date"
                    :max-date="new Date()"
                    @on-change="(dateOb, dateStr) => {
                    form.start_date = dateStr
                  }"
                />

                <span
                    v-if="form.hasError('start_date')"
                    class="text-danger"
                    v-text="form.getError('start_date')"
                />
              </div>
            </div>
            <div class="form-group mb-4">
              <label class="form-label">End Date</label>
              <div>
                <date-picker
                    name="date_of_birth"
                    formclass="form-control bg-white"
                    :value="form.end_date"
                    :max-date="new Date()"
                    @on-change="(dateOb, dateStr) => {
                    form.end_date = dateStr
                  }"
                />

                <span
                    v-if="form.hasError('end_date')"
                    class="text-danger"
                    v-text="form.getError('end_date')"
                />
              </div>
            </div>
            <div class="mb-3">
              <div class="d-flex gap-2">
                <input id="continuous" v-model="form.continuous" class="form-check-input" type="checkbox">
                <label class="form-label" for="continuous">Continuous</label>
              </div>

              <span
                  v-if="form.hasError('continuous')"
                  class="text-danger"
                  v-text="form.getError('continuous')"
              />
            </div>
            <div class="progress mb-2" v-if="form.processing">
              <div class="progress">
                <div class="progress-bar progress-bar-indeterminate bg-green"></div>
              </div>
            </div>

          </form>

          <template #modal-footer="{cancel}">
                <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">Close
                </button>
                <button type="button" class="btn btn-primary" :disabled="form.processing"
                        @click.prevent="updateContribution">
                    Submit
                </button>

            </template>
        </b-modal>

    </div>
</template>
