var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-overlay",
        {
          attrs: {
            show: _vm.showOverlay,
            opacity: "0.9",
            rounded: "sm",
            "spinner-variant": "primary",
            "spinner-small": "",
          },
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c(
                "div",
                { staticClass: "table-responsive-sm" },
                [
                  _c("Vuetable", {
                    ref: "vuetable",
                    attrs: {
                      id: "vuetable",
                      "api-url": _vm.apiUrl,
                      "api-mode": _vm.apiMode,
                      data: _vm.data,
                      "http-fetch": _vm.httpFetch,
                      fields: _vm.fields,
                      "table-height": _vm.tableHeight,
                      "pagination-path": _vm.paginationPath,
                      "per-page": _vm.perPage,
                      "row-class": _vm.onRowClass,
                      css: _vm.tableCss.table,
                      "sort-order": _vm.sortOrder,
                      "multi-sort": false,
                      "append-params": _vm.appendParams,
                      "load-on-start": _vm.loadOnStart,
                      "data-manager": _vm.dataManager,
                      transform: _vm.transformData,
                      "detail-row-component": _vm.detailRowComponent,
                    },
                    on: {
                      "vuetable:cell-clicked": _vm.onCellClicked,
                      "vuetable:row-clicked": _vm.onRowClicked,
                      "vuetable:cell-row": _vm.onRowClicked,
                      "vuetable:checkbox-toggled": _vm.onCheckBoxToggled,
                      "vuetable:checkbox-toggled-all": _vm.onCheckBoxToggledAll,
                      "vuetable:pagination-data": _vm.onPaginationData,
                      "vuetable:loading": _vm.showLoader,
                      "vuetable:load-success": _vm.loadedSuccess,
                      "vuetable:loaded": _vm.hideLoader,
                      "vuetable:load-error": _vm.loadError,
                    },
                    scopedSlots: _vm._u(
                      [
                        _vm._l(
                          _vm.$scopedSlots
                            ? Object.keys(_vm.$scopedSlots)
                            : null,
                          function (slotName) {
                            return {
                              key: slotName,
                              fn: function (props) {
                                return [
                                  _vm._t(slotName, null, {
                                    rowData: props.rowData,
                                    rowIndex: props.rowIndex,
                                    rowField: props.rowField,
                                  }),
                                ]
                              },
                            }
                          }
                        ),
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.showPagination
                ? _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-end align-items-baseline px-3 pt-3 bg-light",
                    },
                    [
                      _c("vuetable-pagination-bootstrap", {
                        ref: "pagination",
                        attrs: { css: _vm.css.pagination },
                        on: {
                          "vuetable-pagination:change-page": _vm.onChangePage,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }