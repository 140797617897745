var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "card card-stacked" },
        [
          _c("div", { staticClass: "card-header" }, [
            _c("div", { staticClass: "input-icon me-2" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.moreParams.filter.name,
                    expression: "moreParams.filter.name",
                  },
                ],
                staticClass: "form-control bg-muted-lt rounded-2",
                attrs: { type: "text", placeholder: "Search employee" },
                domProps: { value: _vm.moreParams.filter.name },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.moreParams.filter,
                        "name",
                        $event.target.value
                      )
                    },
                    _vm.applyFilter,
                  ],
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-actions" }),
          ]),
          _vm._v(" "),
          _c("VueTable", {
            ref: "table",
            attrs: {
              "api-url": "/datatable/leave-requests",
              fields: _vm.fields,
              "append-params": _vm.moreParams,
            },
            scopedSlots: _vm._u([
              {
                key: "approved",
                fn: function (props) {
                  return [
                    _c("div", { staticClass: "media" }, [
                      _c(
                        "div",
                        { staticClass: "media-body align-self-center" },
                        [
                          _c(
                            "div",
                            { staticClass: "font-size-12 text-purple-300" },
                            [
                              props.rowData.approved
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "badge badge-success rounded px-1 p-1 shadow-sm border",
                                    },
                                    [_c("small", [_vm._v("HR has approved")])]
                                  )
                                : props.rowData.rejected
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "badge badge-soft-warning rounded px-1 p-1 shadow-sm border",
                                    },
                                    [_c("small", [_vm._v("HR has rejected")])]
                                  )
                                : _c(
                                    "span",
                                    {
                                      staticClass:
                                        "badge badge-soft-secondary shadow-sm",
                                    },
                                    [_vm._v("pending HR approval")]
                                  ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "name",
                fn: function (props) {
                  return [
                    _c("div", { staticClass: "media" }, [
                      _c(
                        "div",
                        { staticClass: "media-body align-self-center" },
                        [
                          _c("span", { staticClass: "text-sm" }, [
                            _vm._v(_vm._s(props.rowData.name) + " "),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "font-size-12 text-purple-300" },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    props.rowData.stores
                                      ? props.rowData.stores.name
                                      : ""
                                  ) +
                                  "\n                "
                              ),
                              props.rowData.auto_post_request
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "badge badge-soft-success shadow-sm",
                                    },
                                    [_vm._v("Auto Post")]
                                  )
                                : _c(
                                    "span",
                                    {
                                      staticClass:
                                        "badge badge-soft-secondary rounded px-1 p-1 shadow-sm border",
                                    },
                                    [_vm._v("Manual Post")]
                                  ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "actions",
                fn: function (props) {
                  return [
                    _c("div", { staticClass: "dropdown" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn align-text-top py-1",
                          attrs: { "data-bs-toggle": "dropdown" },
                        },
                        [_c("i", { staticClass: "uil uil-ellipsis-h" })]
                      ),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "dropdown-menu dropdown-menu-end",
                      }),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "create-modal",
            title: "Assign a Leave",
            "hide-header-close": "",
            size: "lg",
            "footer-class": "d-flex justify-content-start",
            "modal-class": "modal-blur",
            "no-close-on-esc": "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn me-auto",
                      attrs: { type: "button", disabled: _vm.form.processing },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return cancel.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Close\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button", disabled: _vm.form.processing },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.createAssignLeave.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n          Submit\n        ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "form",
            {
              attrs: { id: "createForm" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.createAssignLeave.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("Employee Name")]
                      ),
                      _vm._v(" "),
                      _c("v-select", {
                        attrs: {
                          id: "gender_id",
                          name: "department_id",
                          options: _vm.employees,
                          label: "name",
                          reduce: (option) => option.id,
                          clearable: false,
                        },
                        model: {
                          value: _vm.form.employee_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "employee_id", $$v)
                          },
                          expression: "form.employee_id",
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("employee_id")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.form.getError("employee_id")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("Leave Type")]
                      ),
                      _vm._v(" "),
                      _c("v-select", {
                        attrs: {
                          id: "gender_id",
                          name: "leave_type_id",
                          options: _vm.leave_types,
                          label: "name",
                          reduce: (option) => option.id,
                          clearable: false,
                        },
                        model: {
                          value: _vm.form.leave_type_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "leave_type_id", $$v)
                          },
                          expression: "form.leave_type_id",
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("leave_type_id")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.form.getError("leave_type_id")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("Start Date")]
                      ),
                      _vm._v(" "),
                      _c("date-picker", {
                        attrs: {
                          name: "date_of_birth",
                          value: _vm.form.start_date,
                          "max-date": new Date(),
                        },
                        on: {
                          "on-change": (dateOb, dateStr) => {
                            _vm.form.start_date = dateStr
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("start_date")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.form.getError("start_date")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("End Date")]
                      ),
                      _vm._v(" "),
                      _c("date-picker", {
                        attrs: {
                          name: "date_of_birth",
                          value: _vm.form.end_date,
                          "max-date": _vm.futureDate,
                        },
                        on: {
                          "on-change": (dateOb, dateStr) => {
                            _vm.form.end_date = dateStr
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("end_date")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.form.getError("end_date ")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm.form.processing
                ? _c("div", { staticClass: "progress mb-2" }, [
                    _c("div", { staticClass: "progress" }, [
                      _c("div", {
                        staticClass:
                          "progress-bar progress-bar-indeterminate bg-green",
                      }),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "update-modal",
            title: "Edit Leave",
            "hide-header-close": "",
            size: "lg",
            "footer-class": "d-flex justify-content-start",
            "modal-class": "modal-blur",
            "no-close-on-esc": "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn me-auto",
                      attrs: { type: "button", disabled: _vm.form.processing },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return cancel.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Close\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button", disabled: _vm.form.processing },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.editLeave.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n          Submit\n        ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "form",
            {
              attrs: { id: "createForm" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.editLeave.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("Employee Name")]
                      ),
                      _vm._v(" "),
                      _c("v-select", {
                        attrs: {
                          id: "gender_id",
                          name: "department_id",
                          options: _vm.employees,
                          label: "name",
                          reduce: (option) => option.id,
                          clearable: false,
                        },
                        model: {
                          value: _vm.form.employee_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "employee_id", $$v)
                          },
                          expression: "form.employee_id",
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("employee_id")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.form.getError("employee_id")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("Leave Type")]
                      ),
                      _vm._v(" "),
                      _c("v-select", {
                        attrs: {
                          id: "gender_id",
                          name: "leave_type_id",
                          options: _vm.leave_types,
                          label: "name",
                          reduce: (option) => option.id,
                          clearable: false,
                        },
                        model: {
                          value: _vm.form.leave_type_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "leave_type_id", $$v)
                          },
                          expression: "form.leave_type_id",
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("leave_type_id")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.form.getError("leave_type_id")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("Start Date")]
                      ),
                      _vm._v(" "),
                      _c("date-picker", {
                        attrs: {
                          name: "date_of_birth",
                          value: _vm.form.start_date,
                          "max-date": new Date(),
                        },
                        on: {
                          "on-change": (dateOb, dateStr) => {
                            _vm.form.start_date = dateStr
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("start_date")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.form.getError("start_date")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("End Date")]
                      ),
                      _vm._v(" "),
                      _c("date-picker", {
                        attrs: {
                          name: "date_of_birth",
                          value: _vm.form.end_date,
                          "max-date": new Date(),
                        },
                        on: {
                          "on-change": (dateOb, dateStr) => {
                            _vm.form.end_date = dateStr
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("end_date")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.form.getError("end_date ")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm.form.processing
                ? _c("div", { staticClass: "progress mb-2" }, [
                    _c("div", { staticClass: "progress" }, [
                      _c("div", {
                        staticClass:
                          "progress-bar progress-bar-indeterminate bg-green",
                      }),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "input-icon-addon" }, [
      _c("i", { staticClass: "uil uil-search" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }