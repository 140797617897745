<script>

export default {
  props: {
    leave_types: {
      type: Array,
      required: true,
      default: function () {
        return []
      },
    },
  },
  data () {
    return {
      fields: [
        {
          name: 'incident_date',
          title: 'Date',
          titleClass: 'font-weight-bold text-right',
          dataClass: 'text-right',

        },
        {
          name: '__slot:name',
          title: 'Employee',
          titleClass: 'font-weight-bold',
        },
        {
          name: 'description',
          title: 'Description',
          titleClass: 'font-weight-bold text-right',
          dataClass: 'text-right',

        },
        {
          name: 'severity_level.name',
          title: 'Severity Level',
          titleClass: 'font-weight-bold text-right',
          dataClass: 'text-right',

        },
        {
          name: 'action_taken.action_date',
          title: 'Action Date',
          titleClass: 'font-weight-bold text-right',
          dataClass: 'text-right',

        },
        {
          name: 'action_taken.action_type.name',
          title: 'Action Taken',
          titleClass: 'font-weight-bold text-right',
          dataClass: 'text-right',

        },
        {
          name: 'status',
          title: 'Status',
          titleClass: 'font-weight-bold text-right',
          dataClass: 'text-right',
          width: '10%',
          callback: function (value){
            if(value){
              return 'Closed'
            }else{
              return 'Open'
            }
          }
        },

        {
          name: '__slot:actions',
          titleClass: 'text-center',
          dataClass: 'text-left',
          width: '5%',
        },
      ],
      moreParams: {
        filter: {
          search: '',
          caseresolved: true,
        }
      },
    }
  },

  computed: {
    vuetable () {
      return this.$refs.table.$refs.vuetable
    },
  },

  watch: {

  },
  methods: {

    viewDetails (rowData) {
      console.log(rowData);
      this.$bvModal.show('detail-view-modal')
    },


    applyFilter () {
      this.vuetable.reload()
    },

    cleanUp () {
      this.form.reset()
    },
  },
}
</script>

<template>
  <div>
    <div class="card card-stacked">
      <div class="card-header">
        <div class="input-icon me-2">
                    <span class="input-icon-addon">
                        <i class="uil uil-search"></i>
                    </span>
          <input type="text" class="form-control bg-muted-lt rounded-2" placeholder="Search"
                 @input="applyFilter" v-model="moreParams.filter.name">
        </div>
      </div>
      <VueTable ref="table" api-url="/datatable/disciplinary-cases" :fields="fields" :append-params="moreParams">
        <template v-slot:name="props">
          <div class="media">
            <div class="media-body align-self-center">
              <span class="text-sm">{{ props.rowData.employee.name }} </span>
            </div>
          </div>
        </template>

        <template v-slot:actions="props">
          <div class="dropdown">
            <button class="btn align-text-top py-1" data-bs-toggle="dropdown">
              <i class="uil uil-ellipsis-h"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-end">

              <div class="dropdown-item">
                <a href="#" class="text-secondary" @click.prevent="viewDetails(props.rowData)"
                   title="Mail the payslip to employee">
                  <i class="uil uil-envelope mr-2"></i> View Details</a>
              </div>

            </div>
          </div>
        </template>
      </VueTable>
    </div>
    <b-modal id="detail-view-modal" title="Disciplinary Case View" hide-header-close
             footer-class="d-flex justify-content-start" modal-class="modal-blur" no-close-on-esc>
      <div>
        inve
      </div>
      <template #modal-footer="{ cancel }">
        <button type="button" class="btn me-auto" @click.prevent="cancel" >Close
        </button>
      </template>

    </b-modal>
  </div>
</template>
