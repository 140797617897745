<script>
import { defineComponent } from 'vue'
import { MediaLibraryAttachment } from 'media-library-pro-vue2-attachment'

export default defineComponent({
  name: 'EmployeeDocument',
  components: {
    MediaLibraryAttachment,
  },
  props: {
    employee: {
      type: Object,
      required: true,
    },
  },

  data () {
    return {

      documents: [],

      value: {
        name: 'media',
        media: {},
      },

      enableForm: true,

      form: new window.Form({
        document_name: '',
        employee_id: this.employee.id,
        media: {},
        name: 'media',
      }),

      validationErrors: {},
      isReadyToSubmit: true,

    }
  },

  mounted () {
    this.retrieveDocuments()
  },
  methods: {
    toggleForm () {
      this.enableForm = !this.enableForm
    },

    onChange (media) {
      // this.form.media = media
      this.value.media = media
    },

    uploadDocument () {
      this.form.media = this.value.media

      this.form.post('/employee/employee-photo')
          .then(() => {
            this.$refs.mediaComponent.mediaLibrary.changeState(state => {
              state.media = []
            })
            this.$toast.success('Photo uploaded successfully')
            setTimeout(() => {
              window.location.reload()
            }, 3000)
          }).catch()
    },

    retrieveDocuments () {
      this.$httpClient.get('/employee/employee-documents', {
        params: {
          employee_id: this.employee.id,
        },
      })
          .then(({ data }) => {
            this.documents = data
          }).catch((error) => {
        console.error(error)
      })
    },
  },
})
</script>

<template>
  <div v-if="enableForm">
    <div class="card rounded-3">
      <div class="card-header">
        <div>
          <h3 class="card-title">
            Photo
          </h3>
          <p class="card-subtitle">
            Upload photo
          </p>
        </div>
      </div>

      <div class="card-body px-4">
        <form @submit.prevent="uploadDocument">

          <div class="form-group mb-4">

            <media-library-attachment
                ref="mediaComponent"
                name="media"
                :validation-rules="{
                accept: ['image/jpeg', 'image/gif'],
                maxSizeInKB: 10240
              }"
                :validation-errors="validationErrors"
                :initial-value="value.media"
                @is-ready-to-submit-change="isReadyToSubmit = $event"
                @change="onChange"
            />
          </div>

          <button
              class="btn btn-primary"
              :disabled="form.processing"
              type="submit"
          >
            Upload Document
          </button>
        </form>
      </div>

    </div>

  </div>
</template>

<style scoped>

</style>
