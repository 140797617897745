var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav", { attrs: { "aria-label": "Page navigation example" } }, [
    _c(
      "ul",
      { staticClass: "pagination pagination-sm" },
      [
        _c(
          "li",
          {
            staticClass: "page-item",
            class: { "previous disabled": _vm.isOnFirstPage },
          },
          [
            _c(
              "a",
              {
                staticClass: "page-link",
                attrs: { href: "#", tabindex: "-1", "aria-disabled": "true" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.loadPage("prev")
                  },
                },
              },
              [
                _vm._v("Prev\n                "),
                _c(
                  "svg",
                  {
                    staticClass: "icon",
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "24",
                      height: "24",
                      viewBox: "0 0 24 24",
                      "stroke-width": "2",
                      stroke: "currentColor",
                      fill: "none",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        stroke: "none",
                        d: "M0 0h24v24H0z",
                        fill: "none",
                      },
                    }),
                    _vm._v(" "),
                    _c("polyline", { attrs: { points: "15 6 9 12 15 18" } }),
                  ]
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _vm.notEnoughPages
          ? _vm._l(_vm.totalPage, function (n) {
              return _c(
                "li",
                {
                  staticClass: "page-item",
                  class: { active: _vm.isCurrentPage(n) },
                },
                [
                  _c("a", {
                    staticClass: "page-link",
                    attrs: { href: "#" },
                    domProps: { innerHTML: _vm._s(n) },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.loadPage(n)
                      },
                    },
                  }),
                ]
              )
            })
          : _vm._l(_vm.windowSize, function (n) {
              return _c(
                "li",
                {
                  staticClass: "page-item",
                  class: {
                    "page-item active": _vm.isCurrentPage(
                      _vm.windowStart + n - 1
                    ),
                  },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "page-link",
                      attrs: { href: "#" },
                      domProps: { innerHTML: _vm._s(_vm.windowStart + n - 1) },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.loadPage(_vm.windowStart + n - 1)
                        },
                      },
                    },
                    [_vm._v("Next")]
                  ),
                ]
              )
            }),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "page-item", class: { disabled: _vm.isOnLastPage } },
          [
            _c(
              "a",
              {
                staticClass: "page-link",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.loadPage("next")
                  },
                },
              },
              [
                _vm._v("\n                Next\n                "),
                _c(
                  "svg",
                  {
                    staticClass: "icon",
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "24",
                      height: "24",
                      viewBox: "0 0 24 24",
                      "stroke-width": "2",
                      stroke: "currentColor",
                      fill: "none",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        stroke: "none",
                        d: "M0 0h24v24H0z",
                        fill: "none",
                      },
                    }),
                    _vm._v(" "),
                    _c("polyline", { attrs: { points: "9 6 15 12 9 18" } }),
                  ]
                ),
              ]
            ),
          ]
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }