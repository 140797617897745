<script>

export default {
  props: {
    actionstypes: {
      type: Array,
      required: true,
      default: function () {
        return []
      },
    },
  },
  data () {
    return {
      fields: [
        {
          name: 'incident_date',
          title: 'Date',
          titleClass: 'font-weight-bold text-right',
          dataClass: 'text-right',

        },
        {
          name: '__slot:name',
          title: 'Employee',
          titleClass: 'font-weight-bold',
        },
        {
          name: 'description',
          title: 'Description',
          titleClass: 'font-weight-bold text-right',
          dataClass: 'text-right',

        },
        {
          name: 'severity_level.name',
          title: 'Severity Level',
          titleClass: 'font-weight-bold text-right',
          dataClass: 'text-right',

        },
        {
          name: 'resolution_date',
          title: 'Resolution Date',
          titleClass: 'font-weight-bold text-right',
          dataClass: 'text-right',

        },
        {
          name: 'status',
          title: 'Status',
          titleClass: 'font-weight-bold text-right',
          dataClass: 'text-right',
          width: '10%',
          callback: function (value){
            if(value){
              return 'Closed'
            }else{
              return 'Open'
            }
          }
        },

        {
          name: '__slot:actions',
          titleClass: 'text-center',
          dataClass: 'text-left',
          width: '5%',
        },
      ],
      moreParams: {
        filter: {
          search: '',
          caseresolved:false,
        }
      },

      form: new window.Form({
        case_id: null,
        action_type_id: '',
        action_date:'',
        action_details: '',
      }),
    }
  },

  computed: {
    vuetable () {
      return this.$refs.table.$refs.vuetable
    },
  },
  mounted () {
    this.retrieveEmployees()
  },
  watch: {

  },
  methods: {
    showActionForm (rowData) {
      this.form.case_id = rowData.id
      this.$bvModal.show('resolve-form-modal')
    },
    resolveCase(){
      this.form.post('/disciplinary-case/resolve-case')
          .then(() => {
            this.$bvModal.hide('resolve-form-modal');
            this.$toast.success('Case resolved');
            this.vuetable.reload();
          })
          .catch()
    },

    applyFilter () {
      this.vuetable.reload()
    },

    cleanUp () {
      this.form.reset()
    },
  },
}
</script>

<template>
  <div>
    <div class="card card-stacked">
      <div class="card-header">
        <div class="input-icon me-2">
                    <span class="input-icon-addon">
                        <i class="uil uil-search"></i>
                    </span>
          <input type="text" class="form-control bg-muted-lt rounded-2" placeholder="Search"
                 @input="applyFilter" v-model="moreParams.filter.search">
        </div>
      </div>
      <VueTable ref="table" api-url="/datatable/disciplinary-cases" :fields="fields" :append-params="moreParams">
        <template v-slot:name="props">
          <div class="media">
            <div class="media-body align-self-center">
              <span class="text-sm">{{ props.rowData.employee.name }} </span>
            </div>
          </div>
        </template>

        <template v-slot:actions="props">
          <div class="dropdown">
            <button class="btn align-text-top py-1" data-bs-toggle="dropdown">
              <i class="uil uil-ellipsis-h"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <b-dropdown-header>Action</b-dropdown-header>

              <div class="dropdown-item">
                <a href="#" class="text-secondary" @click="showActionForm(props.rowData)"
                   title="Download the payslip">
                  <i class="uil uil-down-arrow mr-2"></i> resolve case</a>
              </div>
            </div>
          </div>
        </template>
      </VueTable>
    </div>
    <b-modal id="resolve-form-modal" title="Resolve Case" hide-header-close
             footer-class="d-flex justify-content-start" modal-class="modal-blur" no-close-on-esc>
      <div class="form-group mb-4">
        <label for="physical_address" class="form-label">Action</label>
        <v-select id="item_id"  :clearable="false" v-model="form.action_type_id"
                  :reduce="(option) => option.id" :options="actionstypes" label="name">
        </v-select>

        <span v-if="form.hasError('action_type_id')" class="text-danger"
              v-text="form.getError('action_type_id')" />
      </div>
      <div class="form-group mb-4">
        <label for="physical_address" class="form-label">Action Details</label>
        <textarea class="form-control" v-model="form.action_details"></textarea>

        <span v-if="form.hasError('action_details')" class="text-danger"
              v-text="form.getError('action_details')" />
      </div>
      <div class="form-group mb-4">
        <label for="physical_address" class="form-label">Action Date</label>
        <date-picker name="date_of_birth" :value="form.action_date" :min-date="new Date()" @on-change="(dateOb, dateStr) => {
                      form.action_date = dateStr
                    }" />

        <span v-if="form.hasError('action_date')" class="text-danger"
              v-text="form.getError('action_date')" />
      </div>
      <template #modal-footer="{ cancel }">
        <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">Close
        </button>
        <button type="button" @click="resolveCase" class="btn btn-primary" :disabled="form.processing">
          Submit
        </button>
      </template>

    </b-modal>
  </div>
</template>
