<script>
import { defineComponent } from 'vue'
import { toPrice } from '@plugins/money'

export default defineComponent({
  name: 'Pensions',
  props: {
    employee: {
      type: Object,
      default: () => { },
    },
    permissions: {
      type: Array,
      default: () => [],
    },
    pensions: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {

      fields: [
        {
          name: 'name',
          title: 'Loan',
          titleClass: ' text-start pe-3',
          dataClass: ' text-center pe-3',

        },
        {
          name: 'amount',
          title: 'Loan Issued',
          titleClass: 'ps-3',
          dataClass: 'ps-3',

        },

        {
          name: 'loan_balance',
          title: 'Balance',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'due_date',
          title: 'Due Date',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },

        // {
        //   name: '__slot:status',
        //   title: 'STATUS',
        //   dataClass: 'ps-1',
        //   titleClass: 'ps-1',
        //   width: '10%',
        // },
        {
          name: '__slot:actions',
          dataClass: 'txt-center',
          width: '5%',
        },
      ],

      employeeQualifications: [],

      billToReverse: '',

      form: new window.Form({
        id: null,
        employee_payroll_id: this.employee.id,
        name: '',
        balance: '',
        amount: '',
        installment: '',
        due_date: '',
      }),
    }
  },
  mounted() {
    this.retrieveEmployeeLoans()
  },

  methods: {
    retrieveEmployeeLoans() {
      console.log(this.employee)
      this.$httpClient.get('/employee/loans/' + this.employee.hashid)
        .then(({ data }) => {
          this.employeeQualifications = data;
        }).catch(() => {
          this.$toast.error('There was a problem retrieving employee loans')
        })
    },
    generateStatement(rowData){
      this.id = rowData.id
      this.$httpClient.get(`/reports/loan/${rowData.id}/generate`, {
        responseType: 'blob',
        params: {
          ...this.form.data(),
        },
      })
          .then(({ data }) => {
            this.form.processing = false

            const url = URL.createObjectURL(
                new Blob([data], { type: 'application/pdf' }),
            )

            const link = document.createElement('a')

            link.href = url
            link.target = '_blank'
            link.click()
          }).catch((error) => {
        this.form.processing = false

        this.form.errors.record(error.response.data.errors)
      })
    },
    deleteLoan (rowData){
      this.form.id = rowData.id
      this.$toast.question('Are you sure?', 'You want to delete').then(() => {
        this.$httpClient.delete('/employee/loans/' + this.form.id )
        .then((error) => {
          this.$toast.success('deleted')
          this.cleanUp()
          this.vuetable.reload()
        }).catch((error) => {
          this.$toast.error(error.response.data)
        })


      })
    },
    openLoanModal() {
      this.$bvModal.show('loan-modal')
    },


    recordLoan() {
      this.form.post('/employee/loans')
        .then(() => {
          this.$toast.success('Employee loan has been recorded')
          this.cleanUp()
          this.$bvModal.hide('loan-modal')
        })
        .catch((error) => {
          this.form.processing = false
          this.form.errors.record(error.response.data.errors)
        })
    },
    editLoan (data) {
      this.form.populate(data);
      this.$bvModal.show('edit-loan-modal')
    },
    updateLoan () {
      this.form.patch('/employee/loans/' + this.form.id)
        .then(() => {
          this.$toast.success('The changes have been saved')

          this.cleanUp()
          this.$bvModal.hide('edit-loan-modal')
          this.vuetable.reload()
        })
        .catch()
    },
    cleanUp () {
      this.form.reset()
      this.retrieveEmployeeLoans()
    },

  },

})
</script>

<template>

  <div>

    <b-modal id="loan-modal" body-class="px-4" content-class="bg-white rounded-3"
      footer-class="d-flex justify-content-between bg-muted-lt border-top" hide-header-close modal-class="modal-blur"
      no-close-on-backdrop no-close-on-esc no-enforce-focus title="Record Loan Form" @hidden="cleanUp">
      <div class="form-group mb-4">
        <label for="physical_address" class="form-label">Loan Description</label>
        <input id="physical_address" v-model="form.name" type="text" class="form-control">

        <span v-if="form.hasError('name')" class="text-danger" v-text="form.getError('name')" />
      </div>
      <div class="form-group mb-4">
        <label for="physical_address" class="form-label">Loan Offered</label>
        <medic-money :value="form.amount" @input="(value) => {
          form.amount = value
        }"></medic-money>

        <span v-if="form.hasError('amount')" class="text-danger" v-text="form.getError('amount')" />
      </div>
      <div class="form-group mb-4">
        <label for="physical_address" class="form-label">Repay Installment</label>
        <medic-money :value="form.installment" @input="(value) => {
          form.installment = value
        }"></medic-money>

        <span v-if="form.hasError('installment')" class="text-danger" v-text="form.getError('installment')" />
      </div>
      <div class="form-group mb-4">
        <label class="form-label">Due Date</label>
        <div>
          <date-picker name="date_of_birth" formclass="form-control bg-white" :value="form.due_date" :max-date="false"
            @on-change="(dateOb, dateStr) => {
              form.due_date = dateStr
            }" />

          <span v-if="form.hasError('due_date')" class="text-danger" v-text="form.getError('due_date')" />
        </div>
      </div>


      <div v-if="form.processing" class="progress mb-2">
        <div class="progress">
          <div class="progress-bar progress-bar-indeterminate bg-green" />
        </div>
      </div>

      <template #modal-footer="{ cancel }">
        <button :disabled="form.processing" class="btn" type="button" @click.prevent="cancel">
          Close
        </button>

        <button :disabled="form.processing" class="btn btn-primary" type="submit" @click="recordLoan">
          Submit
        </button>
      </template>
    </b-modal>
    <b-modal id="edit-loan-modal" body-class="px-4" content-class="bg-white rounded-3"
      footer-class="d-flex justify-content-between bg-muted-lt border-top" hide-header-close modal-class="modal-blur"
      no-close-on-backdrop no-close-on-esc no-enforce-focus title="Edit Qualification Form" @hidden="cleanUp">
      <div class="form-group mb-4">
        <label for="physical_address" class="form-label">Loan Description</label>
        <input id="physical_address" v-model="form.name" type="text" class="form-control">

        <span v-if="form.hasError('name')" class="text-danger" v-text="form.getError('name')" />
      </div>
      <div class="form-group mb-4">
        <label for="physical_address" class="form-label">Loan Offered</label>
        <medic-money :value="form.amount" @input="(value) => {
          form.amount = value
        }"></medic-money>

        <span v-if="form.hasError('amount')" class="text-danger" v-text="form.getError('amount')" />
      </div>
      <div class="form-group mb-4">
        <label for="physical_address" class="form-label">Repay Installment</label>
        <medic-money :value="form.installment" @input="(value) => {
          form.installment = value
        }"></medic-money>

        <span v-if="form.hasError('installment')" class="text-danger" v-text="form.getError('installment')" />
      </div>
      <div class="form-group mb-4">
        <label class="form-label">Due Date</label>
        <div>
          <date-picker name="date_of_birth" formclass="form-control bg-white" :value="form.due_date" :max-date="false"
            @on-change="(dateOb, dateStr) => {
              form.due_date = dateStr
            }" />

          <span v-if="form.hasError('due_date')" class="text-danger" v-text="form.getError('due_date')" />
        </div>
      </div>
      <div v-if="form.processing" class="progress mb-2">
        <div class="progress">
          <div class="progress-bar progress-bar-indeterminate bg-green" />
        </div>
      </div>

      <template #modal-footer="{ cancel }">
        <button :disabled="form.processing" class="btn" type="button" @click.prevent="cancel">
          Close
        </button>

        <button :disabled="form.processing" class="btn btn-primary" type="submit" @click="updateLoan">
          Submit
        </button>
      </template>
    </b-modal>

    <div class="card rounded-3 mb-5 ">
      <div class="card-header">
        <div class="">
          <h2 class="card-title mb-0 fw-bold">
            Loans
          </h2>
        </div>

        <div class="card-actions">
          <div class="btn-list">
            <button class="btn btn-primary me-4" @click.prevent="openLoanModal">
              <i class="uil uil-plus me-1" /> Record Loan
            </button>
          </div>
        </div>
      </div>

      <VueTable ref="table" :api-mode="false" :data="employeeQualifications" :fields="fields" :load-on-start="false"
        :show-pagination="false" :show-per-page="false">




        <template #actions="props">
          <div class="dropdown">
            <button class="btn align-text-center py-1" data-bs-toggle="dropdown">
              <i class="uil uil-ellipsis-h" />
            </button>
            <div class="dropdown-menu dropdown-menu-end">


              <div class="dropdown-divider" />
              <a class="dropdown-item" href="#" @click="generateStatement(props.rowData)">
                <i class="uil uil-pen fs-3 me-2" /> Statement
              </a>
              <a class="dropdown-item" href="#" @click="editLoan(props.rowData)">
                <i class="uil uil-pen fs-3 me-2" /> Edit
              </a>

              <a class="dropdown-item text-danger" href="#" @click="deleteLoan(props.rowData)">
                <i class="uil  uil-trash-alt me-2"/> Delete
              </a>
            </div>
          </div>
        </template>
      </VueTable>
    </div>

  </div>
</template>

<style scoped></style>
