var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("flat-pickr", {
    ref: "flatpickr",
    attrs: {
      placeholder: _vm.placeholder,
      config: _vm.config,
      disabled: _vm.disabled,
    },
    on: { "on-change": _vm.onChange, "on-close": _vm.onClose },
    model: {
      value: _vm.date,
      callback: function ($$v) {
        _vm.date = $$v
      },
      expression: "date",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }