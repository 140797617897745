var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "card card-stacked" },
        [
          _c("div", { staticClass: "card-header" }, [
            _c("div", { staticClass: "input-icon me-2" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.moreParams.filter.search,
                    expression: "moreParams.filter.search",
                  },
                ],
                staticClass: "form-control bg-muted-lt rounded-2",
                attrs: { type: "text", placeholder: "Search" },
                domProps: { value: _vm.moreParams.filter.search },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.moreParams.filter,
                        "search",
                        $event.target.value
                      )
                    },
                    _vm.applyFilter,
                  ],
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("VueTable", {
            ref: "table",
            attrs: {
              "api-url": "/datatable/disciplinary-cases",
              fields: _vm.fields,
              "append-params": _vm.moreParams,
            },
            scopedSlots: _vm._u([
              {
                key: "name",
                fn: function (props) {
                  return [
                    _c("div", { staticClass: "media" }, [
                      _c(
                        "div",
                        { staticClass: "media-body align-self-center" },
                        [
                          _c("span", { staticClass: "text-sm" }, [
                            _vm._v(_vm._s(props.rowData.employee.name) + " "),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "actions",
                fn: function (props) {
                  return [
                    _c("div", { staticClass: "dropdown" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn align-text-top py-1",
                          attrs: { "data-bs-toggle": "dropdown" },
                        },
                        [_c("i", { staticClass: "uil uil-ellipsis-h" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "dropdown-menu dropdown-menu-end" },
                        [
                          _c("b-dropdown-header", [_vm._v("Action")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "dropdown-item" }, [
                            _c(
                              "a",
                              {
                                staticClass: "text-secondary",
                                attrs: {
                                  href: "#",
                                  title: "Download the payslip",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.showActionForm(props.rowData)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "uil uil-down-arrow mr-2",
                                }),
                                _vm._v(" resolve case"),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "resolve-form-modal",
            title: "Resolve Case",
            "hide-header-close": "",
            "footer-class": "d-flex justify-content-start",
            "modal-class": "modal-blur",
            "no-close-on-esc": "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn me-auto",
                      attrs: { type: "button", disabled: _vm.form.processing },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return cancel.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Close\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button", disabled: _vm.form.processing },
                      on: { click: _vm.resolveCase },
                    },
                    [_vm._v("\n        Submit\n      ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "div",
            { staticClass: "form-group mb-4" },
            [
              _c(
                "label",
                {
                  staticClass: "form-label",
                  attrs: { for: "physical_address" },
                },
                [_vm._v("Action")]
              ),
              _vm._v(" "),
              _c("v-select", {
                attrs: {
                  id: "item_id",
                  clearable: false,
                  reduce: (option) => option.id,
                  options: _vm.actionstypes,
                  label: "name",
                },
                model: {
                  value: _vm.form.action_type_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "action_type_id", $$v)
                  },
                  expression: "form.action_type_id",
                },
              }),
              _vm._v(" "),
              _vm.form.hasError("action_type_id")
                ? _c("span", {
                    staticClass: "text-danger",
                    domProps: {
                      textContent: _vm._s(_vm.form.getError("action_type_id")),
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "form-group mb-4" }, [
            _c(
              "label",
              { staticClass: "form-label", attrs: { for: "physical_address" } },
              [_vm._v("Action Details")]
            ),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.action_details,
                  expression: "form.action_details",
                },
              ],
              staticClass: "form-control",
              domProps: { value: _vm.form.action_details },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form, "action_details", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _vm.form.hasError("action_details")
              ? _c("span", {
                  staticClass: "text-danger",
                  domProps: {
                    textContent: _vm._s(_vm.form.getError("action_details")),
                  },
                })
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group mb-4" },
            [
              _c(
                "label",
                {
                  staticClass: "form-label",
                  attrs: { for: "physical_address" },
                },
                [_vm._v("Action Date")]
              ),
              _vm._v(" "),
              _c("date-picker", {
                attrs: {
                  name: "date_of_birth",
                  value: _vm.form.action_date,
                  "min-date": new Date(),
                },
                on: {
                  "on-change": (dateOb, dateStr) => {
                    _vm.form.action_date = dateStr
                  },
                },
              }),
              _vm._v(" "),
              _vm.form.hasError("action_date")
                ? _c("span", {
                    staticClass: "text-danger",
                    domProps: {
                      textContent: _vm._s(_vm.form.getError("action_date")),
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "input-icon-addon" }, [
      _c("i", { staticClass: "uil uil-search" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }