var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "benefit-modal",
            "body-class": "px-4",
            "content-class": "bg-white rounded-3",
            "footer-class":
              "d-flex justify-content-between bg-muted-lt border-top",
            "hide-header-close": "",
            "modal-class": "modal-blur",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-enforce-focus": "",
            title: "Benefit Form",
          },
          on: { hidden: _vm.cleanUp },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      attrs: { disabled: _vm.form.processing, type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return cancel.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n        Close\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { disabled: _vm.form.processing, type: "submit" },
                      on: { click: _vm.addBenefit },
                    },
                    [_vm._v("\n        Submit\n      ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "div",
            { staticClass: "form-group mb-4" },
            [
              _c(
                "label",
                { staticClass: "form-label", attrs: { for: "item_id" } },
                [_vm._v("Benefit")]
              ),
              _vm._v(" "),
              _c("v-select", {
                attrs: {
                  id: "item_id",
                  clearable: false,
                  reduce: (option) => option.id,
                  options: _vm.benefits,
                  label: "name",
                },
                model: {
                  value: _vm.form.benefit_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "benefit_id", $$v)
                  },
                  expression: "form.benefit_id",
                },
              }),
              _vm._v(" "),
              _vm.form.errors.has("benefit_id")
                ? _c("span", {
                    staticClass: "text-danger",
                    domProps: {
                      textContent: _vm._s(_vm.form.errors.first("benefit_id")),
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "form-group mb-4" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { staticClass: "form-label", attrs: { for: "" } }, [
                  _vm._v("Amount"),
                ]),
                _vm._v(" "),
                _c("medic-money", {
                  attrs: { value: _vm.form.amount },
                  on: {
                    input: (value) => {
                      _vm.form.amount = value
                    },
                  },
                }),
                _vm._v(" "),
                _vm.form.hasError("amount")
                  ? _c("div", {
                      staticClass: "text-danger",
                      domProps: {
                        textContent: _vm._s(_vm.form.getError("amount")),
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.form.processing
            ? _c("div", { staticClass: "progress mb-2" }, [
                _c("div", { staticClass: "progress" }, [
                  _c("div", {
                    staticClass:
                      "progress-bar progress-bar-indeterminate bg-green",
                  }),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "edit-benefit-modal",
            "body-class": "px-4",
            "content-class": "bg-white rounded-3",
            "footer-class":
              "d-flex justify-content-between bg-muted-lt border-top",
            "hide-header-close": "",
            "modal-class": "modal-blur",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-enforce-focus": "",
            title: "Edit Benefit Form",
          },
          on: { hidden: _vm.cleanUp },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      attrs: { disabled: _vm.form.processing, type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return cancel.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n        Close\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { disabled: _vm.form.processing, type: "submit" },
                      on: { click: _vm.updateContribution },
                    },
                    [_vm._v("\n        Submit\n      ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "div",
            { staticClass: "form-group mb-4" },
            [
              _c(
                "label",
                { staticClass: "form-label", attrs: { for: "item_id" } },
                [_vm._v("Benefit")]
              ),
              _vm._v(" "),
              _c("v-select", {
                attrs: {
                  id: "item_id",
                  clearable: false,
                  reduce: (option) => option.id,
                  options: _vm.benefits,
                  label: "name",
                },
                model: {
                  value: _vm.form.benefit_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "benefit_id", $$v)
                  },
                  expression: "form.benefit_id",
                },
              }),
              _vm._v(" "),
              _vm.form.errors.has("benefit_id")
                ? _c("span", {
                    staticClass: "text-danger",
                    domProps: {
                      textContent: _vm._s(_vm.form.errors.first("benefit_id")),
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "form-group mb-4" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { staticClass: "form-label", attrs: { for: "" } }, [
                  _vm._v("Amount"),
                ]),
                _vm._v(" "),
                _c("medic-money", {
                  attrs: { value: _vm.form.amount },
                  on: {
                    input: (value) => {
                      _vm.form.amount = value
                    },
                  },
                }),
                _vm._v(" "),
                _vm.form.hasError("amount")
                  ? _c("div", {
                      staticClass: "text-danger",
                      domProps: {
                        textContent: _vm._s(_vm.form.getError("amount")),
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.form.processing
            ? _c("div", { staticClass: "progress mb-2" }, [
                _c("div", { staticClass: "progress" }, [
                  _c("div", {
                    staticClass:
                      "progress-bar progress-bar-indeterminate bg-green",
                  }),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card rounded-3 mb-5" },
        [
          _c("div", { staticClass: "card-header" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "card-actions" }, [
              _c("div", { staticClass: "btn-list" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary me-4",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.openContributionModal.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "uil uil-plus me-1" }),
                    _vm._v(" Add Benefit\n          "),
                  ]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("VueTable", {
            ref: "table",
            attrs: {
              "api-mode": false,
              data: _vm.employeeContributions,
              fields: _vm.fields,
              "load-on-start": false,
              "show-pagination": false,
              "show-per-page": false,
            },
            scopedSlots: _vm._u([
              {
                key: "actions",
                fn: function (props) {
                  return [
                    _c("div", { staticClass: "dropdown" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn align-text-center py-1",
                          attrs: { "data-bs-toggle": "dropdown" },
                        },
                        [_c("i", { staticClass: "uil uil-ellipsis-h" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "dropdown-menu dropdown-menu-end" },
                        [
                          _c("div", { staticClass: "dropdown-divider" }),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  return _vm.editContribution(props.rowData)
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "uil uil-pen fs-3 me-2" }),
                              _vm._v(" Edit\n          "),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item text-danger",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteContribution(props.rowData)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "uil uil-trash-alt me-2",
                              }),
                              _vm._v(" Delete\n          "),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", {}, [
      _c("h2", { staticClass: "card-title mb-0 fw-bold" }, [
        _vm._v("\n          Benefits\n        "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }