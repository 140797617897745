<script>
import { defineComponent } from 'vue'
import { toPrice } from '@plugins/money'

export default defineComponent({
  name: 'Relief',
  props: {
    reliefs: {
      type: Array,
      default: () => [],
    },
    permissions: {
      type: Array,
      default: () => [],
    },
    employee: {
      type: Object,
      default: () => {},
    },
  },

  data () {
    return {

      fields: [
        {
          name: 'relief.name',
          title: 'Name',
          titleClass: 'ps-3',
          dataClass: 'ps-3',
          width: '45%',
        },
        {
          name: 'amount',
          title: 'Amount',
          callback: function (value) {
            if (value < 0) {
              return '(' + toPrice(Math.abs(value)).toFormat('0,0.00') + ')'
            }
            return toPrice(value).toFormat('0,0.00')
          },
          titleClass: 'text-end pe-3',
          dataClass: 'text-end pe-3',
          width: '10%',
        },

        {
          name: '__slot:actions',
          dataClass: 'txt-center',
          width: '5%',
        },
      ],



      form: new window.Form({
        id:null,
        employee_payroll_id: this.employee.id,
        relief_id: null,
        amount:null,
      }),

      enableForm: false,

      employeeReliefs: [],
    }
  },


  mounted () {
    this.retrieveEmployeeReliefs()
  },

  methods: {
    retrieveEmployeeReliefs () {
      this.$httpClient.get('/payroll/reliefs/' + this.employee.hashid)
          .then(({ data }) => {
            this.employeeReliefs = data;
          }).catch(() => {
        this.$toast.error('There was a problem retrieving the Employee reliefs')
      })
    },
    deleteRelief (rowData){
      this.form.id = rowData.id
      this.$toast.question('Are you sure?', 'You want to delete').then(() => {
        this.$httpClient.delete('/payroll/reliefs/' + this.form.id )
        .then((error) => {
          this.$toast.success('deleted')
          this.cleanUp()
          this.vuetable.reload()
        }).catch((error) => {
          this.$toast.error(error.response.data)
        })


      })
    },
    openReliefModal () {
      this.$bvModal.show('relief-modal')
    },


    addRelief () {
      this.form.post('/payroll/reliefs')
          .then(() => {
            this.$toast.success('Relief has been added')
            this.cleanUp()
          })
          .catch((error) => {
            this.form.processing = false
            this.form.errors.record(error.response.data.errors)
          })
    },
    editRelief(data){
      this.form.populate(data)
      this.$bvModal.show('edit-relief-modal')
    },

    updateRelief(){
      this.form.patch('/payroll/reliefs/' + this.form.id)
          .then(() => {
            this.$toast.success('The changes have been saved')
            this.cleanUp()
            this.$bvModal.hide('edit-relief-modal')
            this.vuetable.reload()


          })
          .catch()
    },
    cleanUp () {
      this.form.reset()
      this.retrieveEmployeeReliefs()
    },

  },

})
</script>

<template>

  <div>

    <b-modal
        id="relief-modal"
        body-class="px-4"
        content-class="bg-white rounded-3"
        footer-class="d-flex justify-content-between bg-muted-lt border-top"
        hide-header-close
        modal-class="modal-blur"
        no-close-on-backdrop
        no-close-on-esc
        no-enforce-focus
        title="Employee Relief Form"
        @hidden="cleanUp"
    >
      <div class="form-group mb-4">
        <label class="form-label" for="item_id">Relief</label>
        <v-select
            id="item_id"
            v-model="form.relief_id"
            :clearable="false"
            :reduce="(option) => option.id"
            :options="reliefs"
            label="name"
        >

        </v-select>
        <span
            v-if="form.errors.has('relief_id')"
            class="text-danger"
            v-text="form.errors.first('relief_id')"
        />
      </div>
      <div class="form-group mb-4">
        <div class="form-group">
          <label for="" class="form-label">Amount</label>
          <medic-money
              :value="form.amount"
              @input="(value) => {
                                            form.amount = value
                                        }"></medic-money>

          <div class="text-danger"
               v-if="form.hasError('amount')"
               v-text="form.getError('amount')">
          </div>
        </div>
      </div>
      <div v-if="form.processing" class="progress mb-2">
        <div class="progress">
          <div class="progress-bar progress-bar-indeterminate bg-green"/>
        </div>
      </div>

      <template #modal-footer="{cancel}">
        <button :disabled="form.processing" class="btn" type="button" @click.prevent="cancel">
          Close
        </button>

        <button
            :disabled="form.processing" class="btn btn-primary"
            type="submit"
            @click="addRelief"
        >
          Submit
        </button>
      </template>
    </b-modal>
    <b-modal
        id="edit-relief-modal"
        body-class="px-4"
        content-class="bg-white rounded-3"
        footer-class="d-flex justify-content-between bg-muted-lt border-top"
        hide-header-close
        modal-class="modal-blur"
        no-close-on-backdrop
        no-close-on-esc
        no-enforce-focus
        title="Edit Employee Relief Form"
        @hidden="cleanUp"
    >
      <div class="form-group mb-4">
        <label class="form-label" for="item_id">Relief</label>
        <v-select
            id="item_id"
            v-model="form.relief_id"
            :clearable="false"
            :reduce="(option) => option.id"
            :options="reliefs"
            label="name"
        >

        </v-select>
        <span
            v-if="form.errors.has('relief_id')"
            class="text-danger"
            v-text="form.errors.first('relief_id')"
        />
      </div>
      <div class="form-group mb-4">
        <div class="form-group">
          <label for="" class="form-label">Amount</label>
          <medic-money
              :value="form.amount"
              @input="(value) => {
                                            form.amount = value
                                        }"></medic-money>

          <div class="text-danger"
               v-if="form.hasError('amount')"
               v-text="form.getError('amount')">
          </div>
        </div>
      </div>
      <div v-if="form.processing" class="progress mb-2">
        <div class="progress">
          <div class="progress-bar progress-bar-indeterminate bg-green"/>
        </div>
      </div>

      <template #modal-footer="{cancel}">
        <button :disabled="form.processing" class="btn" type="button" @click.prevent="cancel">
          Close
        </button>

        <button
            :disabled="form.processing" class="btn btn-primary"
            type="submit"
            @click="updateRelief"
        >
          Submit
        </button>
      </template>
    </b-modal>


    <div class="card rounded-3 mb-5 ">
      <div class="card-header">
        <div class="">
          <h2 class="card-title mb-0 fw-bold">
            Relief
          </h2>
        </div>

        <div class="card-actions">
          <div class="btn-list">
            <button class="btn btn-primary me-4" @click.prevent="openReliefModal">
              <i class="uil uil-plus me-1"/> Add relief
            </button>
          </div>
        </div>
      </div>

      <VueTable
          ref="table"
          :api-mode="false"
          :data="employeeReliefs"
          :fields="fields"
          :load-on-start="false"
          :show-pagination="false"
          :show-per-page="false"
      >

        <template #actions="props">
          <div class="dropdown">
            <button
                class="btn align-text-center py-1"
                data-bs-toggle="dropdown"
            >
              <i class="uil uil-ellipsis-h"/>
            </button>
            <div class="dropdown-menu dropdown-menu-end">

              <div class="dropdown-divider"/>
              <a class="dropdown-item" href="#" @click="editRelief(props.rowData)">
                <i class="uil uil-pen fs-3 me-2"/> Edit
              </a>
              <a class="dropdown-item text-danger" href="#" @click="deleteRelief(props.rowData)">
                <i class="uil  uil-trash-alt me-2"/> Delete
              </a>
            </div>
          </div>
        </template>
      </VueTable>
    </div>

  </div>
</template>

<style scoped>

</style>
