<script>
export default {
  props: {
    leave_types: {
      type: Array,
      required: true,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      employees: [],
      assignedLeaves:[],
      fields: [
        {
          name: "employee_leave_type.employee.name",
          title: "Employee",
          titleClass: "font-weight-bold",
          dataClass: "ps-3",
        },
        {
          name: "employee_leave_type.leave_type.name",
          title: "Leave Type",
          titleClass: "font-weight-bold",
          dataClass: "ps-3",
        },
        {
          name: "start_date",
          title: "Start Date",
          titleClass: "font-weight-bold",
          dataClass: "ps-3",
        },
        {
          name: "end_date",
          title: "End Date",
          titleClass: "font-weight-bold",
          dataClass: "ps-3",
        }, {
          name: "days",
          title: "Days",
          titleClass: "font-weight-bold",
          dataClass: "ps-3",
        },
        {
          name: "approved",
          title: "STATUS",
          titleClass: "font-weight-bold",
          dataClass: "ps-3",
          callback: function (value) {
            if (value) {
              return "Approved";
            }

            return "Not approved";
          },
        },
        {
          name: "__slot:actions",
          width: "15%",
          titleClass: "text-center",
          dataClass: "text-right",
        },
      ],

      moreParams: {
        filter: {
          search: "",
          approved: false,
          supervisor_approved: false,
          supervisor_rejected: false,
          rejected: false,
          supervisor:true,
        },
      },
      leaveBalance: null,
      form: new window.Form({
        id: null,
        employee_id: "",
        leave_type_id: '',
        employee_leave_type_id: '',
        reason: '',
        start_date: '',
        end_date: '',
        leaveRequest:null,
        approved: true,
        return_date: '',
      }),
    };
  },

  computed: {
    vuetable() {
      return this.$refs.table.$refs.vuetable;
    },
  },
  mounted() {
    this.retrieveEmployees();
  },
  watch:{
    'form.leave_type_id': function (id) {
      this.getLeaveBalance()
    },
    'form.employee_id': function (id) {
      this.getAssgned()
    },
  },
  methods: {
    getLeaveBalance() {
      this.$httpClient.get('/employee/leave-balance', {
        params: {
          employee_id: this.form.employee_id,
          leave_type_id: this.form.leave_type_id
        }
      })
          .then(({data})=>{
            this.leaveBalance = data
          }).catch(()=>{

      })
    },
    getAssgned() {
      this.$httpClient.get('/datatable/employee-leave-types', {
        params: {
          filter: {
            employee_assigned_leaves: this.form.employee_id
          }
        }
      })
          .then(({ data }) => {
            this.assignedLeaves = data.data;
          }).catch(() => {
        this.$toast.error('There was a problem retrieving the Employee assigned leaves')
      })
    },
    retrieveEmployees() {
      this.$httpClient
        .get("/datatable/employees/",{
          params:{
            filter: {
              activeEmployee:true,
              supervisor:true,
            }
          }
        })
        .then(({ data }) => {
          this.employees = data.data;
        })
        .catch(() => {
          this.$toast.error(
            "There was a problem retrieving the Employees"
          );
        });
    },
    showCreateForm() {
      this.$bvModal.show("create-modal");
    },
    showRejectForm(dataRow) {
      this.form.leaveRequest = dataRow;
      this.$bvModal.show("reject-reason");
    },

    showUpdateForm(rowData) {
      this.selectedDepartment = rowData;
      this.viewEditForm = true;
    },
    // for supervisor
    approveLeave(leaveRequest) {
      this.form
        .patch("/employee/supervisor/leave-request-approval/" + leaveRequest.id)
        .then(() => {
          this.vuetable.reload();
          this.$toast.success("The leave approved");
        })
        .catch((error) => {
          this.$toast.error(error.response.data);
        });
    },
    rejectLeave() {
      this.$toast.question('Are you sure?', 'You want to reject this leave request').then(() => {
        this.form
            .patch("/employee/supervisor/leave-request-reject/" + this.form.leaveRequest.id)
            .then(() => {
              this.vuetable.reload();
              this.$toast.success("The leave rejected");
              this.$bvModal.hide("reject-reason")
            })
            .catch((error) => {
              this.$toast.error(error.response.data);
            });
      })

    },
    createAssignLeave() {
      this.form
        .post("/employee/leaves")
        .then(() => {
          this.$bvModal.hide("create-modal");
          this.$toast.success("The leave has been issued");
          this.vuetable.reload();
        })
        .catch();
    },
    giveLeave() {
      this.form
          .post("/employee/supervisor-give-leave")
          .then(() => {
            this.$bvModal.hide("create-modal");
            this.$toast.success("The leave has been issued");
            this.vuetable.reload();
          })
          .catch( (error) => {
            if (error.response && error.response.data && error.response.data.errors) {
              this.form.errors.record(error.response.data.errors);
            } else {
              this.$toast.error(error.response.data)
            }
          })
    },
    showEditForm(rowData) {
      this.form.populate(rowData);
      this.form.id = rowData.id;

      this.$bvModal.show("update-modal");
    },

    editLeave() {
      this.form
        .patch("/employee/leaves/" + this.form.id)
        .then(() => {
          this.vuetable.reload();
          this.$bvModal.hide("update-modal");
          this.$toast.success("The changes have been saved");
        })
        .catch();
    },

    applyFilter() {
      this.vuetable.reload();
    },

    cleanUp() {
      this.form.reset();
    },
  },
};
</script>

<template>
  <div>
    <div class="card card-stacked">
      <div class="card-header">
        <div class="input-icon me-2">
          <span class="input-icon-addon">
            <i class="uil uil-search"></i>
          </span>
          <input type="text" class="form-control bg-muted-lt rounded-2" placeholder="Search employee"
            @input="applyFilter" v-model="moreParams.filter.name" />
        </div>
        <div class="card-actions">
          <button class="btn btn-primary" @click="showCreateForm">
            <i class="uil uil-plus me-1"></i>Give Leave
          </button>
        </div>
      </div>
      <VueTable ref="table" api-url="/datatable/leave-requests" :fields="fields" :append-params="moreParams">
        <template v-slot:name="props">
          <div class="media">
            <div class="media-body align-self-center">
              <span class="text-sm">{{ props.rowData.name }}
              </span>
              <div class="font-size-12 text-purple-300">
                {{
              props.rowData.stores
                ? props.rowData.stores.name
                : ""
            }}
                <span v-if="props.rowData.auto_post_request" class="badge badge-soft-success shadow-sm">Auto Post</span>
                <span v-else class="badge badge-soft-secondary rounded px-1 p-1 shadow-sm border">Manual Post</span>
              </div>
            </div>
          </div>
        </template>

        <template v-slot:actions="props">
          <div class="dropdown">
            <button class="btn align-text-top py-1" data-bs-toggle="dropdown">
              <i class="uil uil-ellipsis-h"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <!--              <a class="dropdown-item" href="#" @click="showEditForm(props.rowData)">-->
              <!--                <i class="uil uil-pen fs-3 me-2"></i> Edit-->
              <!--              </a>-->
              <a class="dropdown-item" href="#" @click="approveLeave(props.rowData)">
                <i class="uil uil-pen fs-3 me-2"></i> Approve
              </a>
              <a class="dropdown-item" href="#" @click="showRejectForm(props.rowData)">
                 Reject
              </a>
            </div>
          </div>
        </template>
      </VueTable>
    </div>

    <b-modal id="create-modal" title="Give a Leave" hide-header-close size="lg"
             footer-class="d-flex justify-content-start" modal-class="modal-blur" no-close-on-esc>
      <form @submit.prevent="createAssignLeave" id="createForm">
        <div class="row">
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Employee Name</label>
              <v-select id="gender_id" v-model="form.employee_id" name="department_id" :options="employees" label="name"
                        :reduce="(option) => option.id" :clearable="false" />

              <span v-if="form.hasError('employee_id')" class="text-danger" v-text="form.getError('employee_id')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Leave Type</label>
              <v-select id="gender_id" v-model="form.leave_type_id" name="leave_type_id" :options="assignedLeaves"
                        label="leave_type_id" :reduce="option => option.id" :clearable="false">
                <template v-slot:option="option">
                  <li class="media mb-1">
                    <div class="media-body">
                      <div class="d-flex justify-content-between">
                        <span>{{ option.leave_type.name }}</span>
                      </div>
                    </div>
                  </li>
                </template>
                <template v-slot:selected-option="option">
                  <li class="media mb-1">
                    <div class="media-body">
                      <div class="d-flex justify-content-between">
                        <span>{{ option.leave_type.name }}</span>
                      </div>
                    </div>
                  </li>
                </template>
              </v-select>

              <span v-if="form.hasError('leave_type_id')" class="text-danger" v-text="form.getError('leave_type_id')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Start Date</label>
              <date-picker name="date_of_birth" :value="form.start_date"  @on-change="(dateOb, dateStr) => {
                form.start_date = dateStr;
              }
              " />

              <span v-if="form.hasError('start_date')" class="text-danger" v-text="form.getError('start_date')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">End Date</label>
              <date-picker name="date_of_birth" :value="form.end_date" @on-change="(dateOb, dateStr) => {
                form.end_date = dateStr;
              }
              " />

              <span v-if="form.hasError('end_date')" class="text-danger" v-text="form.getError('end_date')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Return Date</label>
              <date-picker name="return_date" :value="form.return_date" @on-change="(dateOb, dateStr) => {
                form.return_date = dateStr;
              }
              " />

              <span v-if="form.hasError('return_date')" class="text-danger" v-text="form.getError('return_date')">
              </span>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="mb-3">
              <lable for="reason" class="form-label">Reason for leave</lable>
              <textarea class="form-control" v-model="form.reason"></textarea>
              <span v-if="form.hasError('reason')" class="text-danger" v-text="form.getError('reason')">
              </span>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="mb-3">
              <label class="form-check form-switch">
                <input v-model="form.approved" class="form-check-input" type="checkbox">
                <span class="form-check-label">Approve</span>
              </label>
            </div>
          </div>
          <span v-if="leaveBalance" class="text-warning">leave balance: {{ leaveBalance.balance }} day(s)</span>
        </div>

        <div class="progress mb-2" v-if="form.processing">
          <div class="progress">
            <div class="progress-bar progress-bar-indeterminate bg-green"></div>
          </div>
        </div>
      </form>

      <template #modal-footer="{ cancel }">
        <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">
          Close
        </button>
        <button type="button" class="btn btn-primary" :disabled="form.processing" @click.prevent="giveLeave">
          Submit
        </button>
      </template>
    </b-modal>

    <b-modal id="update-modal" title="Edit Leave" hide-header-close size="lg"
      footer-class="d-flex justify-content-start" modal-class="modal-blur" no-close-on-esc>
      <form @submit.prevent="editLeave" id="createForm">
        <div class="row">
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Employee Name</label>
              <v-select id="gender_id" v-model="form.employee_id" name="department_id" :options="employees" label="name"
                :reduce="(option) => option.id" :clearable="false" />

              <span v-if="form.hasError('employee_id')" class="text-danger" v-text="form.getError('employee_id')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Leave Type</label>
              <v-select id="gender_id" v-model="form.leave_type_id" name="leave_type_id" :options="leave_types"
                label="name" :reduce="(option) => option.id" :clearable="false" />

              <span v-if="form.hasError('leave_type_id')" class="text-danger" v-text="form.getError('leave_type_id')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Start Date</label>
              <date-picker name="date_of_birth" :value="form.start_date" :max-date="new Date()" @on-change="(dateOb, dateStr) => {
                form.start_date = dateStr;
              }
              " />

              <span v-if="form.hasError('start_date')" class="text-danger" v-text="form.getError('start_date')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">End Date</label>
              <date-picker name="date_of_birth" :value="form.end_date" :max-date="new Date()" @on-change="(dateOb, dateStr) => {
                form.end_date = dateStr;
              }
              " />

              <span v-if="form.hasError('end_date')" class="text-danger" v-text="form.getError('end_date ')">
              </span>
            </div>
          </div>
        </div>

        <div class="progress mb-2" v-if="form.processing">
          <div class="progress">
            <div class="progress-bar progress-bar-indeterminate bg-green"></div>
          </div>
        </div>
      </form>
      <template #modal-footer="{ cancel }">
        <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">
          Close
        </button>
        <button type="button" class="btn btn-primary" :disabled="form.processing" @click.prevent="editLeave">
          Submit
        </button>
      </template>
    </b-modal>
    <b-modal id="reject-reason" title="Reason for Reject" hide-header-close size="sm"
      footer-class="d-flex justify-content-start" modal-class="modal-blur" no-close-on-esc>
      <form @submit.prevent="editLeave" id="createForm">
        <div class="row">
          <div class="col-lg-12">
            <div class="mb-3">
              <label for="name" class="form-label">Reject Reason</label>
              <textarea class="form-control" v-model="form.reason"></textarea>
              <span v-if="form.hasError('employee_id')" class="text-danger" v-text="form.getError('employee_id')">
              </span>
            </div>
          </div>
        </div>

        <div class="progress mb-2" v-if="form.processing">
          <div class="progress">
            <div class="progress-bar progress-bar-indeterminate bg-green"></div>
          </div>
        </div>
      </form>
      <template #modal-footer="{ cancel }">
        <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">
          Close
        </button>
        <button type="button" class="btn btn-primary" :disabled="form.processing" @click.prevent="rejectLeave">
          Submit
        </button>
      </template>
    </b-modal>
  </div>
</template>
