<script>
import { defineComponent } from 'vue'


export default defineComponent({
  name: 'Qualification',
  props: {
    employee: {
      type: Object,
      default: () => {},
    },
    permissions: {
      type: Array,
      default: () => [],
    },
    pensions: {
      type: Array,
      default: () => [],
    },
  },

  data () {
    return {

      fields: [
        {
          name: 'qualification',
          title: 'Qualification',
          titleClass: ' text-end pe-3',
          dataClass: ' text-center pe-3',
          width: '8%',
        },
        {
          name: 'institution',
          title: 'Institution',
          titleClass: 'ps-3',
          dataClass: 'ps-3',
          width: '45%',
        },

        {
          name: 'date',
          title: 'Date',
          titleClass: 'text-center',
          dataClass: 'text-center',
          width: '5%',
        },

        // {
        //   name: '__slot:status',
        //   title: 'STATUS',
        //   dataClass: 'ps-1',
        //   titleClass: 'ps-1',
        //   width: '10%',
        // },
        {
          name: '__slot:actions',
          dataClass: 'txt-center',
          width: '5%',
        },
      ],

      employeeQualifications: [],

      billToReverse: '',

      form: new window.Form({
        id: null,
        employee_id: this.employee.id,
        date: '',
        institution:'',
        qualification: '',
      }),
    }
  },
  mounted () {
    this.retrieveEmployeeQualifications()
  },

  methods: {
    retrieveEmployeeQualifications () {
      this.$httpClient.get('/employee/qualifications/' + this.employee.id)
          .then(({ data }) => {
            this.employeeQualifications = data;
          }).catch(() => {
        this.$toast.error('There was a problem retrieving employee qualifications')
      })
    },
    deleteQualification (rowData){
      this.form.id = rowData.id
      this.$toast.question('Are you sure?', 'You want to delete').then(() => {
        this.$httpClient.delete('/employee/qualifications/' + this.form.id )
        .then((error) => {
          this.$toast.success('deleted')
          this.cleanUp()
          this.vuetable.reload()
        }).catch((error) => {
          this.$toast.error(error.response.data)
        })

        
      })
    },
    openQualificationModal () {
      this.$bvModal.show('qualification-modal')
    },


    addQualification () {

      this.form.post('/employee/qualifications')
          .then(() => {
            this.$toast.success('Qualification has been added')
            this.cleanUp()
            this.$bvModal.hide('qualification-modal')
          })
          .catch((error) => {
            this.form.processing = false
            this.form.errors.record(error.response.data.errors)
          })
    },
    editQualification(data){
      this.form.populate(data);
      this.$bvModal.show('edit-qualification-modal')
    },
    updateQualification(){
      this.form.patch('/employee/qualifications/' + this.form.id)
          .then(() => {
            this.$toast.success('The changes have been saved')

            this.cleanUp()
            this.$bvModal.hide('edit-qualification-modal')
            this.vuetable.reload()
          })
          .catch()
    },
    cleanUp () {
      this.form.reset();
      this.retrieveEmployeeQualifications()
    },

  },

})
</script>

<template>

  <div>

    <b-modal
        id="qualification-modal"
        body-class="px-4"
        content-class="bg-white rounded-3"
        footer-class="d-flex justify-content-between bg-muted-lt border-top"
        hide-header-close
        modal-class="modal-blur"
        no-close-on-backdrop
        no-close-on-esc
        no-enforce-focus
        title="Qualification Form"
        @hidden="cleanUp"
    >
      <div class="form-group mb-4">
        <label for="physical_address" class="form-label">Institution</label>
        <input
            id="physical_address"
            v-model="form.institution"
            type="text"
            class="form-control"
        >

        <span
            v-if="form.hasError('institution')"
            class="text-danger"
            v-text="form.getError('institution')"
        />
      </div>
      <div class="form-group mb-4">
        <label for="physical_address" class="form-label">Qualification</label>
        <input
            id="physical_address"
            v-model="form.qualification"
            type="text"
            class="form-control"
        >

        <span
            v-if="form.hasError('qualification')"
            class="text-danger"
            v-text="form.getError('qualification')"
        />
      </div>
      <div class="form-group mb-4">
        <label class="form-label">Date</label>
        <div>
          <date-picker
              name="date_of_birth"
              formclass="form-control bg-white"
              :value="form.date"
              :max-date="false"
              @on-change="(dateOb, dateStr) => {
                    form.date = dateStr
                  }"
          />

          <span
              v-if="form.hasError('date')"
              class="text-danger"
              v-text="form.getError('date')"
          />
        </div>
      </div>

      <div v-if="form.processing" class="progress mb-2">
        <div class="progress">
          <div class="progress-bar progress-bar-indeterminate bg-green"/>
        </div>
      </div>

      <template #modal-footer="{cancel}">
        <button :disabled="form.processing" class="btn" type="button" @click.prevent="cancel">
          Close
        </button>

        <button
            :disabled="form.processing" class="btn btn-primary"
            type="submit"
            @click="addQualification"
        >
          Submit
        </button>
      </template>
    </b-modal>
    <b-modal
        id="edit-qualification-modal"
        body-class="px-4"
        content-class="bg-white rounded-3"
        footer-class="d-flex justify-content-between bg-muted-lt border-top"
        hide-header-close
        modal-class="modal-blur"
        no-close-on-backdrop
        no-close-on-esc
        no-enforce-focus
        title="Edit Qualification Form"
        @hidden="cleanUp"
    >
      <div class="form-group mb-4">
        <label for="physical_address" class="form-label">Institution</label>
        <input
            id="physical_address"
            v-model="form.institution"
            type="text"
            class="form-control"
        >

        <span
            v-if="form.hasError('institution')"
            class="text-danger"
            v-text="form.getError('institution')"
        />
      </div>
      <div class="form-group mb-4">
        <label for="physical_address" class="form-label">Qualification</label>
        <input
            id="physical_address"
            v-model="form.qualification"
            type="text"
            class="form-control"
        >

        <span
            v-if="form.hasError('qualification')"
            class="text-danger"
            v-text="form.getError('qualification')"
        />
      </div>
      <div class="form-group mb-4">
        <label class="form-label">Date</label>
        <div>
          <date-picker
              name="date_of_birth"
              formclass="form-control bg-white"
              :value="form.date"
              :max-date="false"
              @on-change="(dateOb, dateStr) => {
                    form.date = dateStr
                  }"
          />

          <span
              v-if="form.hasError('date')"
              class="text-danger"
              v-text="form.getError('date')"
          />
        </div>
      </div>
      <div v-if="form.processing" class="progress mb-2">
        <div class="progress">
          <div class="progress-bar progress-bar-indeterminate bg-green"/>
        </div>
      </div>

      <template #modal-footer="{cancel}">
        <button :disabled="form.processing" class="btn" type="button" @click.prevent="cancel">
          Close
        </button>

        <button
            :disabled="form.processing" class="btn btn-primary"
            type="submit"
            @click="updateQualification"
        >
          Submit
        </button>
      </template>
    </b-modal>

    <div class="card rounded-3 mb-5 ">
      <div class="card-header">
        <div class="">
          <h2 class="card-title mb-0 fw-bold">
            Qualifications
          </h2>
        </div>

        <div class="card-actions">
          <div class="btn-list">
            <button class="btn btn-primary me-4" @click.prevent="openQualificationModal">
              <i class="uil uil-plus me-1"/> Add Qualification
            </button>
          </div>
        </div>
      </div>

      <VueTable
          ref="table"
          :api-mode="false"
          :data="employeeQualifications"
          :fields="fields"
          :load-on-start="false"
          :show-pagination="false"
          :show-per-page="false"
      >




        <template #actions="props">
          <div class="dropdown">
            <button
                class="btn align-text-center py-1"
                data-bs-toggle="dropdown"
            >
              <i class="uil uil-ellipsis-h"/>
            </button>
            <div class="dropdown-menu dropdown-menu-end">


              <div class="dropdown-divider"/>

              <a class="dropdown-item" href="#" @click="editQualification(props.rowData)">
                <i class="uil uil-pen fs-3 me-2"/> Edit
              </a>
              <a class="dropdown-item text-danger" href="#" @click="deleteQualification(props.rowData)">
                <i class="uil  uil-trash-alt me-2"/> Delete
              </a>
            </div>
          </div>
        </template>
      </VueTable>
    </div>

  </div>
</template>

<style scoped>

</style>
