var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row mb-4 gx-4" }, [
        _c("div", { staticClass: "col-xl-5" }, [
          _vm.showPatientEditForm
            ? _c("div", { staticClass: "card card-stacked" }, [
                _c("div", { staticClass: "card-header" }, [
                  _c("div", [
                    _c("h3", { staticClass: "card-title" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.employee.name) +
                          "\n              "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "p",
                      { staticClass: "card-subtitle small text-indigo" },
                      [
                        _vm._v(
                          "\n                Registered " +
                            _vm._s(
                              _vm._f("dATE_MONTH_YEAR_HOUR_MINUTES")(
                                _vm.employee.employed_at
                              )
                            ) +
                            "\n              "
                        ),
                      ]
                    ),
                    _vm.employee.employment_status === 1
                      ? _c("div", { staticClass: "text-warning" }, [
                          _vm._v("Suspended"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.employee.employment_status === 2
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v("Terminated"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("p"),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-body px-4" }, [
                  _c("div", { staticClass: "form-group mb-4" }, [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "name" } },
                      [_vm._v("Employee No")]
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.employeeForm.staff_number,
                            expression: "employeeForm.staff_number",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { id: "name", type: "text" },
                        domProps: { value: _vm.employeeForm.staff_number },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.employeeForm,
                              "staff_number",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.employeeForm.hasError("staff_number")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.employeeForm.getError("staff_number")
                              ),
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group mb-4" }, [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "name" } },
                      [_vm._v("Full Name")]
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.employeeForm.name,
                            expression: "employeeForm.name",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { id: "name", type: "text" },
                        domProps: { value: _vm.employeeForm.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.employeeForm,
                              "name",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.employeeForm.hasError("name")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.employeeForm.getError("name")
                              ),
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-xl-6" }, [
                      _c("div", { staticClass: "form-group mb-4" }, [
                        _vm._m(0),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("date-picker", {
                              attrs: {
                                name: "date_of_birth",
                                value: _vm.employeeForm.dob,
                                "max-date": new Date(),
                              },
                              on: {
                                "on-change": (dateOb, dateStr) => {
                                  _vm.employeeForm.dob = dateStr
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.employeeForm.hasError("dob")
                              ? _c("span", {
                                  staticClass: "text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.employeeForm.getError("dob")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-6" }, [
                      _c("div", { staticClass: "form-group mb-4" }, [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("Gender "),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.employeeForm.gender,
                              expression: "employeeForm.gender",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "identification_value", type: "text" },
                          domProps: { value: _vm.employeeForm.gender },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.employeeForm,
                                "gender",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.employeeForm.hasError("gender")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.employeeForm.getError("gender")
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group mb-4" }, [
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "identification_value" },
                      },
                      [_vm._v("Identification Number ")]
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.employeeForm.identification_number,
                            expression: "employeeForm.identification_number",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { id: "identification_value", type: "text" },
                        domProps: {
                          value: _vm.employeeForm.identification_number,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.employeeForm,
                              "identification_number",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.employeeForm.hasError("identification_number")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.employeeForm.getError(
                                  "identification_number"
                                )
                              ),
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row gx-4" }, [
                    _c("div", { staticClass: "col-xl-6" }, [
                      _c("div", { staticClass: "form-group mb-4" }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "telephone" },
                          },
                          [_vm._v("Mobile Number ")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.employeeForm.telephone,
                              expression: "employeeForm.telephone",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "telephone", max: "10", type: "tel" },
                          domProps: { value: _vm.employeeForm.telephone },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.employeeForm,
                                "telephone",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.employeeForm.hasError("telephone")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.employeeForm.getError("telephone")
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-6" }, [
                      _c("div", { staticClass: "form-group mb-4" }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "physical_address" },
                          },
                          [_vm._v("Email ")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.employeeForm.email,
                              expression: "employeeForm.email",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "physical_address", type: "text" },
                          domProps: { value: _vm.employeeForm.email },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.employeeForm,
                                "email",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.employeeForm.hasError("email")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.employeeForm.getError("email")
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-group mb-4" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "form-label",
                              attrs: { for: "plan_type" },
                            },
                            [_vm._v("Is disabled")]
                          ),
                          _vm._v(" "),
                          _c("v-select", {
                            attrs: {
                              id: "scheme_id",
                              label: "name",
                              options: _vm.is_disabled,
                              reduce: (option) => option.value,
                              clearable: false,
                            },
                            model: {
                              value: _vm.employeeForm.is_disabled,
                              callback: function ($$v) {
                                _vm.$set(_vm.employeeForm, "is_disabled", $$v)
                              },
                              expression: "employeeForm.is_disabled",
                            },
                          }),
                          _vm._v(" "),
                          _vm.employeeForm.hasError("is_disabled")
                            ? _c("span", {
                                staticClass: "text-danger",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.employeeForm.getError("is_disabled")
                                  ),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.employeeForm.is_disabled === 1
                      ? _c(
                          "div",
                          { staticClass: "col-lg-6 form-group mb-4" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "plan_type" },
                              },
                              [_vm._v("Type of Disability")]
                            ),
                            _vm._v(" "),
                            _c("v-select", {
                              attrs: {
                                id: "scheme_id",
                                label: "name",
                                options: _vm.disabilities,
                                reduce: (option) => option,
                                clearable: false,
                              },
                              model: {
                                value: _vm.employeeForm.disability,
                                callback: function ($$v) {
                                  _vm.$set(_vm.employeeForm, "disability", $$v)
                                },
                                expression: "employeeForm.disability",
                              },
                            }),
                            _vm._v(" "),
                            _vm.employeeForm.hasError("disability")
                              ? _c("span", {
                                  staticClass: "text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.employeeForm.getError("disability")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-6" }, [
                      _c("div", { staticClass: "form-group mb-4" }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "physical_address" },
                          },
                          [_vm._v("Residence ")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.employeeForm.address,
                              expression: "employeeForm.address",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "physical_address", type: "text" },
                          domProps: { value: _vm.employeeForm.address },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.employeeForm,
                                "address",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.employeeForm.hasError("address")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.employeeForm.getError("address")
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-group mb-4" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "form-label",
                              attrs: { for: "physical_address" },
                            },
                            [_vm._v("Marital Status")]
                          ),
                          _vm._v(" "),
                          _c("v-select", {
                            attrs: {
                              id: "marital_status",
                              label: "name",
                              options: _vm.maritalStatus,
                              reduce: (option) => option.value,
                              clearable: false,
                            },
                            model: {
                              value: _vm.employeeForm.marital_status,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.employeeForm,
                                  "marital_status",
                                  $$v
                                )
                              },
                              expression: "employeeForm.marital_status",
                            },
                          }),
                          _vm._v(" "),
                          _vm.employeeForm.hasError("marital_status")
                            ? _c("span", {
                                staticClass: "text-danger",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.employeeForm.getError("marital_status")
                                  ),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v("Next of kin")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-6" }, [
                      _c("div", { staticClass: "form-group mb-4" }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "physical_address" },
                          },
                          [_vm._v("Name")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.employeeForm.nextOfKin,
                              expression: "employeeForm.nextOfKin",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: _vm.employeeForm.nextOfKin },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.employeeForm,
                                "nextOfKin",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.employeeForm.hasError("nextOfKin")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.employeeForm.getError("nextOfKin")
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-6" }, [
                      _c("div", { staticClass: "form-group mb-4" }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "physical_address" },
                          },
                          [_vm._v("Tel")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.employeeForm.nextOfKin_tel,
                              expression: "employeeForm.nextOfKin_tel",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: _vm.employeeForm.nextOfKin_tel },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.employeeForm,
                                "nextOfKin_tel",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.employeeForm.hasError("nextOfKin_tel")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.employeeForm.getError("nextOfKin_tel")
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-6" }, [
                      _c("div", { staticClass: "form-group mb-4" }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "physical_address" },
                          },
                          [_vm._v("Nationality ")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.employeeForm.nationality,
                              expression: "employeeForm.nationality",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "physical_address", type: "text" },
                          domProps: { value: _vm.employeeForm.nationality },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.employeeForm,
                                "nationality",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.employeeForm.hasError("nationality")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.employeeForm.getError("nationality")
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-6" }, [
                      _c("div", { staticClass: "form-group mb-4" }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "physical_address" },
                          },
                          [_vm._v("Home county ")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.employeeForm.home_county,
                              expression: "employeeForm.home_county",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "physical_address", type: "text" },
                          domProps: { value: _vm.employeeForm.home_county },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.employeeForm,
                                "home_county",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.employeeForm.hasError("home_county")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.employeeForm.getError("home_county")
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-6" }, [
                      _c("div", { staticClass: "form-group mb-4" }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "physical_address" },
                          },
                          [_vm._v("Home sub county ")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.employeeForm.home_sub_county,
                              expression: "employeeForm.home_sub_county",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "physical_address", type: "text" },
                          domProps: { value: _vm.employeeForm.home_sub_county },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.employeeForm,
                                "home_sub_county",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.employeeForm.hasError("home_sub_county")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.employeeForm.getError("home_sub_county")
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-6" }, [
                      _c("div", { staticClass: "form-group mb-4" }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "physical_address" },
                          },
                          [_vm._v("Home Constituency ")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.employeeForm.home_constituency,
                              expression: "employeeForm.home_constituency",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "physical_address", type: "text" },
                          domProps: {
                            value: _vm.employeeForm.home_constituency,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.employeeForm,
                                "home_constituency",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.employeeForm.hasError("home_constituency")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.employeeForm.getError("home_constituency")
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.employeeForm.ethnicity
                      ? _c("div", { staticClass: "col-xl-6" }, [
                          _c("div", { staticClass: "form-group mb-4" }, [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "physical_address" },
                              },
                              [_vm._v("Ethnicity")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.employeeForm.ethnicity,
                                  expression: "employeeForm.ethnicity",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { id: "physical_address", type: "text" },
                              domProps: { value: _vm.employeeForm.ethnicity },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.employeeForm,
                                    "ethnicity",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.employeeForm.hasError("home_constituency")
                              ? _c("span", {
                                  staticClass: "text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.employeeForm.getError(
                                        "home_constituency"
                                      )
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-group mb-4" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "form-label",
                              attrs: { for: "physical_address" },
                            },
                            [_vm._v("Department")]
                          ),
                          _vm._v(" "),
                          _c("v-select", {
                            attrs: {
                              id: "gender_id",
                              name: "department_id",
                              options: _vm.departments,
                              label: "name",
                              reduce: (option) => option.id,
                              clearable: false,
                            },
                            model: {
                              value: _vm.employeeForm.department_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.employeeForm, "department_id", $$v)
                              },
                              expression: "employeeForm.department_id",
                            },
                          }),
                          _vm._v(" "),
                          _vm.employeeForm.hasError("department_id")
                            ? _c("span", {
                                staticClass: "text-danger",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.employeeForm.getError("department_id")
                                  ),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-group mb-4" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "form-label",
                              attrs: { for: "physical_address" },
                            },
                            [_vm._v("Supervisor")]
                          ),
                          _vm._v(" "),
                          _c("v-select", {
                            attrs: {
                              id: "gender_id",
                              name: "supervisor",
                              options: _vm.employees,
                              label: "name",
                              reduce: (option) => option.id,
                              clearable: false,
                            },
                            model: {
                              value: _vm.employeeForm.supervisor,
                              callback: function ($$v) {
                                _vm.$set(_vm.employeeForm, "supervisor", $$v)
                              },
                              expression: "employeeForm.supervisor",
                            },
                          }),
                          _vm._v(" "),
                          _vm.employeeForm.hasError("supervisor")
                            ? _c("span", {
                                staticClass: "text-danger",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.employeeForm.getError("supervisor")
                                  ),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-6" }, [
                      _c("div", { staticClass: "form-group mb-4" }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "physical_address" },
                          },
                          [_vm._v("Job Title ")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.employeeForm.job_title,
                              expression: "employeeForm.job_title",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "physical_address", type: "text" },
                          domProps: { value: _vm.employeeForm.job_title },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.employeeForm,
                                "job_title",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.employeeForm.hasError("job_title")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.employeeForm.getError("job_title")
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-lg-6 form-group mb-4" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "plan_type" },
                          },
                          [_vm._v("Employee Type")]
                        ),
                        _vm._v(" "),
                        _c("v-select", {
                          attrs: {
                            id: "scheme_id",
                            label: "name",
                            options: _vm.employeetypes,
                            reduce: (option) => option.id,
                            clearable: false,
                          },
                          model: {
                            value: _vm.employeeForm.employee_type_id,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.employeeForm,
                                "employee_type_id",
                                $$v
                              )
                            },
                            expression: "employeeForm.employee_type_id",
                          },
                        }),
                        _vm._v(" "),
                        _vm.employeeForm.hasError("employee_type_id")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.employeeForm.getError("employee_type_id")
                                ),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-lg-6 form-group mb-4" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "plan_type" },
                          },
                          [_vm._v("Job Group")]
                        ),
                        _vm._v(" "),
                        _c("v-select", {
                          attrs: {
                            id: "scheme_id",
                            label: "name",
                            options: _vm.jobgroups,
                            reduce: (option) => option.id,
                            clearable: false,
                          },
                          model: {
                            value: _vm.employeeForm.job_group_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.employeeForm, "job_group_id", $$v)
                            },
                            expression: "employeeForm.job_group_id",
                          },
                        }),
                        _vm._v(" "),
                        _vm.employeeForm.hasError("job_group_id")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.employeeForm.getError("job_group_id")
                                ),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-group mb-2" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "form-label",
                              attrs: { for: "paye_type" },
                            },
                            [_vm._v("Tax Setup")]
                          ),
                          _vm._v(" "),
                          _c("v-select", {
                            attrs: {
                              id: "paye_type",
                              options: _vm.PAYEOptions,
                              label: "name",
                              clearable: false,
                              reduce: (option) => option.value,
                            },
                            model: {
                              value: _vm.employeeForm.payee_type,
                              callback: function ($$v) {
                                _vm.$set(_vm.employeeForm, "payee_type", $$v)
                              },
                              expression: "employeeForm.payee_type",
                            },
                          }),
                          _vm._v(" "),
                          _vm.employeeForm.errors.has("payee_type")
                            ? _c("span", {
                                staticClass: "text-danger",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.employeeForm.errors.get("payee_type")
                                  ),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mb-2" }, [
                      _c("label", { staticClass: "form-check form-switch" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.employeeForm.pays_tax,
                              expression: "employeeForm.pays_tax",
                            },
                          ],
                          staticClass: "form-check-input",
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.employeeForm.pays_tax)
                              ? _vm._i(_vm.employeeForm.pays_tax, null) > -1
                              : _vm.employeeForm.pays_tax,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.employeeForm.pays_tax,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.employeeForm,
                                      "pays_tax",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.employeeForm,
                                      "pays_tax",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.employeeForm, "pays_tax", $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "form-check-label" }, [
                          _vm._v("Pays tax"),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.employeeForm.pays_tax
                      ? _c("div", { staticClass: "mb-4" }, [
                          _c(
                            "label",
                            {
                              staticClass: "form-label",
                              attrs: { for: "telephone" },
                            },
                            [_vm._v("Kra PIN ")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.employeeForm.kra_pin,
                                expression: "employeeForm.kra_pin",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { id: "telephone", max: "10", type: "tel" },
                            domProps: { value: _vm.employeeForm.kra_pin },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.employeeForm,
                                  "kra_pin",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.employeeForm.hasError("kra_pin")
                            ? _c("span", {
                                staticClass: "text-danger",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.employeeForm.getError("kra_pin")
                                  ),
                                },
                              })
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "mb-2" }, [
                      _c("label", { staticClass: "form-check form-switch" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.employeeForm.pays_nhif,
                              expression: "employeeForm.pays_nhif",
                            },
                          ],
                          staticClass: "form-check-input",
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.employeeForm.pays_nhif)
                              ? _vm._i(_vm.employeeForm.pays_nhif, null) > -1
                              : _vm.employeeForm.pays_nhif,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.employeeForm.pays_nhif,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.employeeForm,
                                      "pays_nhif",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.employeeForm,
                                      "pays_nhif",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.employeeForm, "pays_nhif", $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "form-check-label" }, [
                          _vm._v("Pays Nhif"),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.employeeForm.pays_nhif
                      ? _c("div", { staticClass: "mb-4" }, [
                          _c(
                            "label",
                            {
                              staticClass: "form-label",
                              attrs: { for: "telephone" },
                            },
                            [_vm._v("Nhif Number")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.employeeForm.nhif_no,
                                expression: "employeeForm.nhif_no",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { id: "telephone", max: "10", type: "tel" },
                            domProps: { value: _vm.employeeForm.nhif_no },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.employeeForm,
                                  "nhif_no",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.employeeForm.hasError("nhif_no")
                            ? _c("span", {
                                staticClass: "text-danger",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.employeeForm.getError("nhif_no")
                                  ),
                                },
                              })
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "mb-2" }, [
                      _c("label", { staticClass: "form-check form-switch" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.employeeForm.pays_nssf,
                              expression: "employeeForm.pays_nssf",
                            },
                          ],
                          staticClass: "form-check-input",
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.employeeForm.pays_nssf)
                              ? _vm._i(_vm.employeeForm.pays_nssf, null) > -1
                              : _vm.employeeForm.pays_nssf,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.employeeForm.pays_nssf,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.employeeForm,
                                      "pays_nssf",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.employeeForm,
                                      "pays_nssf",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.employeeForm, "pays_nssf", $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "form-check-label" }, [
                          _vm._v("Pays Nssf"),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.employeeForm.pays_nssf
                      ? _c("div", { staticClass: "mb-4" }, [
                          _c(
                            "label",
                            {
                              staticClass: "form-label",
                              attrs: { for: "telephone" },
                            },
                            [_vm._v("Nssf Number")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.employeeForm.nssf_no,
                                expression: "employeeForm.nssf_no",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { id: "telephone", max: "10", type: "tel" },
                            domProps: { value: _vm.employeeForm.nssf_no },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.employeeForm,
                                  "nssf_no",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.employeeForm.hasError("nssf_no")
                            ? _c("span", {
                                staticClass: "text-danger",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.employeeForm.getError("nssf_no")
                                  ),
                                },
                              })
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-group mb-2" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "form-label",
                              attrs: { for: "paye_type" },
                            },
                            [_vm._v("Employment status")]
                          ),
                          _vm._v(" "),
                          _c("v-select", {
                            attrs: {
                              id: "paye_type",
                              options: _vm.employmentStatus,
                              label: "name",
                              clearable: false,
                              reduce: (option) => option.value,
                            },
                            model: {
                              value: _vm.employeeForm.employment_status,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.employeeForm,
                                  "employment_status",
                                  $$v
                                )
                              },
                              expression: "employeeForm.employment_status",
                            },
                          }),
                          _vm._v(" "),
                          _vm.employeeForm.errors.has("payee_type")
                            ? _c("span", {
                                staticClass: "text-danger",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.employeeForm.errors.get("payee_type")
                                  ),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.employeeForm.conflicts_of_interest
                      ? _c("div", { staticClass: "col-xl-12" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "physical_address" },
                              },
                              [_vm._v("Conflicts of interest")]
                            ),
                            _vm._v(" "),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.employeeForm.conflicts_of_interest,
                                  expression:
                                    "employeeForm.conflicts_of_interest",
                                },
                              ],
                              staticClass: "form-control",
                              domProps: {
                                value: _vm.employeeForm.conflicts_of_interest,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.employeeForm,
                                    "conflicts_of_interest",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between space-x px-4 mb-4",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn w-25 py-3",
                        attrs: { disabled: _vm.employeeForm.processing },
                        on: { click: _vm.togglePatientEditForm },
                      },
                      [_vm._v("\n              Close\n            ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary w-25 py-3",
                        attrs: { disabled: _vm.employeeForm.processing },
                        on: { click: _vm.updateEmployee },
                      },
                      [_vm._v("\n              Save Changes\n            ")]
                    ),
                  ]
                ),
              ])
            : _c("div", { staticClass: "card card-stacked" }, [
                _c("div", { staticClass: "card-header" }, [
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-body d-flex align-items-center gap-2",
                      },
                      [
                        this.employee.profile_image
                          ? _c(
                              "span",
                              {
                                staticClass: "avatar avatar-xl rounded",
                                style: {
                                  backgroundImage: `url(${
                                    _vm.backgroundImageUrl ?? ""
                                  })`,
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "dropdown-mnu position-absolute",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          href: _vm.backgroundImageUrl ?? "",
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    View image\n                  "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "item",
                                        attrs: { href: "#" },
                                        on: { click: _vm.showEmployeeImage },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    change image\n                  "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _c(
                              "span",
                              {
                                staticClass: "avatar avatar-xl rounded",
                                staticStyle: {
                                  "background-image":
                                    "url(./static/avatars/005f.jpg)",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.employee.name.slice(0, 2)) +
                                    "\n                   "
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "dropdown-mnu position-absolute",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          href: _vm.backgroundImageUrl ?? "",
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    View image\n                  "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "item",
                                        attrs: { href: "#" },
                                        on: { click: _vm.showEmployeeImage },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    change image\n                  "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                        _vm._v(" "),
                        _c("span", [
                          _c("h3", { staticClass: "m-0 mb-1" }, [
                            _c("a", { attrs: { href: "#" } }, [
                              _vm._v(_vm._s(_vm.employee.name)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "text-secondary" }, [
                            _vm._v(_vm._s(_vm.employee.job_title)),
                          ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      { staticClass: "card-subtitle small text-indigo" },
                      [
                        _vm._v(
                          "\n                Registered " +
                            _vm._s(
                              _vm._f("dATE_MONTH_YEAR_HOUR_MINUTES")(
                                _vm.employee.created_at
                              )
                            ) +
                            "\n              "
                        ),
                      ]
                    ),
                    _vm.employee.employment_status === 1
                      ? _c("div", { staticClass: "text-warning" }, [
                          _vm._v("Suspended"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.employee.employment_status === 2
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v("Terminated"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("p"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "card-actions" }, [
                    _c("div", { staticClass: "btn-group" }, [
                      _vm._m(1),
                      _vm._v(" "),
                      _c("div", { staticClass: "dropdown" }, [
                        _vm._m(2),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "dropdown-menu dropdown-menu-end" },
                          [
                            _c("h6", { staticClass: "dropdown-header" }, [
                              _vm._v(
                                "\n                      Menu\n                    "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.togglePatientEditForm.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "uil uil-edit me-2" }),
                                _vm._v(" Edit Details\n                    "),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "dropdown-divider" }),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.showPromotionForm.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "uil uil-edit me-2" }),
                                _vm._v(" Promote\n                    "),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.showDemotionForm.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "uil uil-edit me-2" }),
                                _vm._v(" Demote\n                    "),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.showTransferForm.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "uil uil-edit me-2" }),
                                _vm._v("Transfer\n                    "),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.showCaseForm.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "uil uil-edit me-2" }),
                                _vm._v("Record case\n                    "),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.showHistory.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "uil uil-edit me-2" }),
                                _vm._v(
                                  " Employment History\n                    "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-body px-4" }, [
                  _c("div", { staticClass: "form-group mb-4" }, [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "name" } },
                      [_vm._v("Employee No")]
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.employeeForm.staff_number,
                            expression: "employeeForm.staff_number",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { id: "name", disabled: "", type: "text" },
                        domProps: { value: _vm.employeeForm.staff_number },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.employeeForm,
                              "staff_number",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.employeeForm.hasError("staff_number")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.employeeForm.getError("staff_number")
                              ),
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group mb-4" }, [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "name" } },
                      [_vm._v("Full Name")]
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.employeeForm.name,
                            expression: "employeeForm.name",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { id: "name", disabled: "", type: "text" },
                        domProps: { value: _vm.employeeForm.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.employeeForm,
                              "name",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.employeeForm.hasError("name")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.employeeForm.getError("name")
                              ),
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row gx-4" }, [
                    _c("div", { staticClass: "col-xl-6" }, [
                      _c("div", { staticClass: "form-group mb-4" }, [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("Date of Birth\n                  "),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("date-picker", {
                              attrs: {
                                disabled: "",
                                name: "date_of_birth",
                                value: _vm.employeeForm.dob,
                                "max-date": new Date(),
                              },
                              on: {
                                "on-change": (dateOb, dateStr) => {
                                  _vm.employeeForm.dob = dateStr
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.employeeForm.hasError("dob")
                              ? _c("span", {
                                  staticClass: "text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.employeeForm.getError("dob")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-6" }, [
                      _c("div", { staticClass: "form-group mb-4" }, [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("Gender "),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.employeeForm.gender,
                              expression: "employeeForm.gender",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "name", disabled: "", type: "text" },
                          domProps: { value: _vm.employeeForm.gender },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.employeeForm,
                                "gender",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.employeeForm.hasError("gender")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.employeeForm.getError("gender")
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group mb-4" }, [
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "identification_value" },
                      },
                      [_vm._v("Identification Number ")]
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.employeeForm.identification_number,
                            expression: "employeeForm.identification_number",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "identification_value",
                          disabled: "",
                          type: "text",
                        },
                        domProps: {
                          value: _vm.employeeForm.identification_number,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.employeeForm,
                              "identification_number",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.employeeForm.hasError("identification_number")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.employeeForm.getError(
                                  "identification_number"
                                )
                              ),
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row gx-4" }, [
                    _c("div", { staticClass: "col-xl-6" }, [
                      _c("div", { staticClass: "form-group mb-4" }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "telephone" },
                          },
                          [_vm._v("Mobile Number ")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.employeeForm.telephone,
                              expression: "employeeForm.telephone",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "telephone",
                            max: "10",
                            disabled: "",
                            type: "tel",
                          },
                          domProps: { value: _vm.employeeForm.telephone },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.employeeForm,
                                "telephone",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.employeeForm.hasError("telephone")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.employeeForm.getError("telephone")
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.employeeForm.is_disabled === 1
                      ? _c(
                          "div",
                          { staticClass: "col-lg-6 form-group mb-4" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "plan_type" },
                              },
                              [_vm._v("Type of Disability")]
                            ),
                            _vm._v(" "),
                            _c("v-select", {
                              attrs: {
                                disabled: "",
                                id: "scheme_id",
                                label: "name",
                                options: _vm.disabilities,
                                reduce: (option) => option,
                                clearable: false,
                              },
                              model: {
                                value: _vm.employeeForm.disability,
                                callback: function ($$v) {
                                  _vm.$set(_vm.employeeForm, "disability", $$v)
                                },
                                expression: "employeeForm.disability",
                              },
                            }),
                            _vm._v(" "),
                            _vm.employeeForm.hasError("disability")
                              ? _c("span", {
                                  staticClass: "text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.employeeForm.getError("disability")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-6" }, [
                      _c("div", { staticClass: "form-group mb-4" }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "physical_address" },
                          },
                          [_vm._v("Email ")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.employeeForm.email,
                              expression: "employeeForm.email",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "physical_address",
                            type: "text",
                            disabled: "",
                          },
                          domProps: { value: _vm.employeeForm.email },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.employeeForm,
                                "email",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.employeeForm.hasError("email")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.employeeForm.getError("email")
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-group mb-4" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "form-label",
                              attrs: { for: "physical_address" },
                            },
                            [_vm._v("Marital Status")]
                          ),
                          _vm._v(" "),
                          _c("v-select", {
                            attrs: {
                              id: "scheme_id",
                              label: "name",
                              options: _vm.maritalStatus,
                              reduce: (option) => option.value,
                              disabled: true,
                              clearable: false,
                            },
                            model: {
                              value: _vm.employeeForm.marital_status,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.employeeForm,
                                  "marital_status",
                                  $$v
                                )
                              },
                              expression: "employeeForm.marital_status",
                            },
                          }),
                          _vm._v(" "),
                          _vm.employeeForm.hasError("marital_status")
                            ? _c("span", {
                                staticClass: "text-danger",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.employeeForm.getError("marital_status")
                                  ),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.employeeForm.nextOfKin || _vm.employeeForm.nextOfKin_tel
                      ? _c("p", [_vm._v("Next of kin")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.employeeForm.nextOfKin
                      ? _c("div", { staticClass: "col-xl-6" }, [
                          _c("div", { staticClass: "form-group mb-4" }, [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "physical_address" },
                              },
                              [_vm._v("Name")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.employeeForm.nextOfKin,
                                  expression: "employeeForm.nextOfKin",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { disabled: "", type: "text" },
                              domProps: { value: _vm.employeeForm.nextOfKin },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.employeeForm,
                                    "nextOfKin",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.employeeForm.hasError("nextOfKin")
                              ? _c("span", {
                                  staticClass: "text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.employeeForm.getError("nextOfKin")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.employeeForm.nextOfKin_tel
                      ? _c("div", { staticClass: "col-xl-6" }, [
                          _c("div", { staticClass: "form-group mb-4" }, [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "physical_address" },
                              },
                              [_vm._v("Tel")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.employeeForm.nextOfKin_tel,
                                  expression: "employeeForm.nextOfKin_tel",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { disabled: "", type: "text" },
                              domProps: {
                                value: _vm.employeeForm.nextOfKin_tel,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.employeeForm,
                                    "nextOfKin_tel",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.employeeForm.hasError("nextOfKin_tel")
                              ? _c("span", {
                                  staticClass: "text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.employeeForm.getError("nextOfKin_tel")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-6" }, [
                      _c("div", { staticClass: "form-group mb-4" }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "physical_address" },
                          },
                          [_vm._v("Residence ")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.employeeForm.address,
                              expression: "employeeForm.address",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "physical_address",
                            type: "text",
                            disabled: "",
                          },
                          domProps: { value: _vm.employeeForm.address },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.employeeForm,
                                "address",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.employeeForm.hasError("physical_address")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.employeeForm.getError("physical_address")
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-group mb-4" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "form-label",
                              attrs: { for: "physical_address" },
                            },
                            [_vm._v("Department")]
                          ),
                          _vm._v(" "),
                          _c("v-select", {
                            attrs: {
                              id: "gender_id",
                              name: "department_id",
                              disabled: "",
                              options: _vm.departments,
                              label: "name",
                              reduce: (option) => option.id,
                              clearable: false,
                            },
                            model: {
                              value: _vm.employeeForm.department_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.employeeForm, "department_id", $$v)
                              },
                              expression: "employeeForm.department_id",
                            },
                          }),
                          _vm._v(" "),
                          _vm.employeeForm.hasError("department_id")
                            ? _c("span", {
                                staticClass: "text-danger",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.employeeForm.getError("department_id")
                                  ),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-group mb-4" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "form-label",
                              attrs: { for: "physical_address" },
                            },
                            [_vm._v("Supervisor")]
                          ),
                          _vm._v(" "),
                          _c("v-select", {
                            attrs: {
                              id: "gender_id",
                              name: "supervisor",
                              disabled: "",
                              options: _vm.employees,
                              label: "name",
                              reduce: (option) => option.id,
                              clearable: false,
                            },
                            model: {
                              value: _vm.employeeForm.supervisor,
                              callback: function ($$v) {
                                _vm.$set(_vm.employeeForm, "supervisor", $$v)
                              },
                              expression: "employeeForm.supervisor",
                            },
                          }),
                          _vm._v(" "),
                          _vm.employeeForm.hasError("supervisor")
                            ? _c("span", {
                                staticClass: "text-danger",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.employeeForm.getError("supervisor")
                                  ),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-6" }, [
                      _c("div", { staticClass: "form-group mb-4" }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "physical_address" },
                          },
                          [_vm._v("Nationality ")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.employeeForm.nationality,
                              expression: "employeeForm.nationality",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "physical_address",
                            type: "text",
                            disabled: "",
                          },
                          domProps: { value: _vm.employeeForm.nationality },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.employeeForm,
                                "nationality",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.employeeForm.hasError("nationality")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.employeeForm.getError("nationality")
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-6" }, [
                      _c("div", { staticClass: "form-group mb-4" }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "physical_address" },
                          },
                          [_vm._v("Home county ")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.employeeForm.home_county,
                              expression: "employeeForm.home_county",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "physical_address",
                            type: "text",
                            disabled: "",
                          },
                          domProps: { value: _vm.employeeForm.home_county },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.employeeForm,
                                "home_county",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.employeeForm.hasError("home_county")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.employeeForm.getError("home_county")
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-6" }, [
                      _c("div", { staticClass: "form-group mb-4" }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "physical_address" },
                          },
                          [_vm._v("Home sub county ")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.employeeForm.home_sub_county,
                              expression: "employeeForm.home_sub_county",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "physical_address",
                            type: "text",
                            disabled: "",
                          },
                          domProps: { value: _vm.employeeForm.home_sub_county },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.employeeForm,
                                "home_sub_county",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.employeeForm.hasError("home_sub_county")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.employeeForm.getError("home_sub_county")
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-6" }, [
                      _c("div", { staticClass: "form-group mb-4" }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "physical_address" },
                          },
                          [_vm._v("Home Constituency ")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.employeeForm.home_constituency,
                              expression: "employeeForm.home_constituency",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "physical_address",
                            type: "text",
                            disabled: "",
                          },
                          domProps: {
                            value: _vm.employeeForm.home_constituency,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.employeeForm,
                                "home_constituency",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.employeeForm.hasError("home_constituency")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.employeeForm.getError("home_constituency")
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.employeeForm.ethnicity
                      ? _c("div", { staticClass: "col-xl-6" }, [
                          _c("div", { staticClass: "form-group mb-4" }, [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "physical_address" },
                              },
                              [_vm._v("Ethnicity")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.employeeForm.ethnicity,
                                  expression: "employeeForm.ethnicity",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "physical_address",
                                type: "text",
                                disabled: "",
                              },
                              domProps: { value: _vm.employeeForm.ethnicity },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.employeeForm,
                                    "ethnicity",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.employeeForm.hasError("home_constituency")
                              ? _c("span", {
                                  staticClass: "text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.employeeForm.getError(
                                        "home_constituency"
                                      )
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-6" }, [
                      _c("div", { staticClass: "form-group mb-4" }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "physical_address" },
                          },
                          [_vm._v("Job Title ")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.employeeForm.job_title,
                              expression: "employeeForm.job_title",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "physical_address",
                            type: "text",
                            disabled: "",
                          },
                          domProps: { value: _vm.employeeForm.job_title },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.employeeForm,
                                "job_title",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.employeeForm.hasError("job_title")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.employeeForm.getError("job_title")
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-lg-6 form-group mb-4" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "plan_type" },
                          },
                          [_vm._v("Employee Type")]
                        ),
                        _vm._v(" "),
                        _c("v-select", {
                          attrs: {
                            id: "scheme_id",
                            label: "name",
                            disabled: "",
                            options: _vm.employeetypes,
                            reduce: (option) => option.id,
                            clearable: false,
                          },
                          model: {
                            value: _vm.employeeForm.employee_type_id,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.employeeForm,
                                "employee_type_id",
                                $$v
                              )
                            },
                            expression: "employeeForm.employee_type_id",
                          },
                        }),
                        _vm._v(" "),
                        _vm.employeeForm.hasError("employee_type_id")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.employeeForm.getError("employee_type_id")
                                ),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-lg-6 form-group mb-4" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "plan_type" },
                          },
                          [_vm._v("Job Group")]
                        ),
                        _vm._v(" "),
                        _c("v-select", {
                          attrs: {
                            id: "scheme_id",
                            label: "name",
                            disabled: "",
                            options: _vm.jobgroups,
                            reduce: (option) => option.id,
                            clearable: false,
                          },
                          model: {
                            value: _vm.employeeForm.job_group_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.employeeForm, "job_group_id", $$v)
                            },
                            expression: "employeeForm.job_group_id",
                          },
                        }),
                        _vm._v(" "),
                        _vm.employeeForm.hasError("job_group_id")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.employeeForm.getError("job_group_id")
                                ),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-group mb-2" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "form-label",
                              attrs: { for: "paye_type" },
                            },
                            [_vm._v("Tax Setup")]
                          ),
                          _vm._v(" "),
                          _c("v-select", {
                            attrs: {
                              id: "paye_type",
                              options: _vm.PAYEOptions,
                              label: "name",
                              disabled: "true",
                              clearable: false,
                              reduce: (option) => option.value,
                            },
                            model: {
                              value: _vm.employeeForm.payee_type,
                              callback: function ($$v) {
                                _vm.$set(_vm.employeeForm, "payee_type", $$v)
                              },
                              expression: "employeeForm.payee_type",
                            },
                          }),
                          _vm._v(" "),
                          _vm.employeeForm.errors.has("payee_type")
                            ? _c("span", {
                                staticClass: "text-danger",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.employeeForm.errors.get("payee_type")
                                  ),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.employeeForm.pays_tax
                      ? _c("div", { staticClass: "col-xl-6" }, [
                          _c("div", { staticClass: "form-group mb-4" }, [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "physical_address" },
                              },
                              [_vm._v("Kra PIN")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.employeeForm.kra_pin,
                                  expression: "employeeForm.kra_pin",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "physical_address",
                                type: "text",
                                disabled: "",
                              },
                              domProps: { value: _vm.employeeForm.kra_pin },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.employeeForm,
                                    "kra_pin",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.employeeForm.hasError("kra_pin")
                              ? _c("span", {
                                  staticClass: "text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.employeeForm.getError("kra_pin")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.employeeForm.pays_nhif
                      ? _c("div", { staticClass: "col-xl-6" }, [
                          _c("div", { staticClass: "form-group mb-4" }, [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "physical_address" },
                              },
                              [_vm._v("Nhif Number")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.employeeForm.nhif_no,
                                  expression: "employeeForm.nhif_no",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "physical_address",
                                type: "text",
                                disabled: "",
                              },
                              domProps: { value: _vm.employeeForm.nhif_no },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.employeeForm,
                                    "nhif_no",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.employeeForm.hasError("nhif_no")
                              ? _c("span", {
                                  staticClass: "text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.employeeForm.getError("nhif_no")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.employeeForm.pays_nssf
                      ? _c("div", { staticClass: "col-xl-6" }, [
                          _c("div", { staticClass: "form-group mb-4" }, [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "physical_address" },
                              },
                              [_vm._v("Nssf Number")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.employeeForm.nssf_no,
                                  expression: "employeeForm.nssf_no",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "physical_address",
                                type: "text",
                                disabled: "",
                              },
                              domProps: { value: _vm.employeeForm.nssf_no },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.employeeForm,
                                    "nssf_no",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.employeeForm.hasError("nssf_no")
                              ? _c("span", {
                                  staticClass: "text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.employeeForm.getError("nssf_no")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.employeeForm.conflicts_of_interest
                      ? _c("div", { staticClass: "col-xl-12" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "physical_address" },
                              },
                              [_vm._v("Conflicts of interest")]
                            ),
                            _vm._v(" "),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.employeeForm.conflicts_of_interest,
                                  expression:
                                    "employeeForm.conflicts_of_interest",
                                },
                              ],
                              staticClass: "form-control",
                              domProps: {
                                value: _vm.employeeForm.conflicts_of_interest,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.employeeForm,
                                    "conflicts_of_interest",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-xl-7" },
          [
            _c(
              "b-tabs",
              {
                attrs: {
                  "nav-class": "mb-2",
                  "active-nav-item-class": "bg-white text-indigo",
                },
              },
              [
                _c(
                  "b-tab",
                  {
                    attrs: {
                      "title-link-class": "p-3",
                      "title-item-class": "fs-3",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c("i", { staticClass: "uil uil-history me-1" }),
                            _vm._v(" Income\n            "),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _vm._v(" "),
                    _c("income", {
                      attrs: { employee: _vm.employee, incomes: _vm.incomes },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-tab",
                  {
                    attrs: {
                      "title-link-class": "p-3",
                      "title-item-class": "fs-3",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c("i", { staticClass: "uil uil-history me-1" }),
                            _vm._v(" Attachments\n            "),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _vm._v(" "),
                    _c("attachments", {
                      attrs: {
                        allowances: _vm.allowances,
                        employee: _vm.employee,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-tab",
                  {
                    attrs: {
                      "title-link-class": "p-3",
                      "title-item-class": "fs-3",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c("i", { staticClass: "uil uil-history me-1" }),
                            _vm._v(" Benefits\n            "),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _vm._v(" "),
                    _c("benefits", {
                      attrs: { benefits: _vm.benefits, employee: _vm.employee },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-tab",
                  {
                    attrs: {
                      "title-link-class": "p-3",
                      "title-item-class": "fs-3",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c("i", { staticClass: "uil uil-history me-1" }),
                            _vm._v(" Allowances\n            "),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _vm._v(" "),
                    _c("allowances", {
                      attrs: {
                        allowances: _vm.allowances,
                        employee: _vm.employee,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-tab",
                  {
                    attrs: {
                      "title-link-class": "p-3",
                      "title-item-class": "fs-3",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c("i", { staticClass: "uil uil-history me-1" }),
                            _vm._v(" Dedutions\n            "),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _vm._v(" "),
                    _c("dedutions", {
                      attrs: {
                        deductions: _vm.deductions,
                        employee: _vm.employee,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-tab",
                  {
                    attrs: {
                      "title-link-class": "p-3",
                      "title-item-class": "fs-3",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c("i", { staticClass: "uil uil-history me-1" }),
                            _vm._v(" Pension\n            "),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _vm._v(" "),
                    _c("pension", {
                      attrs: { pensions: _vm.pensions, employee: _vm.employee },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-tab",
                  {
                    attrs: {
                      "title-link-class": "p-3",
                      "title-item-class": "fs-3",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c("i", { staticClass: "uil uil-history me-1" }),
                            _vm._v(" Relief\n            "),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _vm._v(" "),
                    _c("relief", {
                      attrs: { reliefs: _vm.reliefs, employee: _vm.employee },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-tab",
                  {
                    attrs: {
                      "title-link-class": "p-3",
                      "title-item-class": "fs-3",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c("i", {
                              staticClass: "uil uil-file-upload-alt me-1",
                            }),
                            _vm._v(" Contributions\n            "),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _vm._v(" "),
                    _c("contributions", {
                      attrs: {
                        employee: _vm.employee,
                        contributions: _vm.contributions,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-tab",
                  {
                    attrs: {
                      "title-link-class": "p-3",
                      "title-item-class": "fs-3",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c("i", {
                              staticClass: "uil uil-graduation-cap me-1",
                            }),
                            _vm._v(" Qualifications\n            "),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _vm._v(" "),
                    _c("qualification", {
                      attrs: {
                        employee: _vm.employee,
                        contributions: _vm.contributions,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "promotion-modal",
            "body-class": "px-4",
            "content-class": "bg-white rounded-3",
            "footer-class":
              "d-flex justify-content-between bg-muted-lt border-top",
            "hide-header-close": "",
            "modal-class": "modal-blur",
            "no-enforce-focus": "",
            title: "Promotion Form",
          },
          on: { hidden: _vm.cleanUp },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      attrs: { disabled: _vm.form.processing, type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return cancel.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n          Close\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { disabled: _vm.form.processing, type: "submit" },
                      on: { click: _vm.promote },
                    },
                    [_vm._v("\n          Submit\n        ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("div", { staticClass: "form-group mb-4" }, [
            _c(
              "label",
              { staticClass: "form-label", attrs: { for: "physical_address" } },
              [_vm._v("New Job Title")]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.promotionForm.new_job_title,
                  expression: "promotionForm.new_job_title",
                },
              ],
              staticClass: "form-control",
              attrs: { id: "physical_address", type: "text" },
              domProps: { value: _vm.promotionForm.new_job_title },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.promotionForm,
                    "new_job_title",
                    $event.target.value
                  )
                },
              },
            }),
            _vm._v(" "),
            _vm.promotionForm.hasError("new_job_title")
              ? _c("span", {
                  staticClass: "text-danger",
                  domProps: {
                    textContent: _vm._s(
                      _vm.promotionForm.getError("new_job_title")
                    ),
                  },
                })
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group mb-4" },
            [
              _c(
                "label",
                {
                  staticClass: "form-label",
                  attrs: { for: "physical_address" },
                },
                [_vm._v("New Job Group")]
              ),
              _vm._v(" "),
              _c("v-select", {
                attrs: {
                  id: "item_id",
                  clearable: false,
                  reduce: (option) => option.id,
                  options: _vm.jobgroups,
                  label: "name",
                },
                model: {
                  value: _vm.promotionForm.new_job_group_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.promotionForm, "new_job_group_id", $$v)
                  },
                  expression: "promotionForm.new_job_group_id",
                },
              }),
              _vm._v(" "),
              _vm.promotionForm.hasError("new_job_group_id")
                ? _c("span", {
                    staticClass: "text-danger",
                    domProps: {
                      textContent: _vm._s(
                        _vm.promotionForm.getError("new_job_group_id")
                      ),
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group mb-4" },
            [
              _c(
                "label",
                {
                  staticClass: "form-label",
                  attrs: { for: "physical_address" },
                },
                [_vm._v("New Department")]
              ),
              _vm._v(" "),
              _c("v-select", {
                attrs: {
                  id: "item_id",
                  clearable: false,
                  reduce: (option) => option.id,
                  options: _vm.departments,
                  label: "name",
                },
                model: {
                  value: _vm.promotionForm.new_department_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.promotionForm, "new_department_id", $$v)
                  },
                  expression: "promotionForm.new_department_id",
                },
              }),
              _vm._v(" "),
              _vm.promotionForm.hasError("department_id")
                ? _c("span", {
                    staticClass: "text-danger",
                    domProps: {
                      textContent: _vm._s(
                        _vm.promotionForm.getError("department_id")
                      ),
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "form-group mb-4" }, [
            _c(
              "label",
              { staticClass: "form-label", attrs: { for: "physical_address" } },
              [_vm._v("Promotion Reason")]
            ),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.promotionForm.promotion_reason,
                  expression: "promotionForm.promotion_reason",
                },
              ],
              staticClass: "form-control",
              domProps: { value: _vm.promotionForm.promotion_reason },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.promotionForm,
                    "promotion_reason",
                    $event.target.value
                  )
                },
              },
            }),
            _vm._v(" "),
            _vm.form.hasError("promotion_reason")
              ? _c("span", {
                  staticClass: "text-danger",
                  domProps: {
                    textContent: _vm._s(_vm.form.getError("promotion_reason")),
                  },
                })
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group mb-4" }, [
            _c("label", { staticClass: "form-label" }, [
              _vm._v("Promotion Date"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("date-picker", {
                  attrs: {
                    name: "date_of_birth",
                    formclass: "form-control bg-white",
                    value: _vm.promotionForm.promotion_date,
                    "max-date": false,
                  },
                  on: {
                    "on-change": (dateOb, dateStr) => {
                      _vm.promotionForm.promotion_date = dateStr
                    },
                  },
                }),
                _vm._v(" "),
                _vm.promotionForm.hasError("promotion_date")
                  ? _c("span", {
                      staticClass: "text-danger",
                      domProps: {
                        textContent: _vm._s(
                          _vm.promotionForm.getError("promotion_date")
                        ),
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.form.processing
            ? _c("div", { staticClass: "progress mb-2" }, [
                _c("div", { staticClass: "progress" }, [
                  _c("div", {
                    staticClass:
                      "progress-bar progress-bar-indeterminate bg-green",
                  }),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "demotion-modal",
            "body-class": "px-4",
            "content-class": "bg-white rounded-3",
            "footer-class":
              "d-flex justify-content-between bg-muted-lt border-top",
            "hide-header-close": "",
            "modal-class": "modal-blur",
            "no-enforce-focus": "",
            title: "Demotion Form",
          },
          on: { hidden: _vm.cleanUp },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      attrs: { disabled: _vm.form.processing, type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return cancel.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n          Close\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { disabled: _vm.form.processing, type: "submit" },
                      on: { click: _vm.demotion },
                    },
                    [_vm._v("\n          Submit\n        ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("div", { staticClass: "form-group mb-4" }, [
            _c(
              "label",
              { staticClass: "form-label", attrs: { for: "physical_address" } },
              [_vm._v("New Job Title")]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.demotionForm.new_job_title,
                  expression: "demotionForm.new_job_title",
                },
              ],
              staticClass: "form-control",
              attrs: { id: "physical_address", type: "text" },
              domProps: { value: _vm.demotionForm.new_job_title },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.demotionForm,
                    "new_job_title",
                    $event.target.value
                  )
                },
              },
            }),
            _vm._v(" "),
            _vm.demotionForm.hasError("new_job_title")
              ? _c("span", {
                  staticClass: "text-danger",
                  domProps: {
                    textContent: _vm._s(
                      _vm.demotionForm.getError("new_job_title")
                    ),
                  },
                })
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group mb-4" },
            [
              _c(
                "label",
                {
                  staticClass: "form-label",
                  attrs: { for: "physical_address" },
                },
                [_vm._v("New Job Group")]
              ),
              _vm._v(" "),
              _c("v-select", {
                attrs: {
                  id: "item_id",
                  clearable: false,
                  reduce: (option) => option.id,
                  options: _vm.jobgroups,
                  label: "name",
                },
                model: {
                  value: _vm.demotionForm.new_job_group_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.demotionForm, "new_job_group_id", $$v)
                  },
                  expression: "demotionForm.new_job_group_id",
                },
              }),
              _vm._v(" "),
              _vm.demotionForm.hasError("new_job_group_id")
                ? _c("span", {
                    staticClass: "text-danger",
                    domProps: {
                      textContent: _vm._s(
                        _vm.demotionForm.getError("new_job_group_id")
                      ),
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group mb-4" },
            [
              _c(
                "label",
                {
                  staticClass: "form-label",
                  attrs: { for: "physical_address" },
                },
                [_vm._v("New Department")]
              ),
              _vm._v(" "),
              _c("v-select", {
                attrs: {
                  id: "item_id",
                  clearable: false,
                  reduce: (option) => option.id,
                  options: _vm.departments,
                  label: "name",
                },
                model: {
                  value: _vm.demotionForm.new_department_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.demotionForm, "new_department_id", $$v)
                  },
                  expression: "demotionForm.new_department_id",
                },
              }),
              _vm._v(" "),
              _vm.demotionForm.hasError("department_id")
                ? _c("span", {
                    staticClass: "text-danger",
                    domProps: {
                      textContent: _vm._s(
                        _vm.demotionForm.getError("department_id")
                      ),
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "form-group mb-4" }, [
            _c(
              "label",
              { staticClass: "form-label", attrs: { for: "physical_address" } },
              [_vm._v("Demotion Reason")]
            ),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.demotionForm.demotion_reason,
                  expression: "demotionForm.demotion_reason",
                },
              ],
              staticClass: "form-control",
              domProps: { value: _vm.demotionForm.demotion_reason },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.demotionForm,
                    "demotion_reason",
                    $event.target.value
                  )
                },
              },
            }),
            _vm._v(" "),
            _vm.form.hasError("demotion_reason")
              ? _c("span", {
                  staticClass: "text-danger",
                  domProps: {
                    textContent: _vm._s(_vm.form.getError("demotion_reason")),
                  },
                })
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group mb-4" }, [
            _c("label", { staticClass: "form-label" }, [
              _vm._v("Demotion Date"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("date-picker", {
                  attrs: {
                    name: "date_of_birth",
                    formclass: "form-control bg-white",
                    value: _vm.demotionForm.demotion_date,
                    "max-date": false,
                  },
                  on: {
                    "on-change": (dateOb, dateStr) => {
                      _vm.demotionForm.demotion_date = dateStr
                    },
                  },
                }),
                _vm._v(" "),
                _vm.demotionForm.hasError("demotion_date")
                  ? _c("span", {
                      staticClass: "text-danger",
                      domProps: {
                        textContent: _vm._s(
                          _vm.demotionForm.getError("demotion_date")
                        ),
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.form.processing
            ? _c("div", { staticClass: "progress mb-2" }, [
                _c("div", { staticClass: "progress" }, [
                  _c("div", {
                    staticClass:
                      "progress-bar progress-bar-indeterminate bg-green",
                  }),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "transfer-modal",
            "body-class": "px-4",
            "content-class": "bg-white rounded-3",
            "footer-class":
              "d-flex justify-content-between bg-muted-lt border-top",
            "hide-header-close": "",
            "modal-class": "modal-blur",
            "no-enforce-focus": "",
            title: "Job Transfer Form",
          },
          on: { hidden: _vm.cleanUp },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      attrs: { disabled: _vm.form.processing, type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return cancel.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n          Close\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { disabled: _vm.form.processing, type: "submit" },
                      on: { click: _vm.transfer },
                    },
                    [_vm._v("\n          Submit\n        ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("div", { staticClass: "form-group mb-4" }, [
            _c(
              "label",
              { staticClass: "form-label", attrs: { for: "physical_address" } },
              [_vm._v("New Job Title")]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.transferForm.new_job_title,
                  expression: "transferForm.new_job_title",
                },
              ],
              staticClass: "form-control",
              attrs: { id: "physical_address", type: "text" },
              domProps: { value: _vm.transferForm.new_job_title },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.transferForm,
                    "new_job_title",
                    $event.target.value
                  )
                },
              },
            }),
            _vm._v(" "),
            _vm.transferForm.hasError("new_job_title")
              ? _c("span", {
                  staticClass: "text-danger",
                  domProps: {
                    textContent: _vm._s(
                      _vm.transferForm.getError("new_job_title")
                    ),
                  },
                })
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group mb-4" },
            [
              _c(
                "label",
                {
                  staticClass: "form-label",
                  attrs: { for: "physical_address" },
                },
                [_vm._v("New Job Group")]
              ),
              _vm._v(" "),
              _c("v-select", {
                attrs: {
                  id: "item_id",
                  clearable: false,
                  reduce: (option) => option.id,
                  options: _vm.jobgroups,
                  label: "name",
                },
                model: {
                  value: _vm.transferForm.new_job_group_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.transferForm, "new_job_group_id", $$v)
                  },
                  expression: "transferForm.new_job_group_id",
                },
              }),
              _vm._v(" "),
              _vm.transferForm.hasError("new_job_group_id")
                ? _c("span", {
                    staticClass: "text-danger",
                    domProps: {
                      textContent: _vm._s(
                        _vm.transferForm.getError("new_job_group_id")
                      ),
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group mb-4" },
            [
              _c(
                "label",
                {
                  staticClass: "form-label",
                  attrs: { for: "physical_address" },
                },
                [_vm._v("New Department")]
              ),
              _vm._v(" "),
              _c("v-select", {
                attrs: {
                  id: "item_id",
                  clearable: false,
                  reduce: (option) => option.id,
                  options: _vm.departments,
                  label: "name",
                },
                model: {
                  value: _vm.transferForm.new_department_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.transferForm, "new_department_id", $$v)
                  },
                  expression: "transferForm.new_department_id",
                },
              }),
              _vm._v(" "),
              _vm.transferForm.hasError("department_id")
                ? _c("span", {
                    staticClass: "text-danger",
                    domProps: {
                      textContent: _vm._s(
                        _vm.transferForm.getError("department_id")
                      ),
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "form-group mb-4" }, [
            _c(
              "label",
              { staticClass: "form-label", attrs: { for: "physical_address" } },
              [_vm._v("Reason for transfer")]
            ),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.transferForm.transfer_reason,
                  expression: "transferForm.transfer_reason",
                },
              ],
              staticClass: "form-control",
              domProps: { value: _vm.transferForm.transfer_reason },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.transferForm,
                    "transfer_reason",
                    $event.target.value
                  )
                },
              },
            }),
            _vm._v(" "),
            _vm.form.hasError("transfer_reason")
              ? _c("span", {
                  staticClass: "text-danger",
                  domProps: {
                    textContent: _vm._s(_vm.form.getError("transfer_reason")),
                  },
                })
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group mb-4" }, [
            _c("label", { staticClass: "form-label" }, [
              _vm._v("Date of transfer"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("date-picker", {
                  attrs: {
                    name: "date_of_birth",
                    formclass: "form-control bg-white",
                    value: _vm.transferForm.transfer_date,
                    "max-date": false,
                  },
                  on: {
                    "on-change": (dateOb, dateStr) => {
                      _vm.transferForm.transfer_date = dateStr
                    },
                  },
                }),
                _vm._v(" "),
                _vm.transferForm.hasError("transfer_date")
                  ? _c("span", {
                      staticClass: "text-danger",
                      domProps: {
                        textContent: _vm._s(
                          _vm.transferForm.getError("transfer_date")
                        ),
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.form.processing
            ? _c("div", { staticClass: "progress mb-2" }, [
                _c("div", { staticClass: "progress" }, [
                  _c("div", {
                    staticClass:
                      "progress-bar progress-bar-indeterminate bg-green",
                  }),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "record-case-modal",
            "body-class": "px-4",
            "content-class": "bg-white rounded-3",
            "footer-class":
              "d-flex justify-content-between bg-muted-lt border-top",
            "hide-header-close": "",
            "modal-class": "modal-blur",
            "no-enforce-focus": "",
            title: "Record Disciplinary Case",
          },
          on: { hidden: _vm.cleanUp },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      attrs: { disabled: _vm.form.processing, type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return cancel.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n          Close\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { disabled: _vm.form.processing, type: "submit" },
                      on: { click: _vm.recordCase },
                    },
                    [_vm._v("\n          Submit\n        ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("div", { staticClass: "form-group mb-4" }, [
            _c(
              "label",
              { staticClass: "form-label", attrs: { for: "physical_address" } },
              [_vm._v("Case Description")]
            ),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.caseForm.description,
                  expression: "caseForm.description",
                },
              ],
              staticClass: "form-control",
              domProps: { value: _vm.caseForm.description },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.caseForm, "description", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _vm.caseForm.hasError("description")
              ? _c("span", {
                  staticClass: "text-danger",
                  domProps: {
                    textContent: _vm._s(_vm.caseForm.getError("description")),
                  },
                })
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group mb-4" },
            [
              _c(
                "label",
                {
                  staticClass: "form-label",
                  attrs: { for: "physical_address" },
                },
                [_vm._v("Severity Level")]
              ),
              _vm._v(" "),
              _c("v-select", {
                attrs: {
                  id: "item_id",
                  clearable: false,
                  reduce: (option) => option.id,
                  options: _vm.severitylevels,
                  label: "name",
                },
                model: {
                  value: _vm.caseForm.severity_level,
                  callback: function ($$v) {
                    _vm.$set(_vm.caseForm, "severity_level", $$v)
                  },
                  expression: "caseForm.severity_level",
                },
              }),
              _vm._v(" "),
              _vm.caseForm.hasError("severity_level")
                ? _c("span", {
                    staticClass: "text-danger",
                    domProps: {
                      textContent: _vm._s(
                        _vm.caseForm.getError("severity_level")
                      ),
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group mb-4" },
            [
              _c(
                "label",
                {
                  staticClass: "form-label",
                  attrs: { for: "physical_address" },
                },
                [_vm._v("Incident Date")]
              ),
              _vm._v(" "),
              _c("date-picker", {
                attrs: {
                  name: "date_of_birth",
                  value: _vm.caseForm.incident_date,
                  "max-date": new Date(),
                },
                on: {
                  "on-change": (dateOb, dateStr) => {
                    _vm.caseForm.incident_date = dateStr
                  },
                },
              }),
              _vm._v(" "),
              _vm.caseForm.hasError("incident_date")
                ? _c("span", {
                    staticClass: "text-danger",
                    domProps: {
                      textContent: _vm._s(
                        _vm.caseForm.getError("incident_date")
                      ),
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group mb-4" },
            [
              _c(
                "label",
                {
                  staticClass: "form-label",
                  attrs: { for: "physical_address" },
                },
                [_vm._v("Resolution Date")]
              ),
              _vm._v(" "),
              _c("date-picker", {
                attrs: {
                  name: "date_of_birth",
                  value: _vm.caseForm.resolution_date,
                  "min-date": new Date(),
                },
                on: {
                  "on-change": (dateOb, dateStr) => {
                    _vm.caseForm.resolution_date = dateStr
                  },
                },
              }),
              _vm._v(" "),
              _vm.caseForm.hasError("resolution_date")
                ? _c("span", {
                    staticClass: "text-danger",
                    domProps: {
                      textContent: _vm._s(
                        _vm.caseForm.getError("resolution_date")
                      ),
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "form-group mb-4" }, [
            _c(
              "label",
              { staticClass: "form-label", attrs: { for: "physical_address" } },
              [_vm._v("Resolution Details")]
            ),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.caseForm.resolution_details,
                  expression: "caseForm.resolution_details",
                },
              ],
              staticClass: "form-control",
              domProps: { value: _vm.caseForm.resolution_details },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.caseForm,
                    "resolution_details",
                    $event.target.value
                  )
                },
              },
            }),
            _vm._v(" "),
            _vm.form.hasError("resolution_details")
              ? _c("span", {
                  staticClass: "text-danger",
                  domProps: {
                    textContent: _vm._s(
                      _vm.form.getError("resolution_details")
                    ),
                  },
                })
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mb-4" }, [
            _c("label", { staticClass: "form-check form-switch" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.caseForm.status,
                    expression: "caseForm.status",
                  },
                ],
                staticClass: "form-check-input",
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.caseForm.status)
                    ? _vm._i(_vm.caseForm.status, null) > -1
                    : _vm.caseForm.status,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.caseForm.status,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(_vm.caseForm, "status", $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.caseForm,
                            "status",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.caseForm, "status", $$c)
                    }
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "form-check-label" }, [
                _vm._v("Case closed ?"),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.caseForm.status
            ? _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-title p-2" }, [
                  _vm._v("Take Action"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-body" }, [
                  _c(
                    "div",
                    { staticClass: "form-group mb-4" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "form-label",
                          attrs: { for: "physical_address" },
                        },
                        [_vm._v("Action")]
                      ),
                      _vm._v(" "),
                      _c("v-select", {
                        attrs: {
                          id: "item_id",
                          clearable: false,
                          reduce: (option) => option.id,
                          options: _vm.actiontypes,
                          label: "name",
                        },
                        model: {
                          value: _vm.caseForm.action_type_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.caseForm, "action_type_id", $$v)
                          },
                          expression: "caseForm.action_type_id",
                        },
                      }),
                      _vm._v(" "),
                      _vm.caseForm.hasError("action_type_id")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.caseForm.getError("action_type_id")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group mb-4" }, [
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "physical_address" },
                      },
                      [_vm._v("Action Details")]
                    ),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.caseForm.action_details,
                          expression: "caseForm.action_details",
                        },
                      ],
                      staticClass: "form-control",
                      domProps: { value: _vm.caseForm.action_details },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.caseForm,
                            "action_details",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.caseForm.hasError("action_details")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.caseForm.getError("action_details")
                            ),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.form.processing
            ? _c("div", { staticClass: "progress mb-2" }, [
                _c("div", { staticClass: "progress" }, [
                  _c("div", {
                    staticClass:
                      "progress-bar progress-bar-indeterminate bg-green",
                  }),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "P10-form-modal",
            "body-class": "px-4",
            "content-class": "bg-white rounded-3",
            "footer-class":
              "d-flex justify-content-between bg-muted-lt border-top",
            "hide-header-close": "",
            "modal-class": "modal-blur",
            "no-enforce-focus": "",
            title: "Generate P9 Form",
          },
          on: { hidden: _vm.cleanUp },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      attrs: {
                        disabled: _vm.formP9.processing,
                        type: "button",
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return cancel.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n          Close\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        disabled: _vm.formP9.processing,
                        type: "submit",
                      },
                      on: { click: _vm.formP9 },
                    },
                    [_vm._v("\n          Submit\n        ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("div", { staticClass: "form-group mb-4" }, [
            _c(
              "label",
              { staticClass: "form-label", attrs: { for: "physical_address" } },
              [_vm._v("Year")]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.p9Form.year,
                  expression: "p9Form.year",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "number", name: "", id: "" },
              domProps: { value: _vm.p9Form.year },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.p9Form, "year", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _vm.p9Form.hasError("year")
              ? _c("span", {
                  staticClass: "text-danger",
                  domProps: {
                    textContent: _vm._s(_vm.p9Form.getError("year")),
                  },
                })
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.formP9.processing
            ? _c("div", { staticClass: "progress mb-2" }, [
                _c("div", { staticClass: "progress" }, [
                  _c("div", {
                    staticClass:
                      "progress-bar progress-bar-indeterminate bg-green",
                  }),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "employment-history-modal",
            "body-class": "px-4",
            size: "lg",
            "content-class": "bg-white rounded-3",
            "footer-class":
              "d-flex justify-content-between bg-muted-lt border-top",
            "hide-header-close": "",
            "modal-class": "modal-blur",
            "no-enforce-focus": "",
            title: _vm.employee.name + " Employment History",
          },
          on: { hidden: _vm.cleanUp },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      attrs: {
                        disabled: _vm.formP9.processing,
                        type: "button",
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return cancel.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n          Close\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        disabled: _vm.formP9.processing,
                        type: "submit",
                      },
                      on: { click: _vm.formP9 },
                    },
                    [_vm._v("\n          Submit\n        ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("div", [
            _c("div", { staticClass: "containe" }, [
              _c("section", { staticClass: "employment-history" }, [
                _c("table", { staticClass: "history-table" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("Job Title")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Department")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Start Date")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("End Date")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Comments")]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.employmentHistory, function (employment) {
                      return _c("tr", { key: employment.id }, [
                        _c("td", [_vm._v(_vm._s(employment.job_title))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(employment.department.name))]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(_vm._s(_vm.formatDate(employment.start_date))),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(_vm._s(_vm.formatDate(employment.end_date))),
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(employment.notes))]),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.formP9.processing
            ? _c("div", { staticClass: "progress mb-2" }, [
                _c("div", { staticClass: "progress" }, [
                  _c("div", {
                    staticClass:
                      "progress-bar progress-bar-indeterminate bg-green",
                  }),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "profile-image-modal",
            "body-class": "px-4",
            "content-class": "bg-white rounded-3",
            "footer-class":
              "d-flex justify-content-between bg-muted-lt border-top",
            "hide-header-close": "",
            "modal-class": "modal-blur",
            "no-enforce-focus": "",
            title: "Upload profile image",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      attrs: {
                        disabled: _vm.employmentphoto.processing,
                        type: "button",
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return cancel.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n          Close\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        disabled: _vm.employmentphoto.processing,
                        type: "submit",
                      },
                      on: { click: _vm.uploadPhoto },
                    },
                    [_vm._v("\n          Submit\n        ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "form",
            { attrs: { enctype: "multipart/form-data", method: "post" } },
            [
              _c("input", {
                attrs: { type: "file", name: "", id: "" },
                on: { input: _vm.updateImage },
              }),
              _vm._v(" "),
              _vm.formP9.processing
                ? _c("div", { staticClass: "progress mb-2" }, [
                    _c("div", { staticClass: "progress" }, [
                      _c("div", {
                        staticClass:
                          "progress-bar progress-bar-indeterminate bg-green",
                      }),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("Date of Birth\n                    "),
      _c("span", { staticClass: "form-label-description small text-indigo" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "btn rounded-3 me-3",
        attrs: { href: "/employees", title: "Return to Patients dashboard" },
      },
      [
        _c("i", { staticClass: "uil uil-arrow-left me-2" }),
        _vm._v(" Employees\n                "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn align-text-center",
        attrs: { "data-bs-toggle": "dropdown" },
      },
      [_c("i", { staticClass: "uil uil-ellipsis-h" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }