<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
export default {
  props: {
    leave_request: {
      type: Array,
      required: true,
      default: function () {
        return []
      },
    },
  },
  components: {
    FullCalendar
  },
  data() {
    return {
      calendarOptions: {
        plugins: [ dayGridPlugin, interactionPlugin ],
        initialView: 'dayGridMonth',
        eventMouseEnter: this.handleDateClick,
        events: [
        ]
      },
      approved_leaves:[]
    }
  },
  mounted() {
    this.approvedLeaves()
    this.pendingLeaves()
  },
  methods:{
    handleDateClick: function(arg) {
      console.log(arg)
    },
    approvedLeaves(){
      this.$httpClient.get('/datatable/leave-requests', {
        params:{
          filter: {

          }
        }
      })
          .then(({ data }) => {
            this.approved_leaves = data.data;
            this.showApprovedLeaves();
          }).catch(() => {
        this.$toast.error('There was a problem retrieving the Employee allowances')
      })
    },

    showApprovedLeaves(){
      this.approved_leaves.forEach((leave)=>{
        let leaverequest ={}
        if(leave.supervisor_approved && leave.approved){
          leaverequest = {
            'title': leave.employee_leave_type.employee.name,
            'start': leave.start_date,
            'end': leave.end_date,
            'color':'green'
          }
        }else if(leave.supervisor_approved && !leave.rejected){
          leaverequest = {
            'title': leave.employee_leave_type.employee.name,
            'start': leave.start_date,
            'end': leave.end_date,
            'color':'lightblue'
          }
        }else if(leave.rejected || leave.supervisor_rejected){
          leaverequest = {
            'title': leave.employee_leave_type.employee.name,
            'start': leave.start_date,
            'end': leave.end_date,
            'color':'#bc3433'
          }
        }else{
          leaverequest = {
            'title': leave.employee_leave_type.employee.name,
            'start': leave.start_date,
            'end': leave.end_date,
            'color':'gray'
          }
        }


        this.calendarOptions.events.push(leaverequest)
      })

    }
  }

};

</script>

<template>
  <div>
    <div class="card card-stacked p-6">
      <FullCalendar :options="calendarOptions" />
    </div>
  </div>
</template>
