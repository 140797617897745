<script>
import { defineComponent } from 'vue'
import { toPrice } from '@plugins/money'

export default defineComponent({
  name: 'Dedutions',
  props: {
    deductions: {
      type: Array,
      default: () => [],
    },
    permissions: {
      type: Array,
      default: () => [],
    },
    employee: {
      type: Array,
      default: () => [],
    },
  },

  data () {
    return {

      fields: [
        {
          name: 'deduction.name',
          title: 'Name',
          titleClass: ' text-start pe-3',
          dataClass: ' text-start pe-3',
          width: '8%',
        },

        {
          name: 'amount',
          title: 'Amount',
          callback: function (value) {
            if (value < 0) {
              return '(' + toPrice(Math.abs(value)).toFormat('0,0.00') + ')'
            }
            return toPrice(value).toFormat('0,0.00')
          },
          titleClass: 'text-end pe-3',
          dataClass: 'text-end pe-3',
          width: '10%',
        },
        {
          name: '__slot:actions',
          dataClass: 'txt-center',
          width: '5%',
        },
      ],

      employeeDeductions: [],

      billToReverse: '',

      form: new window.Form({
        id:null,
        employee_id: this.employee.id,
        deduction_id: null,
        amount: null,
      }),

      enableForm: false,

    }
  },


  watch: {

  },

  mounted () {
    this.retrieveEmployeeDeductions()
  },

  methods: {
    retrieveEmployeeDeductions () {
      this.$httpClient.get('/employee/deductions/' + this.employee.id)
          .then(({ data }) => {
            this.employeeDeductions = data;
          }).catch(() => {
        this.$toast.error('There was a problem retrieving the employee deductions')
      })
    },

    openDeductionModal () {
      this.$bvModal.show('deduction-modal')
    },


    addDedution () {
      this.form.post('/employee/deductions')
          .then(() => {
            this.$toast.success('The deduction has been added')
            this.cleanUp()
            this.vuetable.reload()

          })
          .catch((error) => {
            this.form.processing = false
            this.form.errors.record(error.response.data.errors)
          })
    },

    editDeduction(data){
      this.form.populate(data)
      this.$bvModal.show('edit-deduction-modal')
    },
    updateIncome(){
      this.form.patch('/employee/deductions/' + this.form.id)
          .then(() => {
            this.$toast.success('The changes have been saved')
            this.cleanUp()
            this.$bvModal.hide('edit-deduction-modal')
            this.vuetable.reload()

          })
          .catch()
    },
    cleanUp () {
      this.form.reset()
      this.retrieveEmployeeDeductions()
    },


  },

})
</script>

<template>

  <div>

    <b-modal
        id="deduction-modal"
        body-class="px-4"
        content-class="bg-white rounded-3"
        footer-class="d-flex justify-content-between bg-muted-lt border-top"
        hide-header-close
        modal-class="modal-blur"
        no-close-on-backdrop
        no-close-on-esc
        no-enforce-focus
        title="Dedution Form"
        @hidden="cleanUp"
    >
      <div class="form-group mb-4">
        <label class="form-label" for="item_id">Dedution</label>
        <v-select
            id="item_id"
            v-model="form.deduction_id"
            :clearable="false"
            :reduce="(option) => option.id"
            :options="deductions"
            label="name"
        >

        </v-select>
        <span
            v-if="form.errors.has('deduction_id')"
            class="text-danger"
            v-text="form.errors.first('deduction_id')"
        />
      </div>
      <div class="form-group mb-4">
        <div class="form-group">
          <label for="" class="form-label">Amount</label>
          <medic-money
              :value="form.amount"
              @input="(value) => {
                                            form.amount = value
                                        }"></medic-money>

          <div class="text-danger"
               v-if="form.hasError('amount')"
               v-text="form.getError('amount')">
          </div>
        </div>
      </div>

      <div v-if="form.processing" class="progress mb-2">
        <div class="progress">
          <div class="progress-bar progress-bar-indeterminate bg-green"/>
        </div>
      </div>

      <template #modal-footer="{cancel}">
        <button :disabled="form.processing" class="btn" type="button" @click.prevent="cancel">
          Close
        </button>

        <button
            :disabled="form.processing" class="btn btn-primary"
            type="submit"
            @click="addDedution"
        >
          Submit
        </button>
      </template>
    </b-modal>
    <b-modal
        id="edit-deduction-modal"
        body-class="px-4"
        content-class="bg-white rounded-3"
        footer-class="d-flex justify-content-between bg-muted-lt border-top"
        hide-header-close
        modal-class="modal-blur"
        no-close-on-backdrop
        no-close-on-esc
        no-enforce-focus
        title="Edit Dedution Form"
        @hidden="cleanUp"
    >
      <div class="form-group mb-4">
        <label class="form-label" for="item_id">Dedution</label>
        <v-select
            id="item_id"
            v-model="form.deduction_id"
            :clearable="false"
            :reduce="(option) => option.id"
            :options="deductions"
            label="name"
        >

        </v-select>
        <span
            v-if="form.errors.has('deduction_id')"
            class="text-danger"
            v-text="form.errors.first('deduction_id')"
        />
      </div>
      <div class="form-group mb-4">
        <div class="form-group">
          <label for="" class="form-label">Amount</label>
          <medic-money
              :value="form.amount"
              @input="(value) => {
                                            form.amount = value
                                        }"></medic-money>

          <div class="text-danger"
               v-if="form.hasError('amount')"
               v-text="form.getError('amount')">
          </div>
        </div>
      </div>

      <div v-if="form.processing" class="progress mb-2">
        <div class="progress">
          <div class="progress-bar progress-bar-indeterminate bg-green"/>
        </div>
      </div>

      <template #modal-footer="{cancel}">
        <button :disabled="form.processing" class="btn" type="button" @click.prevent="cancel">
          Close
        </button>

        <button
            :disabled="form.processing" class="btn btn-primary"
            type="submit"
            @click="updateIncome"
        >
          Submit
        </button>
      </template>
    </b-modal>

    <div class="card rounded-3 mb-5 ">
      <div class="card-header">
        <div class="">
          <h2 class="card-title mb-0 fw-bold">
            Dedutions
          </h2>
        </div>

        <div class="card-actions">
          <div class="btn-list">
<!--            <button class="btn btn-primary me-4" @click.prevent="openDeductionModal">-->
<!--              <i class="uil uil-plus me-1"/> Add Dedution-->
<!--            </button>-->
          </div>
        </div>
      </div>

      <VueTable
          ref="table"
          :api-mode="false"
          :data="employeeDeductions"
          :fields="fields"
          :load-on-start="false"
          :show-pagination="false"
          :show-per-page="false"
      >
        <template #date="props">
          <div class="flex-fill">
            <div class="ps-3">
              {{ props.rowData.date | date_DATE_MONTH_YEAR }}
            </div>
            <div class="text-indigo small ps-3">
              {{ props.rowData.date | tIME_HOUR_MINUTES }}
            </div>
          </div>
        </template>
        <template #actions="props">
<!--          <div class="dropdown">-->
<!--            <button-->
<!--                class="btn align-text-center py-1"-->
<!--                data-bs-toggle="dropdown"-->
<!--            >-->
<!--              <i class="uil uil-ellipsis-h"/>-->
<!--            </button>-->
<!--            <div class="dropdown-menu dropdown-menu-end">-->


<!--              <div class="dropdown-divider"/>-->

<!--              <a class="dropdown-item" href="#" @click="editDeduction(props.rowData)">-->
<!--                <i class="uil uil-pen fs-3 me-2"/> Edit-->
<!--              </a>-->
<!--            </div>-->
<!--          </div>-->
        </template>
      </VueTable>
    </div>

  </div>
</template>

<style scoped>

</style>
