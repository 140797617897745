var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-xl-8 mx-auto p-4" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "card card-stacked" }, [
          _vm.canadd
            ? _c(
                "span",
                {
                  staticClass: "d-flex p-2 gap-2",
                  staticStyle: { "margin-left": "auto" },
                },
                [
                  _vm.canadd
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.editForm,
                            expression: "editForm",
                          },
                        ],
                        staticClass: "form-check",
                        attrs: { type: "checkbox", name: "", id: "edit" },
                        domProps: {
                          checked: Array.isArray(_vm.editForm)
                            ? _vm._i(_vm.editForm, null) > -1
                            : _vm.editForm,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.editForm,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.editForm = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.editForm = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.editForm = $$c
                            }
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "label",
                    { staticClass: "form-label", attrs: { for: "edit" } },
                    [_vm._v("Edit")]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.canadd
            ? _c(
                "span",
                {
                  staticClass: "d-flex p-2 gap-2",
                  staticStyle: { "margin-left": "auto" },
                },
                [
                  _vm.canadd
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.uploadChart,
                            expression: "uploadChart",
                          },
                        ],
                        staticClass: "form-check",
                        attrs: { type: "checkbox", name: "", id: "chart" },
                        domProps: {
                          checked: Array.isArray(_vm.uploadChart)
                            ? _vm._i(_vm.uploadChart, null) > -1
                            : _vm.uploadChart,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.uploadChart,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.uploadChart = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.uploadChart = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.uploadChart = $$c
                            }
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "label",
                    { staticClass: "form-label", attrs: { for: "chart" } },
                    [_vm._v("Upload chart")]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "form",
            {
              staticClass: "card-body py-4",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.saveOrganizationInfo.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-6 form-group mb-4" }, [
                  _c(
                    "label",
                    { staticClass: "form-label", attrs: { for: "" } },
                    [_vm._v("Organization Name")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.name,
                        expression: "form.name",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      required: "",
                      id: "name",
                      disabled: !this.editForm,
                      type: "text",
                    },
                    domProps: { value: _vm.form.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "name", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.form.hasError("name")
                    ? _c("div", {
                        staticClass: "text-danger",
                        domProps: {
                          textContent: _vm._s(_vm.form.getError("name")),
                        },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6 form-group mb-4" }, [
                  _c(
                    "label",
                    { staticClass: "form-label", attrs: { for: "" } },
                    [_vm._v("KRA PIN")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.kra_pin,
                        expression: "form.kra_pin",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      required: "",
                      disabled: !this.editForm,
                      id: "name",
                      type: "text",
                    },
                    domProps: { value: _vm.form.kra_pin },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "kra_pin", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.form.hasError("kra_pin")
                    ? _c("div", {
                        staticClass: "text-danger",
                        domProps: {
                          textContent: _vm._s(_vm.form.getError("kra_pin")),
                        },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6 form-group mb-4" }, [
                  _c(
                    "label",
                    { staticClass: "form-label", attrs: { for: "" } },
                    [_vm._v("Phone")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.phone,
                        expression: "form.phone",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      required: "",
                      disabled: !this.editForm,
                      id: "name",
                      type: "text",
                    },
                    domProps: { value: _vm.form.phone },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "phone", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.form.hasError("phone")
                    ? _c("span", {
                        staticClass: "text-danger",
                        domProps: {
                          textContent: _vm._s(_vm.form.getError("phone")),
                        },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6 form-group mb-4" }, [
                  _c(
                    "label",
                    { staticClass: "form-label", attrs: { for: "plan_type" } },
                    [_vm._v("Fax")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.fax,
                        expression: "form.fax",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      disabled: !this.editForm,
                      id: "name",
                      type: "text",
                    },
                    domProps: { value: _vm.form.fax },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "fax", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.form.hasError("fax")
                    ? _c("span", {
                        staticClass: "text-danger",
                        domProps: {
                          textContent: _vm._s(_vm.form.getError("fax")),
                        },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6 form-group mb-4" }, [
                  _c(
                    "label",
                    { staticClass: "form-label", attrs: { for: "plan_type" } },
                    [_vm._v("Email address")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.email,
                        expression: "form.email",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      required: "",
                      disabled: !this.editForm,
                      id: "name",
                      type: "text",
                    },
                    domProps: { value: _vm.form.email },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "email", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.form.hasError("email")
                    ? _c("span", {
                        staticClass: "text-danger",
                        domProps: {
                          textContent: _vm._s(_vm.form.getError("email")),
                        },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6 form-group mb-4" }, [
                  _c(
                    "label",
                    { staticClass: "form-label", attrs: { for: "plan_type" } },
                    [_vm._v("Address")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.address,
                        expression: "form.address",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      required: "",
                      disabled: !this.editForm,
                      id: "name",
                      type: "text",
                    },
                    domProps: { value: _vm.form.address },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "address", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.form.hasError("address")
                    ? _c("span", {
                        staticClass: "text-danger",
                        domProps: {
                          textContent: _vm._s(_vm.form.getError("address")),
                        },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6 form-group mb-4" }, [
                  _c(
                    "label",
                    { staticClass: "form-label", attrs: { for: "plan_type" } },
                    [_vm._v("Postal codes")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.postal_code,
                        expression: "form.postal_code",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      required: "",
                      disabled: !this.editForm,
                      id: "name",
                      type: "text",
                    },
                    domProps: { value: _vm.form.postal_code },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "postal_code", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.form.hasError("postal_code")
                    ? _c("span", {
                        staticClass: "text-danger",
                        domProps: {
                          textContent: _vm._s(_vm.form.getError("postal_code")),
                        },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6 form-group mb-4" }, [
                  _c(
                    "label",
                    { staticClass: "form-label", attrs: { for: "plan_type" } },
                    [_vm._v("City")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.city,
                        expression: "form.city",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      required: "",
                      disabled: !this.editForm,
                      id: "name",
                      type: "text",
                    },
                    domProps: { value: _vm.form.city },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "city", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.form.hasError("city")
                    ? _c("span", {
                        staticClass: "text-danger",
                        domProps: {
                          textContent: _vm._s(_vm.form.getError("city")),
                        },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.uploadChart
                  ? _c("div", { staticClass: "col-lg-6 form-group mb-4" }, [
                      _c(
                        "label",
                        {
                          staticClass: "form-label",
                          attrs: { for: "plan_type" },
                        },
                        [_vm._v("Chart")]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        staticClass: "form-control",
                        attrs: { disabled: !this.uploadChart, type: "file" },
                        on: {
                          change: _vm.uploadChart,
                          input: function ($event) {
                            _vm.chart.chart = $event.target.files[0]
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("chart")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(_vm.form.getError("chart")),
                            },
                          })
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.editForm
                  ? _c("div", { staticClass: "mt-4" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary me-3",
                          attrs: { type: "submit" },
                        },
                        [_vm._v("\n                Submit\n              ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn",
                          attrs: {
                            disabled:
                              _vm.form.processing || _vm.form.successful,
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.goBack.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("\n                Cancel\n              ")]
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center mb-4" }, [
      _c("h1", { staticClass: "fs-1" }, [
        _vm._v("\n          Organization\n        "),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "text-muted" }, [
        _vm._v("Complete the form below to save Organization information"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }