import { render, staticRenderFns } from "./Contributions.vue?vue&type=template&id=0d986298&scoped=true&"
import script from "./Contributions.vue?vue&type=script&lang=js&"
export * from "./Contributions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d986298",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/bismarck/Documents/software/ponacare-medical/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0d986298')) {
      api.createRecord('0d986298', component.options)
    } else {
      api.reload('0d986298', component.options)
    }
    module.hot.accept("./Contributions.vue?vue&type=template&id=0d986298&scoped=true&", function () {
      api.rerender('0d986298', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/pages/employee/Contributions.vue"
export default component.exports