import {isConditionPassed} from '@utils/permission'
import store from '@state/store'

export default {
    install(Vue, option = {}) {
        const permissions = store.state.auth.permissions
        const roles = store.state.auth.roles
        const Medic = {
            permissions: permissions,
            roles: roles,
        }

        Vue.prototype.$medic = {
            /*
            |-------------------------------------------------------------------------
            | Setters
            |-------------------------------------------------------------------------
            |
            | These functions controls the "permissions" and "roles" provided
            | by Laravel Permissions, or from a custom array.
            |
            */
            setPermissions: (permissions) => {
                Medic.permissions = permissions
                // if (canPersistent) {
                store.dispatch('auth/getUserPermissions')
                // localStorage.setItem('permissions', JSON.stringify(permissions))
                // }
            },

            setRoles: (roles) => {
                Medic.roles = roles
                // if (canPersistent) {
                store.dispatch('auth/getUserRoles')
                // localStorage.setItem('roles', JSON.stringify(roles))
                // }
            },

            /*
            |-------------------------------------------------------------------------
            | Getters
            |-------------------------------------------------------------------------
            |
            | These functions return the "permissions" and "roles" stored.
            | This is useful when you want list all data.
            |
            */

            getPermissions: () => store.state.auth.permissions,

            getRoles: () => store.state.auth.roles,

            /*
            |-------------------------------------------------------------------------
            | Directives
            |-------------------------------------------------------------------------
            |
            | These is a group of functions for Vue Directives.
            | This is useful when you want valid a "permission" or "role"
            | programmatically.
            |
            */

            hasPermission: (permission) =>
                store.state.auth.permissions.includes(permission),

            unlessPermission: (permission) =>
                !Vue.prototype.$medic.hasPermission(permission),

            hasAnyPermission: (values) => {
                const permissions = values.split('|')
                return permissions.some((permission) =>
                    Medic.permissions.includes(permission)
                )
            },

            hasAllPermissions: (values) => {
                const permissions = values.split('|')
                return permissions.every((permission) =>
                    Medic.permissions.includes(permission)
                )
            },

            // Roles
            hasRole: (role) => Medic.roles.includes(role),
            unlessRole: (role) => !Vue.prototype.$medic.hasRole(role),

            hasAnyRole: (values) => {
                const roles = values.split('|')
                return roles.some((role) => Medic.roles.includes(role))
            },

            hasAllRoles: (values) => {
                const roles = values.split('|')
                return roles.every((role) => Medic.roles.includes(role))
            },
        }

        // Directives
        Vue.directive('permission', {inserted: isConditionPassed(Vue)})
        Vue.directive('role', {inserted: isConditionPassed(Vue)})
        Vue.directive('role-or-permission', {
            inserted: (el, binding) => {
                if (!binding.value) {
                    console.error('You must specify a value in the directive.')
                    return
                }

                const options = binding.value.split('|')
                const role = options[0]
                const permission = options[1]

                if (
                    !Vue.prototype.$medic.hasRole(role) &&
                    !Vue.prototype.$medic.hasPermission(permission)
                ) {
                    // Remove DOM Element
                    el.parentNode.removeChild(el)
                }
            },
        })

        // Alias for "v-permission:has"
        Vue.directive('can', {
            inserted: (el, binding) => {
                if (!Vue.prototype.$medic.hasPermission(binding.value)) {
                    // Remove DOM Element
                    el.parentNode.removeChild(el)
                }
            },
        })
    },
}
