<script>

export default {
    data() {
        return {
            fields: [

                {
                    name: '__slot:pay_date',
                    title: 'Month',
                    titleClass: 'font-weight-bold text-right',
                    dataClass: 'text-right',
                    // callback: this.$options.filters.toMoneyFormat
                },


                {
                    name: 'totalNetPay',
                    title: 'Net Pay',
                    titleClass: 'font-weight-bold text-right',
                    dataClass: 'text-right',
                    callback: this.$options.filters.toMoneyFormat
                },
              {
                    name: 'processed_by.name',
                    title: 'Processed by',
                    titleClass: 'font-weight-bold text-right',
                    dataClass: 'text-right',
                },

                {
                    name: '__slot:actions',
                    titleClass: 'text-center',
                    dataClass: 'text-left',
                    width: '5%',
                },
            ],
            moreParams: {
                filter: {
                    search: ''
                }
            },

            form: new window.Form({
                id: null,
                month: ''
            }),
        }
    },

    computed: {
        vuetable() {
            return this.$refs.table.$refs.vuetable
        },
    },

    methods: {
        showCreateForm() {
            this.$bvModal.show('create-modal')
        },
      showPayrollDetails(data) {
          window.location.href = `/payroll/lists/${data.pay_date}`;
          console.log(data)
      },
      formatDate(dateStr){

        const date = new Date(dateStr);

        const options = { day: '2-digit', month: 'short', year: 'numeric' };

        const formatter = new Intl.DateTimeFormat('en-GB', options);


        const formattedDate = formatter.format(date);

        const finalFormattedDate = formattedDate.replace(/\//g, '-');

        console.log(finalFormattedDate);
        return finalFormattedDate;

      },
        processPayroll() {
            this.form.post('/payroll')
                .then(() => {
                    this.$bvModal.hide('create-modal')
                    this.$toast.success('Payroll has been processed')
                    this.vuetable.reload()
                })
                .catch((error)=>{
                  let errorContent = error.response
                  if(errorContent.status === 400){
                    this.$toast.error(errorContent.data)
                    console.log('here')
                  }
                  console.log(error)
                })
        },

      deletePayroll(rowData){
        this.$toast.question('Are you sure?', 'You want to delete this payroll').then(() => {
          this.$httpClient
              .delete(`/payroll/delete/`,
                  {
                    params:{
                      year:rowData.year,
                      month: rowData.month
                    }
                  }
              )
              .then(() => {
                this.vuetable.reload()
                this.$toast.success("deleted")
              })
              .catch(() => {
                this.$toast.error(
                    "There was a problem deleting the payroll"
                );
              });
        })
          console.log(rowData);
      },

        applyFilter() {
            this.vuetable.reload()
        },
      showPayrollComparision(rowData) {
          const year = rowData.year;
          const month = rowData.month;
          window.location.href = `/payroll/compare?year=${year}&month=${month}`;
      },
        cleanUp() {
            this.form.reset()
        }
    },
}
</script>

<template>
    <div>
        <div class="card card-stacked">
            <div class="card-header">
                <div class="input-icon me-2">
                    <span class="input-icon-addon">
                        <i class="uil uil-search"></i>
                    </span>
                    <input type="text" class="form-control bg-muted-lt rounded-2" placeholder="Search"
                        @input="applyFilter" v-model="moreParams.filter.search">
                </div>

                <div class="card-actions">
                  <a class="btn btn-primary" href="/payroll/payroll-list">
                     Payroll processing
                  </a>
                </div>
            </div>
            <VueTable ref="table" api-url="/datatable/payroll" :fields="fields" :append-params="moreParams">
                <template v-slot:pay_date="props">
                    <div class="media">
                        <div class="media-body align-self-center">
                            <span class="text-sm">{{ formatDate(props.rowData.pay_date) }} </span>
                        </div>
                    </div>
                </template>
              <template v-slot:name="props">
                    <div class="media">
                        <div class="media-body align-self-center">
                            <span class="text-sm">{{ props.rowData.employee.name }} </span>
                        </div>
                    </div>
                </template>

                <template v-slot:actions="props">
                  <div class="dropdown">
                    <button class="btn align-text-top py-1" data-bs-toggle="dropdown">
                      <i class="uil uil-ellipsis-h"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-end">
                      <div>
                        <a class="dropdown-item" href="#" @click="showPayrollDetails(props.rowData)">
                          Open
                        </a>
                        <a class="dropdown-item" href="#" @click="showPayrollComparision(props.rowData)">
                          Compare with last month
                        </a>
                        <a class="dropdown-item text-danger" href="#" @click="deletePayroll(props.rowData)">
                          Delete
                        </a>
                      </div>

                    </div>
                  </div>
                </template>
            </VueTable>
        </div>

        <b-modal id="create-modal" title="Payroll Processing" hide-header-close
            footer-class="d-flex justify-content-start" modal-class="modal-blur" no-close-on-esc>
            <form @submit.prevent="createDepartment" id="createForm">
                <div class="mb-3">
                    <label for="name" class="form-label">Select Month</label>
                    <date-picker name="month" :value="form.month" :max-date="false" @on-change="(dateOb, dateStr) => {
                            form.month = dateStr
                        }" />

                    <span v-if="form.hasError('month')" class="text-danger" v-text="form.getError('month')">
                    </span>
                </div>

                <div class="progress mb-2" v-if="form.processing">
                    <div class="progress">
                        <div class="progress-bar progress-bar-indeterminate bg-green"></div>
                    </div>
                </div>

            </form>

            <template #modal-footer="{ cancel }">
                <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">Close
                </button>
                <button type="button" class="btn btn-primary" :disabled="form.processing"
                    @click.prevent="processPayroll">
                    Process
                </button>
            </template>

        </b-modal>

        <b-modal id="compare-modal" title="Comparison" hide-header-close footer-class="d-flex justify-content-start"
            modal-class="modal-blur" no-close-on-esc>
            <form @submit.prevent="updateDepartment" id="updateForm">
                <div class="mb-3">
                    <label for="name" class="form-label">Select Month</label>
                    <input type="text" class="form-control" id="month" v-model="form.month">

                    <span v-if="form.hasError('month')" class="text-danger" v-text="form.getError('month')">
                    </span>
                </div>


                <div class="progress mb-2" v-if="form.processing">
                    <div class="progress">
                        <div class="progress-bar progress-bar-indeterminate bg-green"></div>
                    </div>
                </div>

            </form>
            <template #modal-footer="{ cancel }">
                <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">Close
                </button>
                <button type="button" class="btn btn-primary" :disabled="form.processing"
                    @click.prevent="updateDepartment">
                    Process
                </button>

            </template>
        </b-modal>

    </div>
</template>
