import {differenceInCalendarYears, differenceInHours, format, formatDistance} from 'date-fns'
import parseISO from 'date-fns/parseISO'

import Vue from 'vue'
import {toPrice} from "@plugins/money";

Vue.filter('date_differenceYears', function (date) {
  if (!date) return;

  return differenceInCalendarYears(new Date(), parseISO(date));
});

Vue.filter('date_differenceHours', function (date) {
  if (!date) return;

  return differenceInHours(new Date(), parseISO(date));

});

Vue.filter('date_difference', function (date) {
  if (!date) return;

  return formatDistance(new Date(), parseISO(date));
});

Vue.filter('date_DATE_MONTH_YEAR', function (date) {
  if (!date) return;

  return format(parseISO(date), 'dd/MM/yyyy'); // 13/5/2020

});

Vue.filter('date_DATE_MONTH_LONG', function (date) {
  if (!date) return;

  return format(parseISO(date), 'MMMM dd'); // January, 20
});

Vue.filter('date_LONG_MONTH_YEAR', function (date) {
  if (!date) return;

  return format(parseISO(date), 'MMMM, yyyy'); // January, 2020
});

Vue.filter('date_DAY_MONTH_YEAR', function (date) {
  if (!date) return;

  return format(parseISO(date), 'MMMM dd, yyyy'); // January 16, 2020
});

Vue.filter('DATE_MONTH_YEAR_HOUR_MINUTES', function (date) {
  if (!date) return;

  return format(parseISO(date), 'dd/M/yyyy h:mm aaa'); // 23/5/2020 6:11 AM/PM
});

Vue.filter('TIME_HOUR_MINUTES', function (date) {
  if (!date) return;

  return format(parseISO(date), 'h:mm aaa') // 6:11 AM/PM
});

Vue.filter('toMoneyFormat', function (amount) {
  if (amount === null) {
    return 0.00;
  }

  if (amount < 0) {
    return '(' + toPrice(Math.abs(amount)).toFormat('0,0.00') + ')'
  }

  return toPrice(amount).toFormat('0,0.00')
})

Vue.filter('readableFileSize', function (size) {
  let units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let i = 0;
  while (size >= 1024) {
    size /= 1024;
    ++i;
  }
  return size.toFixed(1) + ' ' + units[i];
})
